import React from "react";
import { Label, Input, FormGroup } from "reactstrap";

const AddressGeneralInformations = ({
  editFormData,
  setEditFormData,
  handleFormChange,
  errorForm,
}) => {
  const adressOpeningTimeForm = (
    editFormData,
    day,
    morning_or_afternoon,
    startrange_or_endrange,
  ) => {
    return (
      <Input
        className="information-data information-data-multiple"
        type="time"
        placeholder="Entrer une valeur"
        dictcategory="opening"
        onChange={(event) =>
          handleFormChange(event, editFormData, setEditFormData)
        }
        name={day}
        indexarray={morning_or_afternoon}
        indexopening={startrange_or_endrange}
        value={
          editFormData.opening[day][morning_or_afternoon][
            startrange_or_endrange
          ]
        }
      />
    );
  };

  return (
    <section>
      <section className={`error-form ${errorForm ? "display-error" : ""}`}>
        <p>{errorForm}</p>
      </section>

      <article className="information-bloc">
        <FormGroup className="information-line">
          <Label className="information-label">Nom</Label>
          <Input
            className="information-column-data"
            type="text"
            required="required"
            placeholder="Enter value"
            value={editFormData.name}
            onChange={(event) =>
              handleFormChange(event, editFormData, setEditFormData)
            }
            name="name"
          />
        </FormGroup>

        <FormGroup className="information-line">
          <Label className="information-label">Rôle</Label>
          <select
            type="select"
            value={editFormData.role}
            onChange={(event) =>
              handleFormChange(event, editFormData, setEditFormData)
            }
            name="role"
          >
            <option value="sorting_plant">C. de tri</option>
            <option value="maintenance_centre">C. de maintenance</option>
            <option value="client">Client</option>
            <option value="building_site">Chantier</option>
            <option value="dump">Déchetterie</option>
            <option value="industrial">Site industriel</option>
            <option value="landfill_site">Centre d'enfouissement</option>
            <option value="incineration_centre">Centre d'incinération</option>
          </select>
        </FormGroup>
      </article>

      <article className="information-bloc">
        <h3>Informations</h3>

        <FormGroup className="information-line">
          <Label className="information-label">N° de rue</Label>
          <Input
            className="information-column-data"
            type="text"
            placeholder="Numéro de rue"
            value={editFormData.number}
            onChange={(event) =>
              handleFormChange(event, editFormData, setEditFormData)
            }
            name="number"
          />
        </FormGroup>

        <FormGroup className="information-line">
          <Label className="information-label">Nom de rue</Label>
          <Input
            className="information-column-data"
            type="text"
            placeholder="Nom de rue"
            value={editFormData.street}
            onChange={(event) =>
              handleFormChange(event, editFormData, setEditFormData)
            }
            name="street"
          />
        </FormGroup>

        <FormGroup className="information-line">
          <Label className="information-label">Code Postal</Label>
          <Input
            className="information-column-data"
            type="number"
            required="required"
            placeholder="Code Postale"
            value={editFormData.postalCode}
            onChange={(event) =>
              handleFormChange(event, editFormData, setEditFormData)
            }
            name="postalCode"
            min="00000"
            max="99999"
          />
        </FormGroup>

        <FormGroup className="information-line">
          <Label className="information-label">Ville</Label>
          <Input
            className="information-column-data"
            type="text"
            required="required"
            placeholder="Ville"
            value={editFormData.city}
            onChange={(event) =>
              handleFormChange(event, editFormData, setEditFormData)
            }
            name="city"
          />
        </FormGroup>
      </article>

      <article className="information-bloc">
        <h3>Horaires d'ouvertures</h3>

        <FormGroup className="information-line">
          <Label className="information-label">Lundi</Label>
          <div>
            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Matin
              </Label>
              {adressOpeningTimeForm(editFormData, 1, 0, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 1, 0, 1)}
            </article>

            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Après-midi
              </Label>
              {adressOpeningTimeForm(editFormData, 1, 1, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 1, 1, 1)}
            </article>
          </div>
        </FormGroup>

        <FormGroup className="information-line">
          <Label className="information-label">Mardi</Label>
          <div>
            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Matin
              </Label>
              {adressOpeningTimeForm(editFormData, 2, 0, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 2, 0, 1)}
            </article>

            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Après-midi
              </Label>
              {adressOpeningTimeForm(editFormData, 2, 1, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 2, 1, 1)}
            </article>
          </div>
        </FormGroup>

        <FormGroup className="information-line">
          <Label className="information-label">Mercredi</Label>
          <div>
            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Matin
              </Label>
              {adressOpeningTimeForm(editFormData, 3, 0, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 3, 0, 1)}
            </article>

            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Après-midi
              </Label>
              {adressOpeningTimeForm(editFormData, 3, 1, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 3, 1, 1)}
            </article>
          </div>
        </FormGroup>

        <FormGroup className="information-line">
          <Label className="information-label">Jeudi</Label>
          <div>
            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Matin
              </Label>
              {adressOpeningTimeForm(editFormData, 4, 0, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 4, 0, 1)}
            </article>

            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Après-midi
              </Label>
              {adressOpeningTimeForm(editFormData, 4, 1, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 4, 1, 1)}
            </article>
          </div>
        </FormGroup>

        <FormGroup className="information-line">
          <Label className="information-label">Vendredi</Label>
          <div>
            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Matin
              </Label>
              {adressOpeningTimeForm(editFormData, 5, 0, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 5, 0, 1)}
            </article>

            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Après-midi
              </Label>
              {adressOpeningTimeForm(editFormData, 5, 1, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 5, 1, 1)}
            </article>
          </div>
        </FormGroup>

        <FormGroup className="information-line">
          <Label className="information-label">Samedi</Label>
          <div>
            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Matin
              </Label>
              {adressOpeningTimeForm(editFormData, 6, 0, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 6, 0, 1)}
            </article>

            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Après-midi
              </Label>
              {adressOpeningTimeForm(editFormData, 6, 1, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 6, 1, 1)}
            </article>
          </div>
        </FormGroup>

        <FormGroup className="information-line">
          <Label className="information-label">Dimanche</Label>
          <div>
            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Matin
              </Label>
              {adressOpeningTimeForm(editFormData, 0, 0, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 0, 0, 1)}
            </article>

            <article className="information-bloc-column">
              <Label className="information-data-multiple information-label">
                Après-midi
              </Label>
              {adressOpeningTimeForm(editFormData, 0, 1, 0)}
              <Label className="information-data-multiple">à</Label>
              {adressOpeningTimeForm(editFormData, 0, 1, 1)}
            </article>
          </div>
        </FormGroup>
      </article>

      <article className="information-bloc">
        <h3>Coordonnées GPS</h3>

        <FormGroup className="information-line">
          <Label className="information-label">Latitude *</Label>
          <Input
            className="information-column-data"
            type="number"
            required="required"
            placeholder="40.7128"
            value={editFormData.location ? editFormData.location[0] : ""}
            onChange={(event) =>
              handleFormChange(event, editFormData, setEditFormData)
            }
            dictcategory="location"
            name="0"
            step="0.00000000000000001"
            min="-90"
            max="90"
          />
        </FormGroup>

        <FormGroup className="information-line">
          <Label className="information-label">Longitude *</Label>
          <Input
            className="information-column-data"
            type="number"
            required="required"
            placeholder="-74.0060"
            value={editFormData.location[1]}
            onChange={(event) =>
              handleFormChange(event, editFormData, setEditFormData)
            }
            dictcategory="location"
            name="1"
            step="0.00000000000000001"
            min="-180"
            max="180"
          />
        </FormGroup>
      </article>
    </section>
  );
};

export default AddressGeneralInformations;
