import React, { useState, useEffect } from "react";
import { Rect, Text, Group } from "react-konva";

const ContainerZoomButton = ({
  buttonRef,
  textRef,
  imgDivWidth,
  imgDivHeight,
}) => {
  const [imageWidth, setImageWidth] = useState(1000);
  const [imageHeight, setImageHeight] = useState(1000);

  useEffect(() => {
    if (imgDivWidth && imgDivHeight) {
      setImageWidth(imgDivWidth);
      setImageHeight(imgDivHeight);
    }
  }, [imgDivWidth, imgDivHeight]);

  return (
    <Group>
      <Rect
        ref={buttonRef}
        x={imageWidth / 2 - 22.5}
        y={imageHeight / 2 - 22.5}
        width={45}
        height={45}
        fill="grey"
        opacity={0}
        cornerRadius={30}
      />

      <Text
        ref={textRef}
        x={imageWidth / 2 - 10}
        y={imageHeight / 2 - 15}
        text={"+"}
        opacity={0}
        fill={"white"}
        fontSize={35}
      />
    </Group>
  );
};

export default ContainerZoomButton;
