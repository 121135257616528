import axios from "axios";
import Cookies from "js-cookie";

const API = process.env.REACT_APP_API_BACKEND;

const axiosInstance = axios.create({
  baseURL: API,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor so that the newest cookie is always used
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the cookies from document.cookie or any other source
    const cookies = document.cookie;

    // Set the cookies in the request headers
    if (cookies) {
      config.headers.Authorization = `Bearer ${Cookies.get("userToken")}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
