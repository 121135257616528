import axios from "axios";
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Logo from "../../static/assets/logoHeader.png";
import FooterPage from "../../components/Footer.js";
import {
  Label,
  Input,
  FormGroup,
  Form,
  ModalBody,
  ModalHeader,
  Modal,
  ModalFooter,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import { formattedError } from "../../components/formatted/FormattedText";

const ResetPasswordByMail = ({ API }) => {
  const { token } = useParams();
  const history = useHistory();
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [secure, setSecure] = useState(true);
  const [secureConfirm, setSecureConfirm] = useState(true);
  const [errorForm, setErrorForm] = useState("");
  const [validForm, setValidForm] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (password.length < 6) {
        setErrorForm("Votre mot de passe doit contenir au moins 6 caractères");
      } else if (password !== confirmPassword) {
        setErrorForm("Votre mot de passe doit être similaire");
      } else {
        await axios
          .post(`${API}password/updateByMail/${token}`, {
            password: password,
            confirmPassword: confirmPassword,
          })
          .then((response) => {
            if (response.status === 200) {
              setValidForm(true);
            }
          });
      }
    } catch (error) {
      setErrorForm(formattedError(error.response.data.error));
    }
  };

  return (
    <div className="login-signin-container">
      <div className="col-left">
        <Modal isOpen={validForm}>
          <ModalHeader>
            <h2>Réinitialisation du mot de passe</h2>
            <CloseIcon
              style={{
                color: "var(--gray)",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/login");
              }}
            />
          </ModalHeader>

          <Form
            onSubmit={() => {
              history.push("/login");
            }}
          >
            <ModalBody>
              <p>Votre mot de passe a bien été modifié</p>
            </ModalBody>
            <ModalFooter className="information-footer-modal">
              <Link to="/login">
                <button className="button-submit" type="submit">
                  Fermer
                </button>
              </Link>
            </ModalFooter>
          </Form>
        </Modal>

        <div className="login-form-container">
          <div className="login-title">
            <h1>Saisisez votre nouveau mot de passe</h1>
            <Link to="/login" className="login-option">
              <span>Se connecter</span>
            </Link>
          </div>
          <section className={`error-form ${errorForm ? "display-error" : ""}`}>
            <p>{errorForm}</p>
          </section>

          <Form className="login-form" onSubmit={handleSubmit}>
            <FormGroup className="form-edit-password">
              <Label>Nouveau mot de passe</Label>
              <Input
                type={secure ? "password" : "text"}
                name="password"
                onChange={(event) => setPassword(event.target.value)}
              />
              <FontAwesomeIcon
                className="signin-icon-eye"
                size={24}
                icon={secure ? "eye-slash" : "eye"}
                color="#828899"
                onClick={() => setSecure(!secure)}
              />
            </FormGroup>

            <FormGroup className="form-edit-password">
              <Label>Confirmation mot de passe</Label>
              <Input
                type={secureConfirm ? "password" : "text"}
                name="passwordConfirm"
                onChange={(event) => setConfirmPassword(event.target.value)}
              />

              <FontAwesomeIcon
                className="signin-icon-eye"
                size={24}
                icon={secureConfirm ? "eye-slash" : "eye"}
                color="#828899"
                onClick={() => setSecureConfirm(!secureConfirm)}
              />
            </FormGroup>

            <button className="identification-button bg-cyan" type="submit">
              Valider
            </button>
          </Form>
        </div>
      </div>

      <div className="col-right">
        <div className="signin">
          <img src={Logo} alt="logo" />
        </div>
      </div>
      <FooterPage />
    </div>
  );
};

export default ResetPasswordByMail;
