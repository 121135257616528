import React, { useEffect, useState } from "react";

const Outlet = ({ editFormData, setEditFormData }) => {
  const materials = JSON.parse(sessionStorage.getItem("materialsDescription"));
  const [selectedDestinations, setSelectedDestinations] = useState({});

  const handleCheckboxChange = (material, destination) => {
    setSelectedDestinations((prevState) => ({
      ...prevState,
      [material]: destination,
    }));
  };

  useEffect(
    () => {
      if (editFormData?.outlet) {
        Object.keys(editFormData.outlet).forEach((outlet) => {
          editFormData.outlet[outlet].forEach((material) => {
            setSelectedDestinations((prevState) => ({
              ...prevState,
              [material]: outlet,
            }));
          });
        });
      } else {
        const defaultDestinations = materials.reduce((acc, material) => {
          acc[material.referenceName] = "undefined";
          return acc;
        }, {});
        setSelectedDestinations(defaultDestinations);
      }
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(
    () => {
      let outletData = {
        landfill: [],
        energyRecovery: [],
        materialRecovery: [],
      };
      Object.keys(selectedDestinations).forEach((material) => {
        const destination = selectedDestinations[material];
        if (outletData[destination]) {
          outletData[destination].push(material);
        }
      });
      let newEditFormData = {
        ...editFormData,
        ...{ outlet: outletData },
      };
      setEditFormData(newEditFormData);
    },
    // eslint-disable-next-line
    [selectedDestinations],
  );

  return (
    <div className="flex flex-col ml-2 py-2">
      <div className="flex flex-row w-full mb-3">
        <div className="w-1/5"></div>
        <div className="w-1/5 text-center">
          <span className="font-bold">Valorisation matière</span>
        </div>
        <div className="w-1/5 text-center">
          <span className="font-bold">Valorisation énergétique</span>
        </div>
        <div className="w-1/5 text-center">
          <span className="font-bold">Stockage</span>
        </div>
        <div className="w-1/5 text-center">
          <span className="font-bold">Non défini</span>
        </div>
      </div>
      {materials.length > 0 &&
        materials.map((material, ind) => (
          <div
            key={`item-${material._id}`}
            className="flex flex-row w-full border-b-2 mb-1"
          >
            <div className="w-1/5">
              <span className="font-semibold">
                {material.frenchFormattedName}
              </span>
            </div>
            <div className="w-1/5 text-center">
              <input
                className="relative h-5 p-0 w-auto"
                type="checkbox"
                checked={
                  selectedDestinations[material.referenceName] ===
                  "materialRecovery"
                }
                onChange={() =>
                  handleCheckboxChange(
                    material.referenceName,
                    "materialRecovery",
                  )
                }
              />
            </div>
            <div className="w-1/5 text-center">
              <input
                className="relative h-5 p-0 w-auto"
                type="checkbox"
                checked={
                  selectedDestinations[material.referenceName] ===
                  "energyRecovery"
                }
                onChange={() =>
                  handleCheckboxChange(material.referenceName, "energyRecovery")
                }
              />
            </div>
            <div className="w-1/5 text-center">
              <input
                className="relative h-5 p-0 w-auto"
                type="checkbox"
                checked={
                  selectedDestinations[material.referenceName] === "landfill"
                }
                onChange={() =>
                  handleCheckboxChange(material.referenceName, "landfill")
                }
              />
            </div>
            <div className="w-1/5 text-center">
              <input
                className="relative h-5 p-0 w-auto"
                type="checkbox"
                checked={
                  selectedDestinations[material.referenceName] ===
                    "undefined" ||
                  !["materialRecovery", "energyRecovery", "landfill"].includes(
                    selectedDestinations[material.referenceName],
                  )
                }
                onChange={() =>
                  handleCheckboxChange(material.referenceName, "undefined")
                }
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default Outlet;
