const CameraHeader = ({ classColumnTable }) => {
  return (
    <article className={classColumnTable}>
      <h6 className="column-title" style={{ marginLeft: "15px" }}>
        Identifiant
      </h6>
      <h6 className="column-title">Dernier scan</h6>
      <h6 className="column-title">Adresse</h6>
      <h6 className="column-title">Etat</h6>
      <h6 className="column-title">Identifiant panneau solaire</h6>
      <h6 className="column-title">Batterie</h6>
      <span className="column-title column-action"></span>
    </article>
  );
};

export default CameraHeader;
