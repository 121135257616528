import React, { useRef, useState } from "react";
import { Card } from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";
import { formattedRoleUser } from "../../../components/formatted/FormattedText";
import ToggleFilterButton from "../../ToggleFilterButton";
import Cookies from "js-cookie";

const UserAccessFilter = ({
  setKeywordFilter,
  keywordFilter,
  roleKeys,
  setListRoleFilter,
}) => {
  const multiselectRoleRef = useRef();

  const cookieValue = Cookies.get("userAccessFilter");
  const parsedValue = cookieValue ? JSON.parse(cookieValue) : false;
  const [filterActive, setFilterActive] = useState(parsedValue);

  const labeledRoleKeys = roleKeys.map((v) => ({
    ...v,
    label: formattedRoleUser(v.value),
  }));
  const cleanFilters = () => {
    setListRoleFilter({});
    multiselectRoleRef.current.resetSelectedValues();
    setKeywordFilter("");
  };

  return (
    <>
      <ToggleFilterButton
        cookiesKey="userAccessFilter"
        filterActive={filterActive}
        setFilterActive={setFilterActive}
      />
      {filterActive && (
        <Card className="card-filter">
          <section className="top-filter">
            <div className="table-filter">
              <span className="filter-name">Filtre par mot clé</span>
              <input
                className="operation-sort"
                type="text"
                id="word"
                name="word"
                value={keywordFilter}
                placeholder="Nom, Prénom, Identifiant, Mail"
                onChange={(event) => setKeywordFilter(event.target.value)}
              ></input>
            </div>

            <div className="table-filter">
              <div className="filter-name">
                <i className="fas fa-user-tag ico-action"></i>
                <span>Rôle</span>
              </div>
              <Multiselect
                options={labeledRoleKeys}
                displayValue="label"
                onSelect={(selectedList) => setListRoleFilter({ selectedList })}
                onRemove={(selectedList) => setListRoleFilter({ selectedList })}
                ref={multiselectRoleRef}
                placeholder="Toutes les roles"
                showArrow={true}
                hidePlaceholder={true}
              />
            </div>

            <div className="table-filter">
              <div className="filter-name"></div>
              <button className="delete-button" onClick={cleanFilters}>
                Effacer les filtres
              </button>
            </div>
          </section>
        </Card>
      )}
    </>
  );
};

export default UserAccessFilter;
