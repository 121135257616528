export const formattedDataChart = (tab, xKey, yKey) => {
  const newTab = [
    tab.map((hist) => {
      if (hist.hasOwnProperty(xKey) && hist.hasOwnProperty(yKey)) {
        return {
          x: hist[xKey],
          y: hist[yKey],
        };
      }
      return null;
    }),
  ];
  return newTab;
};
