import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Modal, ModalBody, Form } from "reactstrap";
import {
  handleEditFormCancel,
  handleEditFormSubmit,
} from "../../../components/modals/EditableTable";
import { ContainerGeneralInformation } from "./containerFormTab/ContainerGeneralInformation";
import { ContainerPreview } from "./containerFormTab/ContainerPreview";
import { ContainerManagement } from "./containerFormTab/ContainerManagement";
import { ContainerSiteAndContract } from "./containerFormTab/ContainerSiteAndContract";
import { toast } from "react-toastify";

const ContainerEdit = ({
  tableData,
  setTableData,
  editFormData,
  setEditFormData,
  modalEdit,
  setModalEdit,
  editAddress,
  superContainer,
  containerRight,
  setSelectedContainer,
  fetchContainer,
}) => {
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");

  const path = "container";
  const modalEditRef = useRef(null);

  const [showGeneralInformations, setShowGeneralInformations] = useState(
    !editAddress ? true : false,
  );
  const [showManagement, setShowManagement] = useState(false);
  const [showSiteAndContract, setShowSiteAndContract] = useState(
    editAddress ? true : false,
  );
  const [sendingForm, setSendingForm] = useState(false);
  const [errorForm, setErrorForm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [sites, setSites] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      await axios
        .all([
          axios.get(`${API}address/getAddressNameList`, {
            headers: { authorization: `Bearer ${token}` },
          }),
          axios.get(`${API}contract/getContractList`, {
            headers: { authorization: `Bearer ${token}` },
          }),
        ])
        .then(
          axios.spread((responseSite, responseContract) => {
            if (
              responseSite.status === 200 ||
              responseContract.status === 200
            ) {
              setSites(responseSite.data.addressNameList);
              setContracts(responseContract.data.contractsList);
              setIsLoading(false);
            }
          }),
        );
    };
    fetchData();
  }, [API, token]);

  const handlemSubmit = async () => {
    setSendingForm(true);
    const response = await handleEditFormSubmit(editFormData, path);
    if (response.status === 200) {
      if (superContainer) {
        let editFormDataCopy = {
          ...editFormData,
          _id: containerRight._id,
          productionDate: containerRight.productionDate,
          containerId: containerRight.containerId,
          name: containerRight.name,
          value: containerRight.value,
          _measurement: containerRight._measurement,
          anomalies: containerRight.anomalies,
          filling_bottom: containerRight.filling_bottom,
          filling_level: containerRight.filling_level,
          statusFilling: containerRight.statusFilling,
          h_box: containerRight.h_box,
          y_box: containerRight.y_box,
          x_box: containerRight.x_box,
          w_box: containerRight.w_box,
          image_url: containerRight.image_url,
        };
        await handleEditFormSubmit(editFormDataCopy, path);
      }
      setSendingForm(true);
      if (superContainer) {
        setSelectedContainer(editFormData);
      } else {
        const newData = [...tableData];
        const index = tableData.findIndex(
          (elem) => elem._id === editFormData._id,
        );
        newData[index] = editFormData;
        setTableData(newData);
      }

      setModalEdit(false);
      toast.success("Le contenant a bien été modifié", {
        className: "custom-toast",
      });
    } else {
      setSendingForm(false);
      console.error(response.data.error);
    }
    await fetchContainer();
  };

  const toggle = () => setModalEdit(!modalEdit);

  return (
    <Modal
      isOpen={modalEdit}
      toggle={toggle}
      ref={modalEditRef}
      scrollable={false}
    >
      <Form
        onSubmit={async (event) => {
          event.preventDefault();
          await handlemSubmit();
        }}
      >
        <section className="flex flex-col px-5 py-4">
          {/* Header */}
          <section className="flex justify-between max-md:flex-col mb-4">
            <div className="flex">
              <h4 className="font-semibold text-2xl">
                Paramètres de mon contenant
              </h4>
              {editFormData.wasteName && (
                <h4 className="ml-2 text-red font-semibold text-2xl capitalize">
                  | {editFormData.wasteName}
                </h4>
              )}
              {editFormData.addressName && (
                <h4 className="ml-2 text-red font-semibold text-2xl capitalize">
                  {" "}
                  - {editFormData.addressName}
                </h4>
              )}
            </div>

            <div className="flex text-base max-md:justify-end">
              <button
                className="px-3 py-2 mr-2 border border-gray-300 rounded-md hover:bg-neutral-100"
                onClick={() => {
                  handleEditFormCancel(setModalEdit);
                }}
              >
                Annuler
              </button>
              <button
                className={`px-3 py-2 rounded text-white ${!sendingForm && errorForm === "" ? "bg-cyan" : "bg-neutral-400 hover:bg-neutral-400"}`}
                type="submit"
                disabled={errorForm !== "" || sendingForm}
              >
                Sauvegarder
              </button>
            </div>
          </section>

          {/* Tabs */}
          <section className="flex max-md:flex-col text-base text-cyan ">
            <div
              className={`mr-2 px-1 cursor-pointer ${showGeneralInformations ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(true);
                setShowManagement(false);
                setShowSiteAndContract(false);
              }}
            >
              Infos générales
            </div>
            <div
              className={`mr-2 px-1 cursor-pointer ${showManagement ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(false);
                setShowManagement(true);
                setShowSiteAndContract(false);
              }}
            >
              Gestion
            </div>
            <div
              className={`mr-2 px-1 cursor-pointer ${showSiteAndContract ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(false);
                setShowManagement(false);
                setShowSiteAndContract(true);
              }}
            >
              Sites & contrats
            </div>
          </section>

          {/* Error */}
          {errorForm && (
            <div className="flex justify-center mx-auto mt-4 p-3 bg-white rounded shadow-3xl w-2/3">
              <i className="fas fa-exclamation-triangle my-auto mx-2 text-yellow"></i>
              <p
                className="my-0"
                dangerouslySetInnerHTML={{ __html: errorForm }}
              ></p>
            </div>
          )}
        </section>

        <ModalBody className="flex px-5 py-2">
          {showGeneralInformations && (
            <ContainerGeneralInformation
              formData={editFormData}
              setFormData={setEditFormData}
              setErrorForm={setErrorForm}
            />
          )}

          {showManagement && (
            <ContainerManagement
              formData={editFormData}
              setFormData={setEditFormData}
              setErrorForm={setErrorForm}
            />
          )}

          {showSiteAndContract && (
            <ContainerSiteAndContract
              isLoading={isLoading}
              sites={sites}
              contracts={contracts}
              formData={editFormData}
              setFormData={setEditFormData}
            />
          )}

          <ContainerPreview
            contracts={contracts}
            formData={editFormData}
            setFormData={setEditFormData}
          />
        </ModalBody>
      </Form>
    </Modal>
  );
};

export default ContainerEdit;
