import { isValidPhoneNumber } from "react-phone-number-input";

export const isValidPhone = function (number, setErrorForm) {
  let errorNumber = true;
  if (number !== "") {
    if (!isValidPhoneNumber(number)) {
      errorNumber = false;
      setErrorForm(`${number} is an invalid phone number for this country`);
    }
  }

  return errorNumber;
};
