import { CustomInput, FormGroup, Input, Label } from "reactstrap";
import { handleFormChange } from "../../../../components/modals/EditableTable";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  verifyCodeOnu,
  verifyCodeWaste,
} from "../../../../components/utils/VerifyMaterialFlowCode";
import { CompactPicker } from "react-color";
import "../../../../static/css/wasteCategory.css";

export const WasteGeneralInformations = ({
  formData,
  setFormData,
  setErrorForm,
  setFormIsVerified,
}) => {
  const [isHazardousWaste, setIsHazardousWaste] = useState(
    formData.hazardous || false,
  );
  const [hex, setHex] = useState("#fff");
  const [showColorPicker, setShowColorPicker] = useState(false);

  const isAkanthasAccount = (Cookies.get("userEmail") || "").includes(
    "@akanthas.com",
  );

  useEffect(() => {
    if (formData?.displayColor) {
      setHex(formData?.displayColor);
    } else {
      setHex("#fff");
    }
  }, [formData]);

  return (
    <section className="flex flex-col">
      {/* Label */}
      <FormGroup className="flex flex-col w-2/3 max-md:w-full mb-3">
        <Label>Nom*</Label>
        <Input
          type="text"
          required="required"
          placeholder="Tapez le nom du flux : carton, DIB, gravats ..."
          value={formData.label}
          onChange={(event) => {
            handleFormChange(event, formData, setFormData);
          }}
          name="label"
        />
      </FormGroup>

      {/* Density*/}
      <FormGroup className="flex flex-col w-2/3 max-md:w-full mb-3">
        <Label>
          Densité(kg/m<sup>3</sup>)
        </Label>
        <Input
          type="number"
          placeholder="Seulement chiffres : 1200"
          value={formData.density}
          onChange={(event) => {
            handleFormChange(event, formData, setFormData);
          }}
          name="density"
        />
      </FormGroup>

      {/* compactionFactor */}
      {isAkanthasAccount && (
        <FormGroup className="flex flex-col w-2/3 max-md:w-full mb-3">
          <Label>Coefficient de compactage</Label>
          <Input
            type="number"
            placeholder="Coefficient de compactage"
            value={formData.compactionFactor}
            onChange={(event) => {
              handleFormChange(event, formData, setFormData);
            }}
            name="compactionFactor"
          />
        </FormGroup>
      )}

      {/* Is hazardous */}
      <FormGroup className="flex flex-col w-1/3 mb-3">
        <Label>Type de flux</Label>
        <div className="flex justify-between w-1/2">
          <span className="text-sm">Non dangereux</span>
          <CustomInput
            className="mx-2"
            type="switch"
            id="isHazardousWaste"
            defaultChecked={formData.hazardous}
            onClick={(event) => {
              handleFormChange(event, formData, setFormData);
              setIsHazardousWaste(!isHazardousWaste);
            }}
            bsSize="lg"
            name="hazardous"
          />
          <span className="text-sm">Dangereux</span>
        </div>
      </FormGroup>

      {/* Is hazardous Informations */}
      {isHazardousWaste && (
        <section className="flex flex-col mb-5 justify-between w-1/3 max-md:w-full rounded-md shadow-3xl ">
          <div className="flex m-2">
            <i className="fas fa-info-circle text-cyan my-auto"></i>
            <span className="font-semibold ml-2">
              Pour vos flux dangereux :
            </span>
          </div>
          <span className="m-2">Renseignez les codes de transport ONU</span>
        </section>
      )}

      {/* Waste code */}
      <FormGroup className="flex flex-col w-2/3 max-md:w-full mb-3">
        <Label>Code déchets</Label>
        <Input
          type="text"
          placeholder="Code à six chiffres"
          value={formData.wasteCodeFull}
          onChange={(event) => {
            handleFormChange(event, formData, setFormData);
            verifyCodeWaste(
              event.target.value,
              setFormIsVerified,
              setErrorForm,
            );
            isHazardousWaste &&
              verifyCodeOnu(
                event.target.value,
                setFormIsVerified,
                setErrorForm,
              );
          }}
          name="wasteCodeFull"
        />
        <span className="text-grey ml-2">
          Le numéro doit être sous la forme 11 99 20
        </span>
      </FormGroup>

      {/* Transport Code ONU */}
      {isHazardousWaste && (
        <FormGroup className="flex flex-col w-2/3 max-md:w-full mb-3">
          <Label>Code transport ONU</Label>
          <Input
            type="text"
            placeholder="Code à quatre chiffres"
            value={formData.unCodeFull}
            onChange={(event) => {
              handleFormChange(event, formData, setFormData);
              verifyCodeWaste(
                event.target.value,
                setFormIsVerified,
                setErrorForm,
              );
              verifyCodeOnu(
                event.target.value,
                setFormIsVerified,
                setErrorForm,
              );
            }}
            name="unCodeFull"
          />
          <span className="text-grey ml-2">
            Le numéro doit être sous la forme 11 99
          </span>
        </FormGroup>
      )}
      {/* Category color */}
      <FormGroup className="flex flex-col w-3/4 mb-3">
        <div
          className="flex flex-col"
          onClick={() => setShowColorPicker(!showColorPicker)}
        >
          <Label>Couleur du flux</Label>
          <div
            className="flex items-center space-x-5 color"
            style={{
              cursor: "pointer",
            }}
          >
            <span
              className="color-selected"
              style={{
                backgroundColor: hex,
                cursor: "pointer",
              }}
            ></span>
            <span>Couleur du flux</span>
            <svg
              class="w-2 h-2 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 8"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
              />
            </svg>
          </div>
        </div>
        <div
          style={{
            width: 200,
            display: !showColorPicker ? "none" : "",
          }}
        >
          <CompactPicker
            color={hex}
            onChange={(color) => {
              setFormData((prevState) => ({
                ...prevState,
                displayColor: color.hex,
              }));
            }}
          />
        </div>
      </FormGroup>
    </section>
  );
};
