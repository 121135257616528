import { useState } from "react";
import { getDimensions } from "../../formatted/CalculateImageDimensions";
import { G, Image, Polygon, Svg } from "@react-pdf/renderer";
import { formattedColorMaterial } from "../../formatted/FormattedText";
import { azureSASTokenToUrl } from "../../formatted/FormattedAzureSasToken";

function deltaBox(point, ratioImage) {
  return Math.max(point * ratioImage, 0);
}

function getWidthOrHeightBox(dimensionBox, pointBox, sizeImage, ratioImage) {
  const maxPointBox = deltaBox(pointBox, ratioImage);
  const newDimensionBox = dimensionBox * ratioImage;
  if (maxPointBox + newDimensionBox > sizeImage * ratioImage) {
    return sizeImage * ratioImage - maxPointBox;
  } else {
    return newDimensionBox;
  }
}

function convertPoints(pointList, ratioImage) {
  let result = "";
  for (const point of pointList) {
    result += point * ratioImage + ",";
  }
  return result;
}

function extremas(points) {
  let xValues = [];
  let yValues = [];
  for (let i = 0; i < points.length; i++) {
    if (i % 2 === 0) {
      xValues.push(points[i]);
    } else {
      yValues.push(points[i]);
    }
  }
  const xMax = Math.max(...xValues);
  const xMin = Math.min(...xValues);
  const yMax = Math.max(...yValues);
  const yMin = Math.min(...yValues);
  return [xMin, yMin, xMax, yMin, xMax, yMax, xMin, yMax];
}

export const ImageDocumentComponent = ({ data, drawMaterial }) => {
  const [widthDimensions, setWidthDimensions] = useState({});
  const [heigthDimensions, setHeigthDimensions] = useState({});

  getDimensions(
    azureSASTokenToUrl(data.image_url),
    setWidthDimensions,
    setHeigthDimensions,
  );

  const isLandscapeCrop = data.h_box / data.w_box < 0.5 ? true : false;
  const imageSize = isLandscapeCrop ? 700 : 380;

  const ratioImage = imageSize / Math.max(data.w_box, data.h_box);

  return (
    <Svg
      width={getWidthOrHeightBox(
        data.w_box,
        data.x_box,
        widthDimensions,
        ratioImage,
      )}
      height={getWidthOrHeightBox(
        data.h_box,
        data.y_box,
        heigthDimensions,
        ratioImage,
      )}
      viewBox={`${deltaBox(data.x_box, ratioImage)}
              ${deltaBox(data.y_box, ratioImage)}
              ${getWidthOrHeightBox(data.w_box, data.x_box, widthDimensions, ratioImage)}
              ${getWidthOrHeightBox(data.h_box, data.y_box, heigthDimensions, ratioImage)}`}
      style={{
        marginVertical: isLandscapeCrop ? "5px" : "1px",
        marginHorizontal: isLandscapeCrop ? "auto" : "0px",
        backgroundColor: "#FFF",
      }}
    >
      <Image
        x={0}
        y={0}
        style={{
          width: widthDimensions * ratioImage,
          height: heigthDimensions * ratioImage,
        }}
        src={azureSASTokenToUrl(data.image_url)}
        cache={true}
        debug={true}
      />

      {data.wasteObjectsList !== undefined &&
        data.wasteObjectsList.length > 0 &&
        data.wasteObjectsList.map((anomalie, index) => {
          if (drawMaterial && anomalie.cls !== undefined) {
            if (
              data.characterization &&
              data.activatedSortingErrorOption &&
              data.activatedActivityOption
            ) {
              return (
                <G key={`annotation-${anomalie._id}`}>
                  <Polygon
                    points={convertPoints(anomalie.points_konva, ratioImage)}
                    fill={formattedColorMaterial(anomalie.cls)}
                    stroke={formattedColorMaterial(anomalie.cls)}
                    strokeWidth={1}
                    opacity="0.25"
                  />
                  <Polygon
                    points={convertPoints(anomalie.points_konva, ratioImage)}
                    stroke={formattedColorMaterial(anomalie.cls)}
                    strokeWidth={1}
                  />

                  {!anomalie.isAuthorizedMaterial && (
                    // Draw box
                    <Polygon
                      points={convertPoints(
                        extremas(anomalie.points_konva),
                        ratioImage,
                      )}
                      stroke={formattedColorMaterial(anomalie.cls)}
                      strokeWidth={1.5}
                    />
                  )}
                </G>
              );
            }

            if (data.characterization) {
              return (
                <G key={`annotation-${anomalie._id}`}>
                  <Polygon
                    points={convertPoints(anomalie.points_konva, ratioImage)}
                    fill={formattedColorMaterial(anomalie.cls)}
                    stroke={formattedColorMaterial(anomalie.cls)}
                    strokeWidth={1}
                    opacity="0.25"
                  />
                  <Polygon
                    points={convertPoints(anomalie.points_konva, ratioImage)}
                    stroke={formattedColorMaterial(anomalie.cls)}
                    strokeWidth={1}
                  />
                </G>
              );
            }

            if (
              data.anomalies > 0 &&
              data.activatedSortingErrorOption &&
              data.activatedActivityOption &&
              !anomalie.isAuthorizedMaterial
            ) {
              return (
                <G key={`annotation-${anomalie._id}`}>
                  <Polygon
                    points={convertPoints(anomalie.points_konva, ratioImage)}
                    fill={formattedColorMaterial(anomalie.cls)}
                    stroke={formattedColorMaterial(anomalie.cls)}
                    strokeWidth={1}
                    opacity="0.25"
                  />
                  <Polygon
                    points={convertPoints(anomalie.points_konva, ratioImage)}
                    stroke={formattedColorMaterial(anomalie.cls)}
                    strokeWidth={1}
                  />

                  <Polygon
                    points={convertPoints(
                      extremas(anomalie.points_konva),
                      ratioImage,
                    )}
                    stroke={formattedColorMaterial(anomalie.cls)}
                    strokeWidth={1.5}
                  />
                </G>
              );
            }
          }
          return null;
        })}
    </Svg>
  );
};
