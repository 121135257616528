import { React } from "react";
import { Image } from "@react-pdf/renderer";
import "react-calendar/dist/Calendar.css";

function ShowClientLogo(corporationID, style) {
  try {
    const source = require(`../../../static/assets/${corporationID}.png`);
    return <Image style={style} src={source} />;
  } catch (error) {
    return null;
  }
}

export default ShowClientLogo;
