import { useEffect, useState } from "react";
import Loading from "../../../components/utils/Loading";
import { formatDateToString } from "../../../components/formatted/FormattedDateAndTime";
import ActivityCollectionModal from "./ActivityCollectionModal";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import { LoadingScreen } from "../../../components/loader/LoadingScreen";
import ActivityCollectionRow from "./ActivityCollectionRow";
import {
  getHistoryCollection,
  getHistoryCycleContainer,
} from "../../../services/container";
import { getWastecategoryList } from "../../../services/waste";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../../../components/dropdownMenu/Dropdown";

const ActivityCollection = ({
  keywordFilter,
  periodHistoryStart,
  periodHistoryStop,
  superContainers,
}) => {
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [modalHistoryCycle, setModalHistoryCycle] = useState(false);
  const [dataRow, setDataRow] = useState(false);
  const [countDataCollection, setCountDataCollection] = useState(0);
  const [dataContainerCollection, setDataContainerCollection] = useState([]);
  const [disabledDownload, setDisabledDownload] = useState(false);

  const [loadScreen, setLoadScreen] = useState(false);
  const [wasteCategory, setWasteCategory] = useState([]);
  const [activeWasteCategory, setActiveWasteCategory] = useState("");

  const [progress, setProgress] = useState(0);

  const weightToTonnes = (weightKg, weightUnit) => {
    if (weightUnit === "t") {
      return weightKg / 1000;
    } else {
      return weightKg;
    }
  };

  const handleCsvExport = async (historyCurrent, timeLine) => {
    setLoadScreen(true);
    const label = [
      "Date",
      "Niveau",
      `Poids (${timeLine.weightUnit === "t" ? "tonnes" : "kg"})`,
    ];
    const lastData = historyCurrent.map((item) => [
      `${moment(item._time).tz("Europe/Paris").format("DD MM YYYY HH:mm")}`,
      `${item.filling_level}%`,
      timeLine.weightUnit === "t" ? (item.mass / 1000).toFixed(2) : item.mass,
    ]);
    const csvContent = [label, ...lastData];
    exportDataToCSV(csvContent, timeLine);
  };

  const exportDataToCSV = (csvContent, container) => {
    const currentDate = new Date();
    const content = csvContent.map((row) => row.join(";")).join("\n");
    const blob = new Blob([content], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Collecte_${formatDateToString(currentDate)}_${container?.addressJoin?.city}_${activeWasteCategory !== "" ? activeWasteCategory : container.wasteName}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    setLoadScreen(false);
  };

  const fetchCycleData = async (item, index) => {
    let cycleData = {};
    const responseHistoryCycle = await getHistoryCycleContainer(
      {
        currentCycle: false,
        getLevelLastRotation: false,
        containerCollectionDate: item._time,
      },
      item._measurement,
    );

    if (responseHistoryCycle.status === 200) {
      cycleData = responseHistoryCycle.data;
    }

    const newProgress = ((index + 1) / dataContainerCollection.length) * 100;
    setProgress(newProgress);

    return [
      `${moment(item._time).tz("Europe/Paris").format("DD-MM-YYYY HH:mm")}`,
      `${item.addressName}${item?.positioning ? " | " + item.positioning : ""}`,
      `${item.wasteName}`,
      `${item.filling_level}%`,
      (cycleData.historyCycle[0].mass / 1000).toFixed(2),
      cycleData.numberOfPackmat,
    ];
  };

  const exportCollectionToCSV = async () => {
    setLoadScreen(true);
    const label = [
      "Date",
      "Adresse",
      "Flux",
      "Niveau",
      "Poids (tonnes)",
      "Compactage",
    ];
    const lastData = [];
    for (let i = 0; i < dataContainerCollection.length; i++) {
      const item = dataContainerCollection[i];
      const data = await fetchCycleData(item, i);
      lastData.push(data);
    }
    const csvContent = [label, ...lastData];

    const content = csvContent.map((row) => row.join(";")).join("\n");
    const blob = new Blob([content], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Collecte_du_${moment(periodHistoryStart).tz("Europe/Paris").format("DD-MM-YYYY_HH-mm")}_au_${moment(periodHistoryStop).tz("Europe/Paris").format("DD-MM-YYYY_HH-mm")}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    setProgress(0);
    setLoadScreen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getHistoryCollection({
      periodStart: periodHistoryStart,
      periodStop: periodHistoryStop,
      keywordFilter: JSON.stringify(keywordFilter.value),
      keepFirst: false,
    })
      .then((response) => {
        if (response.status === 200) {
          setDataContainerCollection(response.data.historyCollection);
          if (response.data.historyCollection.length > 0) {
            setCountDataCollection(response.data.historyCollection.length);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [API, token, periodHistoryStart, periodHistoryStop, keywordFilter]);

  useEffect(() => {
    getWastecategoryList({
      keywordFilter: "",
      listUnCodeFullFilter: {},
      listUnCodeEmptyFilter: {},
      listWasteCodeFullFilter: {},
      listWasteCodeEmptyFilter: {},
      sortByField: "label",
      orderSortByField: "1",
    }).then((response) => {
      setWasteCategory(response.data.data);
    });
  }, []);

  useEffect(
    () => {
      setDisabledDownload(false);
    },
    //eslint-disable-next-line
    [periodHistoryStart, periodHistoryStop],
  );

  return (
    <div className="card-block-activity-item">
      <section className="flex flex-end w-full mt-2">
        <Dropdown
          text={"Exporter"}
          disabled={disabledDownload || isLoading}
          children={
            <>
              <span
                onClick={() => exportCollectionToCSV()}
                className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 cursor-pointer"
              >
                CSV (.csv)
              </span>
            </>
          }
        />
      </section>

      {isLoading ? (
        <Loading color="#F5F5F5" />
      ) : (
        <div>
          <section className="time-line">
            <div className="column-row time-line-col">
              <h6
                className="time-line-title-large column-title"
                style={{ marginLeft: "200px" }}
              >
                {" "}
              </h6>
              <h6 className="time-line-data-large column-title">
                {t("Address")}
              </h6>
              <h6 className="time-line-title-large column-title">
                <span className="ml-1">{t("Flux")}</span>
              </h6>
              <h6 className="time-line-title column-title">{t("Level")}</h6>
              <h6 className="time-line-data-large column-title ">
                <span className="ml-3">{t("Weight")}</span>
              </h6>
              <h6 className="time-line-title column-title">
                {t("Compaction")}
              </h6>
              <h6 className="time-line-title column-title">
                <span className="ml-3">{t("Anomalies")}</span>
              </h6>
              <h6 className="time-line-title-large column-title">
                <span className="ml-3">{t("Quality")}</span>
              </h6>
              <h6 className="time-line-title-large column-title">
                {t("Area/Container")}
              </h6>
              <h6 className="time-line-title column-title">
                <span className="ml-3">{t("Files")}</span>
              </h6>
              <h6 className="time-line-title column-title"> </h6>
            </div>

            {countDataCollection === 0 ? (
              <div className="no-data">Aucune collecte pour cette période.</div>
            ) : (
              <div className="time-line-list">
                {dataContainerCollection.map((timeLine, index) => {
                  return (
                    <ActivityCollectionRow
                      timeLine={timeLine}
                      index={index}
                      setModalHistoryCycle={setModalHistoryCycle}
                      setDataRow={setDataRow}
                      loadScreen={loadScreen}
                      handleCsvExport={handleCsvExport}
                      wasteCategory={wasteCategory}
                      superContainers={superContainers}
                      setActiveWasteCategory={setActiveWasteCategory}
                      activeWasteCategory={activeWasteCategory}
                      weightToTonnes={weightToTonnes}
                    />
                  );
                })}
              </div>
            )}
          </section>

          {modalHistoryCycle && (
            <ActivityCollectionModal
              data={dataRow}
              modalHistoryCycle={modalHistoryCycle}
              setModalHistoryCycle={setModalHistoryCycle}
              exportDataToCSV={exportDataToCSV}
              activeWasteCategory={activeWasteCategory}
            />
          )}

          {loadScreen && (
            <LoadingScreen isProgress={true} progress={progress} />
          )}
        </div>
      )}
    </div>
  );
};

export default ActivityCollection;
