import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { ModalTitle } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { useState } from "react";
import Loading from "../../components/utils/Loading";
import CloseIcon from "@mui/icons-material/Close";

const HelpOpenFile = ({
  isModalDownload,
  setIsModalDownload,
  titleModal,
  fileName,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePageBack() {
    changePage(-1);
  }

  function changePageNext() {
    changePage(+1);
  }

  function changePage(offSet) {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  }

  return (
    <Modal
      isOpen={isModalDownload}
      toggle={() => setIsModalDownload(!isModalDownload)}
    >
      <ModalHeader>
        <ModalTitle>{titleModal}(format PDF)</ModalTitle>
        <CloseIcon
          style={{
            color: "var(--gray)",
            cursor: "pointer",
          }}
          onClick={() => {
            setIsModalDownload(false);
          }}
        />
      </ModalHeader>

      <ModalBody className="modal-confirmation">
        <div className="help-viewer">
          <Document
            file={`/help/${fileName}`}
            onLoadSuccess={onDocumentLoadSuccess}
            className="help-viewer-file"
            options={{
              cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}
          >
            <Page
              className="help-viewer-page"
              width={1020}
              height={680}
              pageNumber={pageNumber}
              loading={<Loading color="#F5F5F5" height={650} />}
            />
          </Document>
          <div className="help-viewer-nav">
            <button onClick={() => pageNumber > 1 && changePageBack()}>
              <i className="fas fa-arrow-left"></i>
            </button>
            <p>
              Page {pageNumber} de {numPages}
            </p>
            <button onClick={() => pageNumber < numPages && changePageNext()}>
              <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default HelpOpenFile;
