import {
  Chart as ChartJS,
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { fr } from "date-fns/locale";
import { formattedDate } from "../../components/formatted/FormattedDateAndTime";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookie from "js-cookie";
import { useTranslation } from "react-i18next";

ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
);

const MultipleLineAndBarChart = ({
  stackedChart,
  type,
  labelsChart,
  borderWidthChart,
  aspectRatioChart,
  datasetsChart,
  indexAxisChart,
  maintainAspectRatioChart,
  displayGrid,
  displayTicks,
  xAxisType,
  reverseChart,
  displayLegend,
  displayTooltip,
  messageLabelTooltip,
  unitValueChart,
  maxYAxis,
  additionalInfo,
}) => {
  const { i18n } = useTranslation();
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookie.get("userToken");
  const [chartData, setChartData] = useState(null);

  const wasteCategoryColor = (category, waste) => {
    const res = category.find((item) => item.label === waste.label);
    if (res) {
      return res?.displayColor;
    } else {
      return waste.backgroundColor;
    }
  };

  useEffect(() => {
    axios
      .get(`${API}wastecategory?page=1&limit=100`, {
        headers: { authorization: `Bearer ${token}` },
        params: {
          keywordFilter: "",
          listUnCodeFullFilter: {},
          listUnCodeEmptyFilter: {},
          listWasteCodeFullFilter: {},
          listWasteCodeEmptyFilter: {},
          sortByField: "label",
          orderSortByField: "1",
        },
      })
      .then((response) => {
        if (type === "bar") {
          const newData = datasetsChart.map((item) => {
            let newItem = item;
            const data = item.data.sort(
              (a, b) => new Date(a.x) - new Date(b.x),
            );
            newItem.data = data;
            return {
              ...newItem,
              backgroundColor: wasteCategoryColor(response.data.data, item),
            };
          });
          setChartData(newData);
        } else if (type === "line") {
          const newData = datasetsChart.map((item) => {
            let newItem = item;
            const data = item.data.sort(
              (a, b) => new Date(a.x) - new Date(b.x),
            );
            newItem.data = data;
            return {
              ...newItem,
              backgroundColor: wasteCategoryColor(response.data.data, item),
              borderColor: wasteCategoryColor(response.data.data, item),
            };
          });
          setChartData(newData);
        } else {
          setChartData(datasetsChart);
        }
      });
  }, [datasetsChart, API, token, type]);

  const optionsMultipleChart = {
    maintainAspectRatio: maintainAspectRatioChart,
    aspectRatio: aspectRatioChart,
    indexAxis: indexAxisChart,
    borderSkipped: false,
    borderWidth: borderWidthChart,
    barPercentage: 0.9,
    categoryPercentage: 1,
    scales: {
      x: {
        stacked: stackedChart,
        beginAtZero: true,
        offsetAfterAutoskip: true,

        grid: {
          display: displayGrid,
          drawTicks: displayGrid,
        },
        border: {
          display: false,
        },
        ticks: {
          precision: 0,
          source: "auto",
          display: displayTicks,
          maxRotation: 0,
        },
        type: xAxisType,
        time: {
          isoWeekday: true,
          rounded: true,
          unit: "month",
          displayFormats: {
            quarter: "MMM YYYY",
          },
        },
        adapters: {
          date: {
            locale: fr,
          },
        },
      },
      y: {
        stacked: stackedChart,
        beginAtZero: true,
        max: maxYAxis,
        reverse: reverseChart,
        grid: {
          display: displayGrid,
          drawTicks: displayGrid,
          drawOnChartArea: displayGrid,
        },
        border: {
          display: false,
        },
        ticks: {
          precision: 0,
          display: displayTicks,
          callback: function (value, index, values) {
            return value + " " + unitValueChart;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: displayLegend,
        position: "bottom",
        align: "start",
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          padding: 15,
          textAlign: "left",
          usePointStyle: true,
          useBorderRadius: true,
          borderRadius: 30,
          font: {
            size: 10,
          },
        },
      },
      tooltip: {
        enabled: displayTooltip,
        backgroundColor: "#f8AA00",
        titleAlign: "center",
        callbacks: {
          title: function (context) {
            let label = context[0].dataset.data[context[0].dataIndex].x || "";
            let labelCat = context[0].label;
            return xAxisType === "time"
              ? `${formattedDate(label, i18n.language)}`
              : labelCat;
          },
          label: function (context) {
            let value = context.dataset.data[context.dataIndex].y || "";
            let type = context.dataset.data[context.dataIndex].z || "";
            let count = context.dataset.data[context.dataIndex].count || "";

            let message = `${type}: ${value}${unitValueChart} ${messageLabelTooltip}`;
            if (additionalInfo) {
              let materialCountMessage = ` (${count} ${count > 1 ? "objets" : "objet"})`;
              message = message + materialCountMessage;
            }
            return message;
          },
        },
      },
    },
  };

  return chartData ? (
    type === "bar" ? (
      <Bar
        data={{
          labels: labelsChart,
          datasets: chartData,
        }}
        options={optionsMultipleChart}
      />
    ) : (
      <Line
        data={{
          labels: labelsChart,
          datasets: chartData,
        }}
        options={optionsMultipleChart}
      />
    )
  ) : (
    ""
  );
};

export default MultipleLineAndBarChart;
