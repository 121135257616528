import React, { useEffect, useState, useCallback } from "react";
import { Input, Label } from "reactstrap";
import { formattedDataChart } from "../../../../components/formatted/FormattedChart";
import Loading from "../../../../components/utils/Loading";
import LineAndBarChart from "../../../charts/LineAndBarChart";
import { handleOpenModalClick } from "../../../../components/modals/EditableTable";
import {
  currentMonthName,
  previousMonthName,
  capitalizeFirstLetter,
} from "../../../../components/utils/Date";
import CalendarComponent from "../../../../components/CalendarComponent";
import ContainerEdit from "../ContainerEdit";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { isMoreThanFiveMinutesAgo } from "../../../../components/formatted/FormattedDateAndTime";
import {
  getHistoryFilling,
  getHistoryFillingSpeed,
} from "../../../../services/container";
import { useTranslation } from "react-i18next";

const ContainerViewFilling = ({
  data,
  tableData,
  setTableData,
  updateData,
}) => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const { _time, container } = JSON.parse(
    sessionStorage.getItem("containerDetails"),
  );
  const [isLoading, setIsLoading] = useState(true);
  const [deltaCustomDate, setDeltaCustomDate] = useState(0);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  // const [displayMassChart, setDisplayMassChart] = useState(false);
  const [displayFillingChart, setDisplayFillingChart] = useState(true);
  const [displayVolumeChart, setDisplayVolumeChart] = useState(false);
  const [customDate, setCustomDate] = useState(false);
  const [helpSpeedFilling, setHelpSpeedFilling] = useState(false);
  const [helpRotationMonth, setHelpRotationMonth] = useState(false);
  const [helpFillingRotation, setHelpFillingRotation] = useState(false);
  const [historyFillingLevel, setHistoryFillingLevel] = useState([]);
  // const [historyMassFilling, setHistoryMassFilling] = useState([]);
  const [historyRotation, setHistoryRotation] = useState([]);
  const [historyVolumeFilling, setHistoryVolumeFilling] = useState([]);
  const [maxFillingBeforeRotation, setMaxFillingBeforeRotation] = useState([]);
  const [meanFillingLevelByDay, setMeanFillingLevelByDay] = useState([]);
  const [meanFillingLevelByOpenedDay, setMeanFillingLevelByOpenedDay] =
    useState([]);
  const [meanFillingLevelByOpenedHour, setMeanFillingLevelByOpenedHour] =
    useState([]);
  const [rotationMonthCurrent, setRotationMonthCurrent] = useState([]);
  const [periodFillingLevelStart, setPeriodFillingLevelStart] = useState("1");
  const [periodFillingLevelStop, setPeriodFillingLevelStop] = useState("");
  const [modalEdit, setModalEdit] = useState(false);
  const [formData, setFormData] = useState({});

  const fetchData = useCallback(() => {
    getHistoryFilling(
      {
        periodFillingLevelStart: periodFillingLevelStart,
        periodFillingLevelStop: periodFillingLevelStop,
        customDate: periodFillingLevelStop ? true : false,
      },
      id,
    ).then((responseHistoryFilling) => {
      if (responseHistoryFilling.status === 200) {
        //History Filling
        setRotationMonthCurrent(
          responseHistoryFilling.data.rotationMonthCurrent[0],
        );
        setMaxFillingBeforeRotation(
          responseHistoryFilling.data.maxFillingBeforeRotation[0],
        );
        setDeltaCustomDate(responseHistoryFilling.data.deltaCustomDate);
        setHistoryRotation(responseHistoryFilling.data.historyRotation);
        // setHistoryMassFilling(responseHistoryFilling.data.historyMassFilling);
        setHistoryVolumeFilling(
          responseHistoryFilling.data.historyVolumeFilling,
        );
        setHistoryFillingLevel(responseHistoryFilling.data.historyFillingLevel);

        setIsLoading(false);
      }
    });
  }, [
    id,
    periodFillingLevelStart,
    periodFillingLevelStop,
    setRotationMonthCurrent,
  ]);

  const fetchHistoryFillingSpeed = useCallback(() => {
    getHistoryFillingSpeed(id).then((responseSpeedFilling) => {
      //Speed Filling
      setMeanFillingLevelByOpenedDay(
        responseSpeedFilling.data.fillingPerOpenedWeekday[0],
      );
      setMeanFillingLevelByOpenedHour(
        responseSpeedFilling.data.fillingPerOpenedHour[0],
      );
      setMeanFillingLevelByDay(responseSpeedFilling.data.fillingPerWeekday[0]);
      const newData = {
        ...container,
        meanFillingLevelByOpenedDay:
          responseSpeedFilling.data.fillingPerOpenedWeekday[0],
        meanFillingLevelByOpenedHour:
          responseSpeedFilling.data.fillingPerOpenedHour[0],
        meanFillingLevelByDay: responseSpeedFilling.data.fillingPerWeekday[0],
      };

      updateData(newData);
      setIsLoading(false);
    });
  }, [id, container, updateData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(
    () => {
      if (isMoreThanFiveMinutesAgo(_time)) {
        fetchHistoryFillingSpeed();
      } else {
        setMeanFillingLevelByOpenedDay(container?.meanFillingLevelByOpenedDay);
        setMeanFillingLevelByOpenedHour(
          container?.meanFillingLevelByOpenedHour,
        );
        setMeanFillingLevelByDay(container?.meanFillingLevelByDay);
      }
    },
    // eslint-disable-next-line
    [_time, container],
  );

  const selectPeriodFilter = (value) => {
    if (value === "calendar") {
      setCustomDate(true);
      setDisplayCalendar(true);
    } else {
      setCustomDate(false);
      setPeriodFillingLevelStart(value);
      setPeriodFillingLevelStop("");
      setHistoryFillingLevel([]);
      setHistoryRotation([]);
    }
  };

  const onChangeCalendar = (event) => {
    setPeriodFillingLevelStop(new Date(event[1]).toISOString());
    setPeriodFillingLevelStart(new Date(event[0]).toISOString());

    setDisplayCalendar(false);
  };

  const dataHistoryFillingLevel = formattedDataChart(
    historyFillingLevel,
    "_time",
    "filling_level",
  );
  const labelsHistoryFillingLevel = [
    ...historyFillingLevel.map((hist) => hist._time),
  ];
  const dataHistoryRotation = formattedDataChart(
    historyRotation,
    "_time",
    "rotation",
  );
  const labelsHistoryRotation = [...historyRotation.map((hist) => hist._time)];
  // const dataHistoryMassFilling = formattedDataChart(historyMassFilling,"_time","mass");
  // const labelsHistoryMassFilling = [...historyMassFilling.map(hist =>(hist._time))];
  const dataHistoryVolumeFilling = formattedDataChart(
    historyVolumeFilling,
    "_time",
    "volume",
  );
  const labelsHistoryVolumeFilling = [
    ...historyVolumeFilling.map((hist) => hist._time),
  ];

  return isLoading ? (
    <Loading color="#efefeffb" />
  ) : (
    <div className="modal-nav-item">
      <section className="modal-item-key">
        <article className="modal-item-key-content">
          <div className="modal-item-key-title">
            <span>{t("Average filling per rotation")}</span>
            <i
              className="fas fa-info-circle"
              onMouseOver={() => setHelpFillingRotation(true)}
              onMouseOut={() => setHelpFillingRotation(false)}
            ></i>
          </div>
          <div className="modal-item-key-value">
            <article>
              {/* <i className="fas fa-long-arrow-alt-up arrow-positive"></i> */}
              <h5 className="font-size-35">
                {maxFillingBeforeRotation
                  ? parseInt(maxFillingBeforeRotation.filling_level)
                  : "-"}
                %
              </h5>
            </article>
            {/* <div>
              <span>Mois dernier (Bientôt)</span>
            </div> */}
          </div>
          {helpFillingRotation ? (
            <article className="modal-item-key-help">
              {t(
                "Average filling rate at collection over the last three months",
              )}
              .
            </article>
          ) : null}
        </article>

        <article className="modal-item-key-content">
          <div className="modal-item-key-title">
            <span>
              Rotation {t("on")}{" "}
              {capitalizeFirstLetter(currentMonthName(i18n.language))}
            </span>
            <i
              className="fas fa-info-circle"
              onMouseOver={() => setHelpRotationMonth(true)}
              onMouseOut={() => setHelpRotationMonth(false)}
            ></i>
          </div>
          <div className="modal-item-key-value">
            <article>
              {/* <i className="fas fa-long-arrow-alt-up arrow-negative"></i> */}
              <h5 className="font-size-35">
                {rotationMonthCurrent ? rotationMonthCurrent.rotation : 0}
              </h5>
            </article>
            <div>
              {/* <span>Mois dernier (Bientôt)</span> */}
              {/* <span>Mois prochain (Bientôt)</span> */}
            </div>
          </div>
          {helpRotationMonth ? (
            <article className="modal-item-key-help">
              {t("Number of rotations in the current month")}.
            </article>
          ) : null}
        </article>

        <article className="modal-item-key-content">
          <div className="modal-item-key-title">
            <span>{t("Filling speed")}</span>
            <i
              className="fas fa-info-circle"
              onMouseOver={() => setHelpSpeedFilling(true)}
              onMouseOut={() => setHelpSpeedFilling(false)}
            ></i>
          </div>
          <div className="modal-item-key-value">
            {meanFillingLevelByDay !== undefined ? (
              <h5 className="font-size-35">
                {Math.round(meanFillingLevelByDay * 10) / 10}% /
                <span>{t("Day")}</span>
              </h5>
            ) : (
              <h5 className="font-size-35">-</h5>
            )}
            {/* <i className="fas fa-long-arrow-alt-up arrow-negative"></i> */}
            {meanFillingLevelByOpenedDay !== undefined ? (
              <div>
                <span>
                  {Math.round(meanFillingLevelByOpenedHour * 10) / 10}% /{" "}
                  {t("opening hour")}
                </span>
              </div>
            ) : null}
            {/*
            <div>
              <span>Mois dernier (Bientôt)</span>
              <span>Mois prochain (Bientôt)</span>
            </div>
            */}
          </div>
          {helpSpeedFilling ? (
            <article className="modal-item-key-help">
              {t(
                "Average filling speed per calendar day (includes any packmat)",
              )}
              .
            </article>
          ) : null}
        </article>
      </section>

      <section className="flex flex-end">
        {customDate ? (
          /* Calendar */
          <CalendarComponent
            classNameBase="relative flex mx-2"
            onChangeCalendar={onChangeCalendar}
            displayCalendar={displayCalendar}
            setDisplayCalendar={setDisplayCalendar}
            periodHistoryStart={periodFillingLevelStart}
            periodHistoryStop={periodFillingLevelStop}
          />
        ) : null}

        <article className="flex">
          <Label className="mx-1 my-auto">{t("Period")}:</Label>
          <Input
            type="select"
            name="select-graphe"
            value={periodFillingLevelStart}
            onChange={(event) => selectPeriodFilter(event.target.value)}
          >
            <option value="0">
              {t("Current month")} :{" "}
              {capitalizeFirstLetter(currentMonthName(i18n.language))}
            </option>
            <option value="1">
              {t("Last month")} :{" "}
              {capitalizeFirstLetter(previousMonthName(i18n.language))}
            </option>
            <option value="-3mo">{t("Last 3 months")}</option>
            <option value="-6mo">{t("Last 6 months")}</option>
            <option value="-12mo">{t("Last 12 months")}</option>
            <option value="calendar">{t("Custom period")}</option>
          </Input>
        </article>
      </section>

      <section className="modal-item-container">
        <article className="modal-item-container-content">
          <div className="modal-item-container-title">
            <h5 className="data-bold">{t("Filling level")}</h5>
            <article className="modal-item-container-chart-type flex-end">
              <span
                className={
                  displayFillingChart
                    ? "modal-item-container-chart-type-select"
                    : ""
                }
                onClick={() => {
                  setDisplayFillingChart(true);
                  setDisplayVolumeChart(false);
                }}
              >
                {t("Level")}
              </span>
              {/* <span className={displayMassChart ? "modal-item-container-chart-type-select" : ''} onClick={()=>{setDisplayFillingChart(false);setDisplayMassChart(true);setDisplayVolumeChart(false)}}>Masse</span> */}
              <span
                className={
                  displayVolumeChart
                    ? "modal-item-container-chart-type-select"
                    : ""
                }
                onClick={() => {
                  setDisplayFillingChart(false);
                  setDisplayVolumeChart(true);
                }}
              >
                Volume
              </span>
              ​
            </article>
          </div>
          <div className="modal-item-chart">
            {/* {displayMassChart ?

              data.contentCategory && data.contentCategory.density !== 0 && data.volume && Number(data.volume) !== 0 ?
                <LineAndBarChart
                  dataChart={dataHistoryMassFilling[0]}
                  type='line'
                  labelsChart={labelsHistoryMassFilling}
                  xAxisType='time'
                  unitValueChart='kg'
                  maxYAxis={undefined}
                  stepYAxis={undefined}
                  messageLabelTooltip='Poids'
                />

              : <div className="modal-item-chart-no-data">
                  <p>La densité et le volume sont des paramètres nécessaires pour le calcul de la masse.</p>
                  <div className="modal-item-chart-button">
                    {data.contentCategory && data.contentCategory.density === 0 ?
                      <Link className = ""to={{ pathname: "/waste"}}><button className="button-submit">Ajouter la densité</button></Link>
                    :null}
                    {!data.volume || data.volume === 0 ?
                      <button className="button-submit" onClick={() => handleOpenModalClick(data, setFormData, setModalEdit)}>Ajouter le volume</button>
                    :null}
                  </div>
                </div>
              : null
            } */}

            {displayFillingChart ? (
              <LineAndBarChart
                dataChart={dataHistoryFillingLevel[0]}
                type="line"
                labelsChart={labelsHistoryFillingLevel}
                xAxisType="time"
                unitValueChart="%"
                maxYAxis={100}
                stepYAxis={undefined}
                messageLabelTooltip="Niveau"
              />
            ) : null}

            {displayVolumeChart ? (
              data.volume && data.volume !== 0 ? (
                <LineAndBarChart
                  dataChart={dataHistoryVolumeFilling[0]}
                  type="line"
                  labelsChart={labelsHistoryVolumeFilling}
                  xAxisType="time"
                  unitValueChart="m3"
                  maxYAxis={undefined}
                  stepYAxis={undefined}
                  messageLabelTooltip="Taux"
                />
              ) : (
                <div className="modal-item-chart-no-data">
                  <p>
                    {t(
                      "Volume is a crucial parameter for calculating filling volume",
                    )}
                    .
                  </p>
                  <div className="modal-item-chart-button">
                    <button
                      className="button-submit"
                      onClick={() =>
                        handleOpenModalClick(data, setFormData, setModalEdit)
                      }
                    >
                      {t("Add volume")}
                    </button>
                  </div>
                </div>
              )
            ) : null}
          </div>
        </article>

        <article className="modal-item-container-content">
          <div className="modal-item-container-title">
            <h5 className="data-bold">
              Rotation{" "}
              {periodFillingLevelStart === "1" ||
              periodFillingLevelStart === "0" ||
              (customDate && deltaCustomDate <= 1)
                ? t("per day")
                : periodFillingLevelStart === "-3mo" ||
                    (customDate && deltaCustomDate < 6)
                  ? "per week"
                  : "per month"}
            </h5>
            {/* <p>Dernière rotation<p className="data-bold">: 45%  | lun. 5 sept. - 10:00</p>​</p> */}
          </div>

          <div className="modal-item-chart">
            <LineAndBarChart
              dataChart={dataHistoryRotation[0]}
              type="bar"
              labelsChart={labelsHistoryRotation}
              xAxisType="time"
              unitValueChart=""
              maxYAxis={undefined}
              stepYAxis={1}
              messageLabelTooltip={t("Number of rotations")}
            />
          </div>
        </article>
      </section>
      {modalEdit && formData._id === data._measurement && (
        <ContainerEdit
          tableData={tableData}
          setTableData={setTableData}
          editFormData={formData}
          setEditFormData={setFormData}
          modalEdit={modalEdit}
          setModalEdit={setModalEdit}
        />
      )}
    </div>
  );
};

export default ContainerViewFilling;
