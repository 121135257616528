const rules = {
  logistic: {
    static: ["navbar:dashboard", "navbar:containers"],
  },
  owner: {
    static: [
      "navbar:dashboard",
      "navbar:containers",
      "navbar:access",
      "navbar:address",
    ],
  },
};

export default rules;
