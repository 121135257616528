import { StyleSheet } from "@react-pdf/renderer";

export const CoverPageStyleSheet = StyleSheet.create({
  contentLogo: {
    marginHorizontal: "auto",
    marginVertical: 30,
  },

  logo: {
    marginHorizontal: "auto",
    marginVertical: 30,
    width: 185,
    height: 70,
  },

  coverTitle: {
    marginHorizontal: "auto",
    marginVertical: 20,
  },

  title: {
    fontSize: 25,
    fontWeight: 900,
    marginVertical: 15,
    marginHorizontal: "auto",
  },

  secondTitle: {
    fontSize: 20,
    marginVertical: 5,
    marginHorizontal: "auto",
  },

  thirdTitle: {
    fontSize: 18,
    marginVertical: 5,
    marginHorizontal: "auto",
  },

  cyan: {
    color: "#00a7a6;",
  },
});
