import React, { useState, useEffect, useRef } from "react";
import LoginIcon from "@mui/icons-material/Login";

export const Dropdown = ({ text, disabled, children }) => {
  const [display, setDisplay] = useState(false);
  const exportDropdownRef = useRef(null);

  const childrenHandler = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      onClick: () => {
        if (child.props.onClick) {
          child.props.onClick();
        }
        handleClick();
      },
    });
  });

  const handleClick = () => {
    setDisplay(!display);
  };

  useEffect(() => {
    setDisplay(false);
  }, [disabled]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        exportDropdownRef.current &&
        !exportDropdownRef.current.contains(event.target)
      ) {
        setDisplay(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={exportDropdownRef}
      style={{ backgroundColor: disabled ? "#d0d0d0" : "var(--akanthas-cyan)" }}
      className={`inline-flex border rounded-md cursor-pointer text-md ${disabled && "border cursor-not-allowed"}`}
      onClick={() => !disabled && handleClick()}
    >
      <span
        className={`pl-2 py-2 text-md rounded-l-md ${disabled ? "text-gray-400" : "text-white"}`}
      >
        {text}
      </span>

      <div className="relative">
        <button
          type="button"
          className={`inline-flex items-center justify-center h-full px-2 rounded-r-md ${disabled ? "text-gray-400" : "text-white"}`}
        >
          <LoginIcon
            sx={{ transform: "rotate(90deg)", marginTop: -0.5, height: 20 }}
          />
        </button>

        <div
          style={{ display: display && !disabled ? "" : "none" }}
          className="absolute right-0 z-10 w-40 mt-2 origin-top-right bg-white border border-gray-100 rounded-md shadow-lg"
        >
          <div className="p-2">{childrenHandler}</div>
        </div>
      </div>
    </div>
  );
};
