import { Redirect } from "react-router";
import { useState, useRef } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import {
  formattedContainerId,
  formattedTypeContainer,
} from "../../../components/formatted/FormattedText";
import {
  deltaMilliseconds,
  englishMonth,
  formattedDate,
  frenchMonth,
  getEndOfMonth,
  getMonthsAgo,
  getMonthsInRange,
  getPreviousPeriod,
  getStartOfMonth,
} from "../../../components/formatted/FormattedDateAndTime";
import StatisticsCollection from "./collection/StatisticsCollection";
import StatisticsQuality from "./quality/StatisticsQuality";
import { useEffect } from "react";
import CalendarComponent from "../../../components/CalendarComponent";
import {
  countContainers,
  getKeywordFilterOptions,
} from "../../../services/container";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import {
  capitalizeFirstLetter,
  currentMonthName,
  previousMonthName,
} from "../../../components/utils/Date";
import { addressCountSiteUsed } from "../../../services/address";
import axios from "axios";
import StatisticsCO2 from "./Co2/StatisticsCo2";
import { useLocation } from "react-router-dom";

const StatisticsNew = ({ API, token }) => {
  const location = useLocation();
  const {
    activateCollectionField = true,
    activateQualityField = false,
    activateCostField = false,
    activateCarbonField = false,
  } = location.state || {};

  const { t, i18n } = useTranslation();
  const [keywordFilterOptions, setKeywordFilterOptions] = useState([]);
  const [keywordFilter, setKeywordFilter] = useState([]);
  const [showCollection, setShowCollection] = useState(activateCollectionField);
  const [showQuality, setShowQuality] = useState(activateQualityField);
  const [showCost, setShowCost] = useState(activateCostField);
  const [showCarbon, setShowCarbon] = useState(activateCarbonField);
  const multiselectKeywordRef = useRef(null);
  const [showDate, setShowDate] = useState(false);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [numberOfContainers, setNumberOfContainers] = useState(0);
  const [numberOfSite, setNumberOfSite] = useState(0);

  const [periodHistoryStop, setPeriodHistoryStop] = useState(new Date());
  const [periodHistoryStart, setPeriodHistoryStart] = useState(
    getStartOfMonth(new Date()).toISOString(),
  );

  const [periodHistoryStopPrevious, setPeriodHistoryStopPrevious] =
    useState(periodHistoryStart);
  const [periodHistoryStartPrevious, setPeriodHistoryStartPrevious] = useState(
    getStartOfMonth(
      new Date(new Date().getFullYear(), new Date().getMonth() - 1),
    ).toISOString(),
  );

  let periodString = null;
  if (i18n.language === "fr") {
    periodString = `Depuis le 1er ${frenchMonth[new Date().getMonth()]}`;
  } else {
    periodString = `Since 1st ${englishMonth[new Date().getMonth()]}`;
  }

  const [formatedPeriod, setFormatedPeriod] = useState(periodString);

  const fetchData = () => {
    getKeywordFilterOptions({
      keywordFilter: JSON.stringify(keywordFilter.value),
    }).then((responseFilterOptions) => {
      if (responseFilterOptions.status === 200) {
        setKeywordFilterOptions(
          responseFilterOptions.data.keywordFilterOptions.filter(
            (keyword) => keyword.key !== null,
          ),
        );
      }
    });
  };

  useEffect(
    () => {
      fetchData();
    },

    // eslint-disable-next-line
    [
      keywordFilter,
      periodHistoryStart,
      periodHistoryStop,
      periodHistoryStartPrevious,
      periodHistoryStopPrevious,
    ],
  );

  const labeledkeywordFilterOptions = keywordFilterOptions.map((keyword) => {
    if (keyword.type === "type") {
      keyword = { ...keyword, key: formattedTypeContainer(keyword.key) };
    } else if (keyword.type === "id") {
      keyword = {
        ...keyword,
        key: formattedContainerId(keyword.key) + keyword.key,
      };
    }
    return keyword;
  });

  const selectPeriodFilter = (value) => {
    setShowDate(value === "calendar");
    let periodString = null;
    const now = new Date();

    switch (value) {
      case "0": {
        const startOfMonth = getStartOfMonth(now);
        const previousPeriod = getPreviousPeriod(now, 1);

        setPeriodHistoryStart(startOfMonth.toISOString());
        setPeriodHistoryStop(now.toISOString());
        setPeriodHistoryStartPrevious(previousPeriod.start.toISOString());
        setPeriodHistoryStopPrevious(previousPeriod.end.toISOString());

        if (i18n.language === "fr") {
          periodString = `Depuis le 1er ${frenchMonth[now.getMonth()]}`;
        } else {
          periodString = `Since 1st ${englishMonth[now.getMonth()]}`;
        }
        setFormatedPeriod(periodString);
        break;
      }
      case "1": {
        const startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1),
        );
        const endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1),
        );
        const previousPeriod = getPreviousPeriod(now, 2);

        if (i18n.language === "fr") {
          periodString = `Du 1er ${frenchMonth[startOfLastMonth.getMonth()]} au ${endOfLastMonth.getDate()} ${frenchMonth[endOfLastMonth.getMonth()]}`;
        } else {
          periodString = `1st ${englishMonth[startOfLastMonth.getMonth()]} to ${endOfLastMonth.getDate()} ${englishMonth[endOfLastMonth.getMonth()]}`;
        }
        setFormatedPeriod(periodString);

        setPeriodHistoryStart(startOfLastMonth.toISOString());
        setPeriodHistoryStop(endOfLastMonth.toISOString());
        setPeriodHistoryStartPrevious(previousPeriod.start.toISOString());
        setPeriodHistoryStopPrevious(previousPeriod.end.toISOString());
        break;
      }
      case "-3mo":
        setPeriodHistoryStart(getMonthsAgo(now, 3).toISOString());
        setPeriodHistoryStop(now.toISOString());
        setPeriodHistoryStartPrevious(getMonthsAgo(now, 6).toISOString());
        setPeriodHistoryStopPrevious(getMonthsAgo(now, 4, true).toISOString());
        periodString = getMonthsInRange(
          getMonthsAgo(now, 3),
          now,
          i18n.language,
        );
        setFormatedPeriod(periodString);
        break;

      case "-6mo":
        setPeriodHistoryStart(getMonthsAgo(now, 6).toISOString());
        setPeriodHistoryStop(now.toISOString());
        setPeriodHistoryStartPrevious(getMonthsAgo(now, 12).toISOString());
        setPeriodHistoryStopPrevious(getMonthsAgo(now, 7, true).toISOString());
        periodString = getMonthsInRange(
          getMonthsAgo(now, 6),
          now,
          i18n.language,
        );
        setFormatedPeriod(periodString);
        break;

      case "-12mo":
        setPeriodHistoryStart(getMonthsAgo(now, 12).toISOString());
        setPeriodHistoryStop(now.toISOString());
        setPeriodHistoryStartPrevious(getMonthsAgo(now, 24).toISOString());
        setPeriodHistoryStopPrevious(getMonthsAgo(now, 13, true).toISOString());
        periodString = getMonthsInRange(
          getMonthsAgo(now, 12),
          now,
          i18n.language,
        );
        setFormatedPeriod(periodString);
        break;

      default:
        break;
    }
  };

  const onChangeCalendar = (event) => {
    let periodString = null;
    const delta = deltaMilliseconds(event[0], event[1]);
    const startMilliseconds = new Date(event[0])?.getTime();
    const startPrevious = startMilliseconds - delta;

    setPeriodHistoryStop(new Date(event[1]).toISOString());
    setPeriodHistoryStart(new Date(event[0]).toISOString());

    setPeriodHistoryStopPrevious(new Date(event[0]).toISOString());
    setPeriodHistoryStartPrevious(new Date(startPrevious).toISOString());
    periodString = `Du ${formattedDate(new Date(event[0]), i18n.language)} au ${formattedDate(new Date(event[1]), i18n.language)}`;
    setFormatedPeriod(periodString);
    setDisplayCalendar(false);
  };

  useEffect(() => {
    axios
      .all([
        countContainers({ keywordFilter: JSON.stringify(keywordFilter.value) }),
        addressCountSiteUsed({
          periodStart: periodHistoryStart,
          periodStop: periodHistoryStop,
          keywordFilter: JSON.stringify(keywordFilter.value),
        }),
      ])
      .then(
        axios.spread((responseContainerCount, responseCountSite) => {
          if (
            responseContainerCount.status === 200 &&
            responseCountSite.status === 200
          ) {
            setNumberOfContainers(responseContainerCount.data.count);
            setNumberOfSite(responseCountSite.data.countSite);
          }
        }),
      );
  }, [keywordFilter, periodHistoryStart, periodHistoryStop]);

  if (token) {
    return (
      <div className="base">
        <h1 className="">
          {t("My")} {t("Summaries")} & {t("Metrics")}
        </h1>

        <nav
          className="flex mx-1 justify-between"
          style={{ marginTop: "30px", marginBottom: "25px" }}
        >
          <div className="w-4/5">
            <span
              onClick={() => {
                setShowCollection(true);
                setShowQuality(false);
                setShowCost(false);
                setShowCarbon(false);
              }}
              className={`text-cyan text-base cursor-pointer px-5 py-2 ${showCollection ? "bg-inherit border-b-4 border-cyan" : "bg-gainsboro rounded-md"}`}
            >
              {t("Rotations")}
            </span>
            <span
              onClick={() => {
                setShowCollection(false);
                setShowQuality(true);
                setShowCost(false);
                setShowCarbon(false);
              }}
              className={`text-cyan text-base cursor-pointer px-5 py-2 ml-2 ${showQuality ? "bg-inherit border-b-4 border-cyan" : "bg-gainsboro rounded-md"}`}
            >
              {t("sorting_errors")}
            </span>
            <span
              onClick={() => {
                setShowCollection(false);
                setShowQuality(false);
                setShowCost(true);
                setShowCarbon(false);
              }}
              className={`text-cyan text-base cursor-pointer px-5 py-2 ml-2 ${showCost ? "bg-inherit border-b-4 border-cyan" : "bg-gainsboro rounded-md"}`}
            >
              {t("Cost")}
            </span>
            <span
              onClick={() => {
                setShowCollection(false);
                setShowQuality(false);
                setShowCost(false);
                setShowCarbon(true);
              }}
              className={`text-cyan text-base cursor-pointer px-5 py-2 ml-2 ${showCarbon ? "bg-inherit border-b-4 border-cyan" : "bg-gainsboro rounded-md"}`}
            >
              {t("Carbon Footprint")}
            </span>
          </div>
        </nav>

        <div className="w-full grid justify-items-end mb-2">
          <div className="w-3/4 flex justify-end">
            {/* Calendar */}
            {showDate ? (
              <CalendarComponent
                classNameBase="relative w-2/5 flex"
                onChangeCalendar={onChangeCalendar}
                displayCalendar={displayCalendar}
                setDisplayCalendar={setDisplayCalendar}
                periodHistoryStart={periodHistoryStart}
                periodHistoryStop={periodHistoryStop}
              />
            ) : null}

            {/* Multiselect */}
            <div className="relative w-1/4">
              <Multiselect
                options={labeledkeywordFilterOptions}
                groupBy="cat"
                displayValue="key"
                selectedValues={keywordFilter.value}
                onSelect={(value) => {
                  setKeywordFilter({ value });
                }}
                onRemove={(value) => {
                  setKeywordFilter({ value });
                }}
                hidePlaceholder={true}
                emptyRecordMsg="Aucune option disponible"
                placeholder={t("Search")}
                closeIcon="circle"
                ref={multiselectKeywordRef}
              />
              {keywordFilter.value && keywordFilter.value.length > 0 ? (
                <i
                  className="fas fa-trash absolute top-3 right-2"
                  onClick={() => {
                    multiselectKeywordRef.current.resetSelectedValues();
                    setKeywordFilter([]);
                  }}
                ></i>
              ) : null}
            </div>

            <div className="flex w-1/4 ml-2">
              <Input
                type="select"
                style={{ borderWidth: "0px" }}
                name="select-graphe"
                onChange={(event) => selectPeriodFilter(event.target.value)}
              >
                <option value="0">
                  Mois en cours : {capitalizeFirstLetter(currentMonthName())}
                </option>
                <option value="1">
                  Mois dernier : {capitalizeFirstLetter(previousMonthName())}
                </option>
                <option value="-3mo">3 derniers mois</option>
                <option value="-6mo">6 derniers mois</option>
                <option value="-12mo">12 derniers mois</option>
                <option value="calendar">Période personnalisée</option>
              </Input>
            </div>
          </div>
        </div>

        <div className="flex">
          {showCollection ? (
            <StatisticsCollection
              API={API}
              token={token}
              periodHistoryStart={periodHistoryStart}
              periodHistoryStop={periodHistoryStop}
              periodHistoryStartPrevious={periodHistoryStartPrevious}
              periodHistoryStopPrevious={periodHistoryStopPrevious}
              keywordFilter={keywordFilter}
              formatedPeriod={formatedPeriod}
              numberOfContainers={numberOfContainers}
              numberOfSite={numberOfSite}
            />
          ) : null}

          {showQuality ? (
            <StatisticsQuality
              API={API}
              token={token}
              periodHistoryStart={periodHistoryStart}
              periodHistoryStop={periodHistoryStop}
              periodHistoryStartPrevious={periodHistoryStartPrevious}
              periodHistoryStopPrevious={periodHistoryStopPrevious}
              keywordFilter={keywordFilter}
              formatedPeriod={formatedPeriod}
              numberOfContainers={numberOfContainers}
              numberOfSite={numberOfSite}
            />
          ) : null}
          {showCarbon ? (
            <StatisticsCO2
              API={API}
              token={token}
              periodHistoryStart={periodHistoryStart}
              periodHistoryStop={periodHistoryStop}
              periodHistoryStartPrevious={periodHistoryStartPrevious}
              periodHistoryStopPrevious={periodHistoryStopPrevious}
              keywordFilter={keywordFilter}
              formatedPeriod={formatedPeriod}
              numberOfContainers={numberOfContainers}
              numberOfSite={numberOfSite}
            />
          ) : null}
          {!showQuality && !showCollection && !showCarbon && (
            <div className="w-full text-center mt-28">
              <h1 className="font-bold">Coming Soon!</h1>
              <h5>
                {t("Don't hesitate to send us your suggestions/needs to")}{" "}
                <span className="text-[#0F265C]">tech@akanthas.com</span>
              </h5>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
};

export default StatisticsNew;
