import { useEffect } from "react";
import * as echarts from "echarts";

export const MaterialCountingChartForPDF = ({
  dataMaterialChart,
  labelChart,
  materialTotal,
  colorChart,
  materialRef,
  fontSizeLabel,
}) => {
  useEffect(() => {
    const chartDom = document.getElementById("material-chart");
    const materialChart = echarts.init(chartDom);
    const data = dataMaterialChart.map((value, index) => ({
      value: ((value * 100) / materialTotal).toFixed(1),
      name: labelChart[index],
      itemStyle: {
        color: colorChart[index],
      },
    }));
    let option;
    option = {
      tooltip: {
        trigger: "item",
      },
      graphic: [
        {
          type: "group",
          left: "center",
          top: "middle",
          children: [
            {
              type: "text",
              style: {
                text: materialTotal,
                fontSize: "6rem",
                fill: "#E95280",
                font: 'bolder 46px "Mulish", sans-serif',
                fontWeight: "bold",
                textAlign: "center",
              },
            },
            {
              type: "text",
              style: {
                text: "Objets détectés",
                fontSize: "1.3rem",
                fill: "#E95280",
                fontWeight: "bold",
                textAlign: "center",
                y: 100,
              },
            },
          ],
        },
      ],

      series: [
        {
          type: "pie",
          radius: ["20%", "48%"],
          label: {
            formatter: "{b}({c}%)",
            position: "outside",
            fontSize: fontSizeLabel,
            fontWeight: 500,
          },
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
    option && materialChart.setOption(option);
  });

  return (
    <div
      id="material-chart"
      ref={materialRef}
      style={{
        width: "80vw",
        height: "80vw",
        padding: 0,
      }}
    />
  );
};
