import { FormGroup, Input, Label } from "reactstrap";
import { handleFormChange } from "../../../../components/modals/EditableTable";

export const WasteCosts = ({ formData, setFormData, unitList }) => {
  return (
    <section className="flex flex-col">
      {/* Traitement Cost */}
      <FormGroup className="flex flex-col w-2/3 mb-5">
        <Label>Coût de traitement(€)</Label>
        <Input
          type="number"
          required="required"
          placeholder="Tapez le montant en euros : 180"
          value={formData.treatmentCostFull}
          onChange={(event) => {
            handleFormChange(event, formData, setFormData);
          }}
          name="treatmentCostFull"
        />
      </FormGroup>

      {/* Unity*/}
      <FormGroup className="flex flex-col w-2/3 mb-5">
        <Label>Unité</Label>
        <select
          className="border border-grey-input rounded bg-grey-200"
          type="select"
          value={formData.treatmentCostUnit}
          onChange={(event) => handleFormChange(event, formData, setFormData)}
          name="treatmentCostUnit"
        >
          {unitList.map((unit) => {
            return <option value={unit.value}>{unit.label}</option>;
          })}
        </select>
      </FormGroup>
    </section>
  );
};
