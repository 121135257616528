import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function SkeletonLoader() {
  return (
    <Stack spacing={2}>
      {Array.from({ length: 6 }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          animation="wave"
          height={30}
          sx={{ bgcolor: "grey.100" }}
        />
      ))}
    </Stack>
  );
}
