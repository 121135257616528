export const masseTabCsvHeaders = [
  "TableauResume",
  "% valorisable masse",
  "PAPIERS/CARTONS_%",
  "METAUX_%",
  "PLASTIQUES_%",
  "VERRE_%",
  "BOIS_%",
  "FRACTION MINERALE_%",
  "PLATRE_%",
  "BIODECHETS_%",
  "TEXTILE_%",
  "AUTRES_%",
  "MasseEstimee_kg",
  "PAPIERS/CARTONS_kg",
  "METAUX_kg",
  "PLASTIQUES_kg",
  "VERRE_kg",
  "BOIS_kg",
  "FRACTION MINERALE_kg",
  "PLATRE_kg",
  "BIODECHETS_kg",
  "TEXTILE_kg",
  "AUTRES_kg",
];

export const massMaterialCsvHeaders = ["Masse", "% valorisable masse"];

export const volumeCsvHeaders = ["Volume", "% valorisable vol"];

export const numberOfMaterialHeaders = ["Nombre_Objets", "Total"];
