import React from "react";
import { CropImage } from "../../../components/utils/ImageTreatment";
import CloseIcon from "@mui/icons-material/Close";
import useWindowDimensions from "../../../components/formatted/UseWindowsDimensions";

const ContainerImageZoom = ({
  setIsImageZoomOverlayActive,
  setContainerActive,
  containerActive,
}) => {
  const { widthWindow, heightWindow } = useWindowDimensions();
  const imageWidth = widthWindow / 2.5;
  const imageHeight = heightWindow / 1.3;

  return (
    <div className="w-full">
      <div
        className="w-6 h-6 bg-gray-400 ml-auto flex items-center justify-center rounded-full"
        style={{
          marginRight: "12px",
          marginTop: "14px",
        }}
        onClick={() => {
          setIsImageZoomOverlayActive(false);
          setContainerActive({});
        }}
      >
        <CloseIcon
          style={{
            width: 22,
            cursor: "pointer",
            color: "#FFF",
          }}
        />
      </div>
      <div className="p-3">
        <CropImage
          container={containerActive}
          data={containerActive}
          imgDivWidth={imageWidth}
          imgDivHeight={imageHeight}
          isTooltip={true}
          isMap={false}
          isCharacterization={false}
          displayLegend={false}
          materialsDropdownToDisplay={[]}
          showCheckbox={false}
        />
      </div>
    </div>
  );
};

export default ContainerImageZoom;
