import React from "react";
import { toFrenchNumber } from "../../../components/formatted/FormattedText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWeightHanging } from "@fortawesome/free-solid-svg-icons";

export const StarRatingIcon = ({
  numberOfStars,
  text,
  alignTextAboveStars,
  color,
  textSize = 15,
  iconSize = 22,
}) => {
  return (
    <>
      {alignTextAboveStars ? (
        <>
          <div>
            <span style={{ color: color, fontSize: textSize }}>{text}</span>
            <div className="icon-line">
              <i
                className={numberOfStars > 0 ? "fas fa-star" : "far fa-star"}
                style={{ color: color, fontSize: iconSize }}
              />
              <i
                className={numberOfStars > 1 ? "fas fa-star" : "far fa-star"}
                style={{ color: color, fontSize: iconSize }}
              />
              <i
                className={numberOfStars > 2 ? "fas fa-star" : "far fa-star"}
                style={{ color: color, fontSize: iconSize }}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="icon-line">
          <i
            className={numberOfStars > 0 ? "fas fa-star" : "far fa-star"}
            style={{ color: color, fontSize: iconSize }}
          />
          <i
            className={numberOfStars > 1 ? "fas fa-star" : "far fa-star"}
            style={{ color: color, fontSize: iconSize }}
          />
          <i
            className={numberOfStars > 2 ? "fas fa-star" : "far fa-star"}
            style={{ color: color, fontSize: iconSize }}
          />
          <span style={{ color: color, marginLeft: 6, fontSize: textSize }}>
            {text}
          </span>
        </div>
      )}
    </>
  );
};

export const LevelBarIcon = ({ fillingLevel }) => {
  return (
    <div className="progress-bar" style={{ backgroundColor: "transparent" }}>
      <div
        className="progress-filling"
        style={{ height: `${fillingLevel}%` }}
      ></div>
    </div>
  );
};

export const MassIcon = ({ currentValue, targetValue, weightUnit }) => {
  return (
    <div className="mass-icon" style={{ marginLeft: "25px" }}>
      <FontAwesomeIcon icon={faWeightHanging} className="mass" />
      <div className="values">
        <span className="text">
          {!isNaN(currentValue) &&
            toFrenchNumber(
              weightUnit === "t" ? currentValue / 1000 : currentValue,
              1,
            )}{" "}
          {weightUnit} {targetValue > 0 && " / " + targetValue}
        </span>
      </div>
    </div>
  );
};
