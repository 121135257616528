import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const API = process.env.REACT_APP_API_BACKEND;
const token = Cookies.get("userToken");

export function handleDeselectAll(data, setData, setContainersSelected) {
  setData(
    data.map((elt) => {
      return {
        ...elt,
        checked: false,
      };
    }),
  );

  setContainersSelected([]);
}

export function handleSelectAll(event, data, setData, setContainersSelected) {
  if (event.target.checked) {
    let dataSelecteds = [];
    for (let i = 0; i < data.length; i++) {
      data[i].checked = true;
      dataSelecteds.push({
        _id: data[i]._id,
        _time: data[i]._time,
      });
    }
    setData(data);
    setContainersSelected(dataSelecteds);

    return;
  }
  handleDeselectAll(data, setData, setContainersSelected);
}

export function handleSelectOne(
  event,
  data,
  setContainersSelected,
  containersSelected,
) {
  const dataSelected = {
    _id: data._id,
    _time: data._time,
  };
  if (event.type === "click") {
    setContainersSelected([dataSelected]);
  } else {
    if (event.target.checked) {
      data.checked = true;
      setContainersSelected([...containersSelected, dataSelected]);
    } else {
      let array = [...containersSelected];
      const index = array.findIndex((container) => container._id === data._id);
      if (index !== -1) {
        array.splice(index, 1);
        setContainersSelected(array);
      }
      data.checked = false;
    }
  }
}

export function checkIfAllSelected(data, containersSelected) {
  return data.length !== 0 && containersSelected.length === data.length;
}

export function checkPlanningIsActive(data, containersSelected) {
  let planningIsActive = true;
  containersSelected.forEach((containerSelected) => {
    data.forEach((container) => {
      if (
        container._id === containerSelected._id &&
        (container.status === "remove_driver_exchange" ||
          !container.collectorEmail)
      ) {
        planningIsActive = false;
      }
    });
  });
  return planningIsActive;
}

export function checkContainerContractExist(data, containersSelected) {
  let containerContractExist = true;
  containersSelected.forEach((containerSelected) => {
    data.forEach((container) => {
      if (
        container._id === containerSelected._id &&
        !container.collectorEmail
      ) {
        containerContractExist = false;
      }
    });
  });
  return containerContractExist;
}

export async function sendPlanningMail(containersList, typeMail) {
  try {
    const response = await axios.post(
      `${API}planning`,
      {
        containersList,
        typeMail,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status === 200) {
      toast.success("Vos demandes d'échanges ont bien été envoyées", {
        className: "custom-toast",
      });
    }
  } catch (error) {
    return error.response;
  }
}
