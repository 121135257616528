import React, { useEffect, useState, useCallback, useRef } from "react";
import { Modal, Input, Button, Spinner } from "reactstrap";
import { ModalTitle } from "react-bootstrap";
import {
  currentMonthName,
  previousMonthName,
  capitalizeFirstLetter,
} from "../../../../components/utils/Date";
import {
  formattedColorMaterial,
  formattedMaterial,
  toFrenchNumber,
} from "../../../../components/formatted/FormattedText";
import moment from "moment";
import CalendarComponent from "../../../../components/CalendarComponent";
import { findObjectInArrayByKeyValue } from "../../../../components/utils/Object";
import Cookies from "js-cookie";
import {
  masseTabCsvHeaders,
  massMaterialCsvHeaders,
  numberOfMaterialHeaders,
  volumeCsvHeaders,
} from "../../../../shared/export/csv";
import { formatFilename, removeAccents } from "../../../../shared/export/utils";
import WasteQualityPDF from "../../../../components/download/wasteQuality/WasteQualityPDF";
import { pdf } from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import reactImageSize from "react-image-size";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dropdown } from "../../../../components/dropdownMenu/Dropdown";
import ContainerViewMultiSelect from "./ContainerViewMultiSelect";
import { LoadingScreen } from "../../../../components/loader/LoadingScreen";
import {
  azureSASTokenToUrl,
  formatAzureBlob,
} from "../../../../components/formatted/FormattedAzureSasToken";
import SingleContainerViewMaterial from "./SingleContainerViewMaterial";
import SuperContainerViewMaterial from "./SuperContainerViewMaterial";
import {
  getAzureBlob,
  getListRotationPeriods,
  getTemporalData,
  saveContainerWeight,
  getContainerWeight,
} from "../../../../services/container";
import { getCorporationData } from "../../../../services/corporation";

import CloseIcon from "@mui/icons-material/Close";
import { getWastecategoryList } from "../../../../services/waste";
import { getCameraId } from "../../../../shared/utils.js/utils";
import ContainerEdit from "../ContainerEdit";
import { handleOpenModalClick } from "../../../../components/modals/EditableTable";
import ContainerViewDetails from "./ContainerViewDetails";
import { useTranslation } from "react-i18next";
import ContainerModalClient from "./ContainerModalClient";

function mergeSimilarMaterials(initialArray) {
  let obj = {};

  initialArray.forEach((i) => {
    let id = i.parentCategory;

    if (!obj[id]) {
      obj[id] = { ...i };
    } else {
      obj[id].massObjects += i.massObjects;
      obj[id].massInOutlet += i.massInOutlet;
      obj[id].massNotInOutlet += i.massNotInOutlet;

      if (id === "Autres") {
        obj[id].categoryList = Array.from(
          new Set([...obj[id].categoryList, ...i.categoryList]),
        );
      }
    }
  });

  let table = Object.values(obj);
  return table;
}

function formatOutletData(initialData, language) {
  if (initialData) {
    const labels = Object.keys(initialData).map((key) => {
      switch (key) {
        case "energyRecovery":
          return language === "en"
            ? "Energy recovery"
            : "Valorisation\nénergétique";

        case "materialRecovery":
          return language === "en"
            ? "Material recovery"
            : "Valorisation\nmatière";

        case "landfill":
          return language === "en" ? "Landfill" : "Stockage";

        case "undefined":
          return language === "en" ? "Undefined" : "Non défini";

        default:
          return "";
      }
    });

    const data = Object.values(initialData);
    const sum = data.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );

    return { labels, data, sum };
  } else {
    return {};
  }
}

function mergeMaterialFluxes(initialArray, mergeConfig) {
  const newArray = [];

  // Merge the specified objects
  mergeConfig.forEach((merge) => {
    const { referenceName, possibleNames } = merge;

    const newObj = {
      cls: referenceName,
      surface: 0,
      count: 0,
      volumeObject: 0,
      massObject: 0,
      recoverable: false,
    };

    possibleNames.forEach((name) => {
      const matchingObj = findObjectInArrayByKeyValue(
        initialArray,
        "cls",
        name,
      );
      if (matchingObj) {
        newObj.surface += matchingObj.surface;
        newObj.count += matchingObj.count;
        newObj.volumeObject += matchingObj.volumeObject;
        newObj.massObject += matchingObj.massObject;
        newObj.recoverable = matchingObj.recoverable;
      }
    });

    if (newObj.surface > 0) {
      newArray.push(newObj);
    }
  });
  return newArray;
}

const ContainerViewMaterial = ({
  data,
  expendImage,
  setExpendImage,
  superContainer,
  containers,
  setSelectedContainer,
  fetchContainer,
}) => {
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");
  const { t, i18n } = useTranslation();
  const materialList = JSON.parse(
    sessionStorage.getItem("materialsDescription"),
  );
  const [characterizationInProgress, setCharacterizationInProgress] =
    useState(false);
  const [customDate, setCustomDate] = useState(false);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [displayMassChart, setDisplayMassChart] = useState(true);
  const [displayVolumeChart, setDisplayVolumeChart] = useState(false);
  const [displayOutletChart, setDisplayOutletChart] = useState(false);
  const [displayNumberOfObjectChart, setDisplayNumberOfObjectChart] =
    useState(false);
  const [helpVolumeRecoverable, setHelpVolumeRecoverable] = useState(false);
  const [helpMassRecoverable, setHelpMassRecoverable] = useState(false);
  const [historyId, setHistoryId] = useState(0);
  const [historyMaterial, setHistoryMaterial] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [materialDelivery, setMaterialDelivery] = useState([]);
  const [materialTotal, setMaterialTotal] = useState(0);
  const [multiPeriods, setMultiPeriods] = useState({});
  const [rotationPeriods, setRotationPeriods] = useState([]);
  const [selectedNumberOfCycleMaterial, setSelectedNumberOfCycleMaterial] =
    useState(0);
  const [totalMassRecoverable, setTotalMassRecoverable] = useState(0);
  const [totalVolumeRecoverable, setTotalVolumeRecoverable] = useState(0);
  const [totalWasteMass, setTotalWasteMass] = useState(0);
  const [totalWasteVolume, setTotalWasteVolume] = useState(0);
  const [toogleMassChart, setToogleMassChart] = useState(false);
  const [toogleOutletChart, setToogleOutletChart] = useState(false);
  const [parentCategoriesList, setParentCategoriesList] = useState([]);
  const [periodMaterialStart, setPeriodMaterialStart] = useState("-6mo");
  const [periodMaterialStop, setPeriodMaterialStop] = useState("");
  const [periodMaterialStartSubfilter, setPeriodMaterialStartSubfilter] =
    useState(periodMaterialStart);
  const [periodMaterialStopSubfilter, setPeriodMaterialStopSubfilter] =
    useState(periodMaterialStop);
  const [disableDropdown, setDisableDropdown] = useState(true);
  const [massTabCsvData, setMassTabCsvData] = useState([]);
  const [materialCsvData, setMaterialCsvData] = useState([]);
  const [materialCsvHeaders, setMaterialCsvHeaders] = useState([]);
  const [volumeHeaders, setVolumeCsvHeaders] = useState([]);
  const [volumeCsvData, setVolumeCsvData] = useState([]);
  const [numberOfMaterialCsvData, setNumberOfMaterialCsvData] = useState([]);
  const [numberOfMaterialCsvHeaders, setNumberOfMaterialCsvHeaders] = useState(
    [],
  );
  const [filename, setFilename] = useState("");
  const [multipleCsvData, setMultipleCsvData] = useState([]);
  const [allMaterial, setAllMaterial] = useState([]);
  const [displayWeight, setDisplayWeight] = useState(false);
  const [displayClient, setDisplayClient] = useState(false);
  const [weight, setWeight] = useState("");
  const [totalWeight, setTotalWeight] = useState("");
  const [missingWeight, setMissingWeight] = useState(false);
  const [newClient, setNewClient] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [caracterisationData, setCaracterisationData] = useState([]);
  const [characterizationWeights, setCharacterizationWeights] = useState([]);
  const [loadWeight, setLoadWeight] = useState(true);
  const [wasteCategory, setWasteCategory] = useState([]);
  const [wasteCategoryName, setWasteCategoryName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [formData, setFormData] = useState({});
  const [outletData, setOutletData] = useState({});
  const [characterizationOutlet, setCharacterizationOutlet] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [toogleCharacterization, setToogleCharacterization] = useState(false);
  const [showData, setShowData] = useState(false);
  const [rotationId, setRotationId] = useState(0);
  const [characterizationName, setCharacterizationName] = useState(null);
  const [selectedList, setSelectedList] = useState([]);
  const [loadRotationPeriods, setLoadRotationPeriods] = useState(false);

  const chartRef = useRef();
  const tableRef = useRef();
  const materialRef = useRef();
  const volumeRef = useRef();
  const outletRef = useRef();
  const tableOutletRef = useRef();
  const weightRef = useRef(null);
  const filterRef = useRef(null);

  const toggleDisplayWeight = () => {
    setDisplayWeight(!displayWeight);
    setNewClient(false);
  };

  const validSelectedPeriods = () => {
    if (
      multiPeriods?.selectedList &&
      multiPeriods?.selectedList.length > 0 &&
      multiPeriods?.selectedList.some(
        (value) => value !== undefined && value !== null && value !== "",
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const groupDataByPeriods = (history, periods) => {
    if (periods) {
      return periods.map((period) => {
        const dataForPeriod = history.filter((item) => {
          const itemTime = new Date(item._time).toISOString();
          const periodStart = new Date(period.start).toISOString();
          const periodStop = new Date(period.stop).toISOString();

          return itemTime >= periodStart && itemTime <= periodStop;
        });

        return dataForPeriod;
      });
    }
  };

  useEffect(() => {
    if (rotationPeriods.length > 0) {
      let newValue = [rotationPeriods[rotationId]];
      setHistoryId(0);
      setMultiPeriods({ selectedList: newValue });
      if (
        newValue &&
        newValue.length > 0 &&
        newValue.some(
          (value) => value !== undefined && value !== null && value !== "",
        )
      ) {
        setSelectedNumberOfCycleMaterial(newValue.length);
        setCharacterizationName(newValue[0].name);
      } else {
        setSelectedNumberOfCycleMaterial(0);
        setCharacterizationName(null);
      }
      setToogleCharacterization(true);
    }
  }, [rotationId, rotationPeriods]);

  const handleSelectionChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);
    setClientName(selectedValue?.name);
    setClientCode(selectedValue?.code);
    setClientAddress(selectedValue?.address);
  };

  const saveWeight = async (id) => {
    const cameraId = getCameraId(data.image_url);
    const blobName = `${
      "camera-ak_" + cameraId
    }/output-client/${historyMaterial[0]._time
      .replace(/:/g, "_")
      .slice(0, -1)}-${"camera-ak_" + cameraId}-${id}-client.json`;
    if (multiPeriods) {
      await saveContainerWeight({
        id: id,
        weight: parseFloat(String(weight).replace(",", ".")) * 1000,
        time: historyMaterial[0]._time,
        modified_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        client: `{"name": "${clientName}", "code": "${clientCode}", "address": "${clientAddress}"}`,
        waste_category: wasteCategoryName,
        blobName: blobName,
      }).then((response) => {
        setDisplayWeight(false);
        setDisplayClient(false);
        setTotalWeight(weight);
        fetchWeight(id);
        toast.success(
          `Le ${displayClient ? "client" : "poids"} a bien été sauvegardé`,
          { className: "custom-toast" },
        );
      });
    }
  };

  const fetchWeight = async (id) => {
    setWeight("");
    setClientName("");
    setClientCode("");
    setClientAddress("");
    setTotalWeight("");
    setWasteCategoryName("");
    setMissingWeight(false);
    const historySelectedWaste = groupDataByPeriods(
      historyMaterial,
      multiPeriods.selectedList,
    );
    if (historySelectedWaste && historySelectedWaste.length > 0) {
      const promises = historySelectedWaste.map(async (history) => {
        if (history.length > 0) {
          const time = new Date(history[0]._time);

          return getContainerWeight(
            {
              start: new Date(time.getTime() - 1).toISOString(),
              stop: new Date(time.getTime() + 1).toISOString(),
            },
            id,
          ).then((response) => {
            if (Object.keys(response?.data).length > 0) {
              if (historySelectedWaste.length === 1) {
                setClientName(
                  response?.data?.client
                    ? JSON.parse(response?.data?.client).name
                    : "",
                );
                setClientCode(
                  response?.data?.client
                    ? JSON.parse(response?.data?.client).code
                    : "",
                );
                setClientAddress(
                  response?.data?.client
                    ? JSON.parse(response?.data?.client).address
                    : "",
                );
                setWasteCategoryName(
                  response?.data?.waste_category
                    ? response?.data?.waste_category
                    : "",
                );
              }
              return response?.data?.weight / 1000;
            }
            return null;
          });
        } else {
          return undefined;
        }
      });

      await Promise.all(promises)
        .then((weights) => {
          setCharacterizationWeights(weights);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setMissingWeight(true);
          }
        });
    }
    setShowData(true);
  };

  useEffect(() => {
    setMissingWeight(false);
    let wasteObjectsWeight;
    if (
      characterizationWeights.length > 1 &&
      (characterizationWeights.includes(null) ||
        characterizationWeights.includes(undefined))
    ) {
      setMissingWeight(true);
    }
    if (!characterizationWeights.includes(undefined)) {
      if (!characterizationWeights.includes(null)) {
        wasteObjectsWeight = characterizationWeights.reduce(
          (sum, weight) => sum + weight,
          0,
        );
        if (wasteObjectsWeight >= 0) {
          setWeight(wasteObjectsWeight);
          setTotalWeight(wasteObjectsWeight);
        }
      } else {
        setWeight("");
        setTotalWeight("");
      }
      setLoadWeight(false);
    }
  }, [characterizationWeights]);

  const displayDates = (idx, start, stop, clientName, clientAddress) => {
    let text =
      `${i18n.language === "fr" ? "Carac" : "Charac"}. ` +
      (idx + 1) +
      " : " +
      moment(start).format("DD/MM/YY, HH:mm");
    if (start !== stop) {
      text += " au " + moment(stop).format("DD/MM/YYYY, HH:mm");
    }
    text +=
      " (" +
      (clientName !== "" ? clientName : "-") +
      (clientAddress && !["", " ", "-", "undefined"].includes(clientAddress)
        ? ", " + clientAddress
        : "") +
      ")";
    return text;
  };

  const fetchRotationTimeline = useCallback(
    async (id) => {
      setLoadRotationPeriods(true);
      await getListRotationPeriods(
        {
          periodMaterialStart: periodMaterialStartSubfilter,
          periodMaterialStop: periodMaterialStopSubfilter,
          superContainerId:
            superContainer && superContainer._id !== undefined
              ? superContainer._id
              : null,
          isCharacterization:
            data?.characterization ||
            (superContainer && superContainer._id !== undefined),
        },
        id,
      ).then((responseRotation) => {
        if (responseRotation.status === 200) {
          const period = responseRotation.data.periods
            .map((v) => ({ ...v, periodMaterialStartSubfilter: v.start }))
            .map((v) => ({ ...v, periodMaterialStopSubfilter: v.stop }))
            .map((v, idx) => ({
              ...v,
              name: displayDates(
                idx,
                v.start,
                v.stop,
                v.client !== "" ? JSON.parse(v.client).name : "-",
                v.client !== "" ? JSON.parse(v.client).address : "-",
              ),
            }));
          const rotations =
            rotationPeriods.length > 0
              ? [...rotationPeriods, ...period]
              : period;
          const filteredRotations = rotations
            .filter(
              (item, index, self) =>
                self.findIndex((t) => t.name === item.name) === index,
            )
            .filter((currentItem, index, array) => {
              const hasDuplicate = array.some((item, i) => {
                return i !== index && item.start === currentItem.start;
              });
              if (hasDuplicate) {
                const hasContentInParentheses =
                  /\((?:[^()-]|\([^()]*\))*\)/.test(currentItem.name);
                return hasContentInParentheses;
              }
              return true;
            })
            .slice();
          setRotationPeriods(filteredRotations);
        }
      });
      setLoadRotationPeriods(false);
    },
    // eslint-disable-next-line
    [API, token, data, periodMaterialStart, periodMaterialStop],
  );

  const handleCsvExport = () => {
    multipleCsvData.forEach(({ data, filename }) => {
      const csvContent = data.map((row) => row.join(";")).join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  };

  const prepareMassTabDataCSV = () => {
    let exportReady = false;
    if (validSelectedPeriods() && multiPeriods?.selectedList.length === 1) {
      // Calculate values
      const selectedPeriod = multiPeriods?.selectedList[0];
      const newFilename = formatFilename(selectedPeriod.start, data._id);
      const newMassTabPercent = parentCategoriesList.map((parentCategory) => {
        if (parentCategory.massObjects !== 0) {
          return `${((parentCategory.massObjects / totalWasteMass) * 100).toFixed(4)}%`;
        } else {
          return `0.0%`;
        }
      });
      const newMassTabKg = parentCategoriesList.map((parentCategory) => {
        if (parentCategory.massObjects !== 0) {
          return (
            ((parentCategory.massObjects / totalWasteMass) *
              100 *
              (totalWeight * 1000)) /
            100
          ).toFixed(4);
        } else {
          return 0.0;
        }
      });

      const newTotalMassRecoverablePercent = (
        (totalMassRecoverable / totalWasteMass) *
        100
      ).toFixed(2);
      const newTotalWasteMassKg = (totalWeight * 1000).toFixed(2);

      // Create the data array
      const newData = [
        `${newFilename}_TableauRecap`,
        `${newTotalMassRecoverablePercent}%`,
        ...newMassTabPercent,
        newTotalWasteMassKg,
        ...newMassTabKg,
      ];

      // Update states
      setFilename(newFilename);
      setMassTabCsvData(newData);
      exportReady = true;
    }

    // Set disableDropdown based on exportReady
    setDisableDropdown(!exportReady);
  };

  const prepareMassMaterialDataCSV = () => {
    let exportReady = false;
    if (validSelectedPeriods() && multiPeriods?.selectedList.length === 1) {
      if (totalWasteMass > 0) {
        const newDataMaterial = materialList.map((materialName) => {
          const matchingMaterial = materialDelivery.find(
            (material) => materialName.referenceName === material.cls,
          );

          if (matchingMaterial) {
            return `${((matchingMaterial.massObject / totalWasteMass) * 100).toFixed(4)}%`;
          } else {
            return `0.0%`;
          }
        });

        const newTotalMassRecoverablePercent = (
          (totalMassRecoverable / totalWasteMass) *
          100
        ).toFixed(2);

        // Update states with the new values
        const newHeader = [...massMaterialCsvHeaders, ...allMaterial];
        setMaterialCsvHeaders(newHeader);

        // Create the CSV data array
        const newData = [
          `${filename}_masse`,
          `${newTotalMassRecoverablePercent}%`,
          ...newDataMaterial,
        ];

        // Update the CSV data state
        setMaterialCsvData(newData);
      }
      exportReady = true;
    }

    setDisableDropdown(!exportReady);
  };

  const prepareVolumeDataCSV = () => {
    let exportReady = false;
    if (validSelectedPeriods() && multiPeriods?.selectedList.length === 1) {
      if (totalWasteVolume > 0) {
        const newDataVolume = materialList.map((materialName) => {
          const matchingMaterial = materialDelivery.find(
            (material) => materialName.referenceName === material.cls,
          );

          if (matchingMaterial) {
            return `${((matchingMaterial.volumeObject / totalWasteVolume) * 100).toFixed(4)}%`;
          } else {
            return `0.0%`;
          }
        });

        const newTotalVolumeRecoverable = (
          (totalVolumeRecoverable / totalWasteVolume) *
          100
        ).toFixed(2);

        // Update states with the new values
        const newHeader = [...volumeCsvHeaders, ...allMaterial];
        setVolumeCsvHeaders(newHeader);

        // Create the CSV data array
        const newData = [
          `${filename}_volume`,
          // newTotalWasteVolume,
          `${newTotalVolumeRecoverable}%`,
          ...newDataVolume,
        ];

        // Update the CSV data state
        setVolumeCsvData(newData);
      }
      exportReady = true;
    }
    setDisableDropdown(!exportReady);
  };

  const prepareNumberOfMaterialDataCSV = () => {
    let exportReady = false;
    if (validSelectedPeriods() && multiPeriods?.selectedList.length === 1) {
      if (totalWasteVolume > 0) {
        const newDataNumberOfMaterial = materialList.map((materialName) => {
          const matchingMaterial = materialDelivery.find(
            (material) => materialName.referenceName === material.cls,
          );

          if (matchingMaterial) {
            return `${((matchingMaterial.count / materialTotal) * 100).toFixed(4)}%`;
          } else {
            return `0.0%`;
          }
        });

        // Update states with the new values
        const newHeader = [...numberOfMaterialHeaders, ...allMaterial];
        setNumberOfMaterialCsvHeaders(newHeader);

        // Create the CSV data array
        const newData = [
          `${filename}_nbObjets`,
          materialTotal,
          ...newDataNumberOfMaterial,
        ];

        // Update the CSV data state
        setNumberOfMaterialCsvData(newData);
      }
      exportReady = true;
    }
    setDisableDropdown(!exportReady);
  };

  useEffect(
    () => {
      if (materialList) {
        const labelMaterial = materialList.map((material) => {
          return `${removeAccents(i18n.language === "fr" ? material.frenchFormattedName : material.englishFormattedName).toUpperCase()}_%`;
        });
        setAllMaterial(labelMaterial);
      }
    },
    // eslint-disable-next-line
    [i18n.language],
  );

  // Fetch characterization timeline
  useEffect(() => {
    setRotationPeriods([]);
    if (superContainer && superContainer._id !== undefined) {
      containers.forEach((container) => {
        fetchRotationTimeline(container._id);
      });
    } else {
      fetchRotationTimeline(data._id);
    }
  }, [data, superContainer, containers, fetchRotationTimeline]);

  useEffect(
    () => {
      setTotalWeight("");
      if (validSelectedPeriods()) {
        fetchWeight(
          superContainer && superContainer._id !== undefined
            ? superContainer._id
            : data._id,
        );
      }
    },
    // eslint-disable-next-line
    [multiPeriods.selectedList, historyMaterial, data],
  );

  useEffect(
    () => {
      prepareMassMaterialDataCSV();
    },
    // eslint-disable-next-line
    [multiPeriods, materialDelivery, totalWasteMass, totalMassRecoverable],
  );

  useEffect(
    () => {
      prepareMassTabDataCSV();
    },
    // eslint-disable-next-line
    [
      multiPeriods,
      data,
      parentCategoriesList,
      totalWasteMass,
      totalMassRecoverable,
    ],
  );

  useEffect(
    () => {
      prepareVolumeDataCSV();
    },
    // eslint-disable-next-line
    [multiPeriods, totalVolumeRecoverable, totalWasteVolume],
  );

  useEffect(
    () => {
      prepareNumberOfMaterialDataCSV();
    },
    // eslint-disable-next-line
    [multiPeriods, totalVolumeRecoverable, materialTotal],
  );

  useEffect(
    () => {
      const datas = [
        {
          data: [masseTabCsvHeaders, massTabCsvData],
          filename: `${filename}_TableauRecap.csv`,
        },
        {
          data: [materialCsvHeaders, materialCsvData],
          filename: `${filename}_masse.csv`,
        },
        {
          data: [volumeHeaders, volumeCsvData],
          filename: `${filename}_volume.csv`,
        },
        {
          data: [numberOfMaterialCsvHeaders, numberOfMaterialCsvData],
          filename: `${filename}_nbObjets.csv`,
        },
      ];
      setMultipleCsvData(datas);
    },
    // eslint-disable-next-line
    [massTabCsvData, materialCsvData, volumeCsvData],
  );

  const selectPeriodFilter = (value) => {
    setMaterialTotal(0);
    setHistoryId(0);
    setMultiPeriods({});
    setSelectedNumberOfCycleMaterial(0);

    if (value === "calendar") {
      setDisplayCalendar(true);
      setCustomDate(true);
    } else {
      setCustomDate(false);
      setDisplayCalendar(false);
      setPeriodMaterialStop("");
      setPeriodMaterialStartSubfilter(value);
      setPeriodMaterialStopSubfilter("");
      setPeriodMaterialStart(value);
    }
  };

  const onChangeCalendar = (event) => {
    setPeriodMaterialStartSubfilter(event[0].toISOString());
    setPeriodMaterialStop(event[1].toISOString());
    setPeriodMaterialStopSubfilter(event[1].toISOString());
    setMaterialDelivery([]);
    setHistoryId(0);
    setParentCategoriesList([]);
    setMaterialTotal(0);
    setDisplayCalendar(false);
    setMultiPeriods({});
    setSelectedNumberOfCycleMaterial(0);
  };

  const getPdfFilename = async (pdfDateTime) => {
    const user = localStorage.getItem("user");
    let corporationName = "";
    await getCorporationData(user).then((responseCorporation) => {
      if (
        responseCorporation.status >= 200 &&
        responseCorporation.status < 400
      ) {
        corporationName = responseCorporation.data.corporation.corporationName;
      }
    });
    const pdfFormattedTime = moment(pdfDateTime).format("YYYYMMDD-HH[h]mm"); // eg 20231024-18h31
    return `AK_WasteQualityCarac_${pdfFormattedTime}_${corporationName}.pdf`;
  };

  const captureCanvasData = async (element) => {
    return html2canvas(element, { allowTaint: true });
  };

  const downloadPdf = async (pdfContent, filename) => {
    const pdfBlob = await pdf(pdfContent).toBlob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(pdfBlob);
    link.download = filename;
    link.click();
  };

  const imageDimensions = async function getDimensions(src) {
    return await reactImageSize(src);
  };

  const fetchTemporalData = async (history, id) => {
    try {
      const responseTemporalData = await getTemporalData(
        { time: history._time },
        id,
      );
      if (responseTemporalData.status === 200) {
        let temporalData =
          responseTemporalData.data.temporalData.length > 0 &&
          responseTemporalData.data.temporalData[0];
        const materialsTemporalData = [];

        const wasteObjects = await getAzureBlob({
          blobUrl: formatAzureBlob(
            temporalData.image_url
              .replace("pictures", "output-surface")
              .replace("-R.jpeg", "-" + id + "-surface.json")
              .replace("-L.jpeg", "-" + id + "-surface.json")
              .replace(".jpeg", "-" + id + "-surface.json"),
          ),
        });

        if (wasteObjects.status === 200) {
          wasteObjects.data.object_segmentations.forEach((annotation) => {
            const annotationToLowerCase = annotation.cls.toLowerCase();
            const index = materialsTemporalData.findIndex(
              (material) =>
                material.name ===
                formattedMaterial(annotationToLowerCase, i18n.language),
            );

            if (index === -1) {
              materialsTemporalData.push({
                name: formattedMaterial(annotationToLowerCase, i18n.language),
                value: annotationToLowerCase,
                color: formattedColorMaterial(annotationToLowerCase),
                count: 1,
                nameAndCount: `${formattedMaterial(annotationToLowerCase, i18n.language)} (1)`,
              });
            } else {
              materialsTemporalData[index].count += 1;
              materialsTemporalData[index].nameAndCount =
                `${formattedMaterial(annotationToLowerCase, i18n.language)} (${materialsTemporalData[index].count})`;
            }
          });
        } else {
          throw new Error("Unexpected response status");
        }
        const dimensions = await imageDimensions(
          azureSASTokenToUrl(temporalData.image_url),
        );
        temporalData.imageDimensions = dimensions;
        const sortedMaterialsTemporalData = materialsTemporalData
          .slice()
          .sort((a, b) =>
            a.value.localeCompare(b.value, "en", { sensitivity: "base" }),
          );
        temporalData.materialsTemporalData = sortedMaterialsTemporalData;
        temporalData.wasteObjectsList = wasteObjects.data.object_segmentations;
        return temporalData;
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      console.error("Error fetching temporal data:", error);
      throw error;
    }
  };

  const generatePdf = async () => {
    setLoading(true);
    const chart = chartRef.current;
    const table = tableRef.current;
    const material = materialRef.current;
    const outlet = outletRef.current;
    const tableOutlet = tableOutletRef.current;
    const volume = volumeRef.current;
    const pdfDateTime = new Date(multiPeriods?.selectedList[0]?.start);
    const user = localStorage.getItem("user");
    let corporationName = "";
    await getCorporationData(user).then((responseCorporation) => {
      if (
        responseCorporation.status >= 200 &&
        responseCorporation.status < 400
      ) {
        corporationName = responseCorporation.data.corporation.corporationName;
      }
    });

    if ((chart && table && material && volume && outlet, tableOutlet)) {
      const [
        chartCanvas,
        tableCanvas,
        materialCanvas,
        volumeCanvas,
        outletCanvas,
        tableOutletCanvas,
      ] = await Promise.all([
        captureCanvasData(chart),
        captureCanvasData(table),
        captureCanvasData(material),
        captureCanvasData(volume),
        captureCanvasData(outlet),
        captureCanvasData(tableOutlet),
      ]);

      const chartImgData = chartCanvas.toDataURL("image/png");
      const tableImgData = tableCanvas.toDataURL("image/png");
      const materialImgData = materialCanvas.toDataURL("image/png");
      const volumeImgData = volumeCanvas.toDataURL("image/png");
      const outletImgData = outletCanvas.toDataURL("image/png");
      const tableOutletImgData = tableOutletCanvas.toDataURL("image/png");
      let temporalDataArray = [];

      if (superContainer && superContainer._id !== undefined) {
        temporalDataArray = [
          await Promise.all(
            caracterisationData[0].historyMaterial.map((item) =>
              fetchTemporalData(item, caracterisationData[0].containerId),
            ),
          ),
          await Promise.all(
            caracterisationData[1].historyMaterial.map((item) =>
              fetchTemporalData(item, caracterisationData[1].containerId),
            ),
          ),
        ];
      } else {
        temporalDataArray = await Promise.all(
          historyMaterial.map((item) => fetchTemporalData(item, data._id)),
        );
      }

      const pdfContent = (
        <WasteQualityPDF
          corporationID={data.corporation}
          caracDatetime={pdfDateTime}
          totalWasteMass={totalWasteMass}
          totalMassRecoverable={totalMassRecoverable}
          chartImageUrl={chartImgData}
          tableImageUrl={tableImgData}
          materialImageUrl={materialImgData}
          selectedNumberOfCycleMaterial={selectedNumberOfCycleMaterial}
          volumeImgData={volumeImgData}
          outletImgData={outletImgData}
          tableOutletImgData={tableOutletImgData}
          totalWasteVolume={totalWasteVolume}
          totalVolumeRecoverable={totalVolumeRecoverable}
          temporalDataArray={temporalDataArray}
          container={data}
          totalWeight={totalWeight * 1000}
          clientName={clientName}
          corporationName={corporationName}
          clientCode={clientCode}
          clientAddress={clientAddress}
          superContainer={
            superContainer && superContainer._id !== undefined ? true : false
          }
          wasteCategoryName={wasteCategoryName}
          parentCategoriesList={parentCategoriesList}
          characterizationOutlet={characterizationOutlet}
        />
      );

      const pdfFilename = await getPdfFilename(pdfDateTime);

      await downloadPdf(pdfContent, pdfFilename);
    }
    setLoading(false);
    toast.success("Votre fichier a bien été téléchargé", {
      className: "custom-toast",
    });
  };

  useEffect(() => {
    getWastecategoryList({
      keywordFilter: "",
      listUnCodeFullFilter: {},
      listUnCodeEmptyFilter: {},
      listWasteCodeFullFilter: {},
      listWasteCodeEmptyFilter: {},
      sortByField: "label",
      orderSortByField: "1",
    }).then((response) => {
      setWasteCategory(response.data.data);
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpenFilter(false);
        setCustomDate(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClear = () => {
    setHistoryId(0);
    setMaterialTotal(0);
    setTotalWasteMass(0);
    setTotalWasteVolume(0);
    setTotalMassRecoverable(0);
    setTotalVolumeRecoverable(0);
    setSelectedNumberOfCycleMaterial(0);
    setOutletData({});
    setMultiPeriods({ selectedList: [] });
    setSelectedList([]);
    setHistoryMaterial([]);
    setMaterialDelivery([]);
    setParentCategoriesList([]);
    setShowData(false);
    setIsLoading(false);
    setCustomDate(false);
    setToogleCharacterization(false);
    setCharacterizationInProgress(false);
    setRotationId(null);
    setCharacterizationName("Aucune caractérisation sélectionnée");
  };

  const handleValidate = () => {
    setOpenFilter(false);
    setCustomDate(false);
    setSelectedNumberOfCycleMaterial(selectedList.length);
    setMultiPeriods({ selectedList: selectedList });
    if (selectedList.length > 0) {
      setCharacterizationName(
        selectedList.length > 1
          ? `${selectedList.length} caractérisations sélectionnées`
          : selectedList[0].name,
      );
      setToogleCharacterization(true);
    } else {
      handleClear();
    }
  };

  return (
    <div className="modal-nav-item">
      {/* Options selection start and stop period */}
      <section
        className="flex flex-row justify-center w-full mb-2"
        ref={filterRef}
      >
        <div
          className={`relative flex flex-col w-full my-3 ${!openFilter && "h-12"}`}
        >
          {loadRotationPeriods ? (
            <div className="flex flex-row items-center justify-center w-full gap-2 bg-white rounded-md px-2 h-12">
              <Spinner className="text-[#D0D0D0]">Loading...</Spinner>
            </div>
          ) : (
            <div className="flex flex-row w-full gap-2 bg-white rounded-md px-2">
              <div className="flex flex-row w-3/5 items-center gap-5 h-12">
                <div className="w-1/6 text-center">
                  {validSelectedPeriods() &&
                    multiPeriods?.selectedList.length === 1 && (
                      <i
                        className={`fas fa-angle-double-left text-2xl cursor-pointer ${rotationId === 0 ? "text-[#8a8989]" : "text-[#00A7A6]"}`}
                        onClick={(event) => {
                          event.preventDefault();
                          setRotationId(Math.max(0, rotationId - 1));
                        }}
                      ></i>
                    )}
                </div>
                <div className="w-4/6 text-center">
                  <h6 className="data-bold mt-1">
                    {rotationPeriods.length > 0
                      ? validSelectedPeriods()
                        ? characterizationName
                        : "Aucune caractérisation sélectionnée"
                      : "Pas de caractérisation"}
                  </h6>
                </div>
                <div className="w-1/6 text-center">
                  {validSelectedPeriods() &&
                    multiPeriods?.selectedList.length === 1 && (
                      <i
                        className={`fas fa-angle-double-right text-2xl cursor-pointer ${rotationId === rotationPeriods.length - 1 ? "text-[#8a8989]" : "text-[#00A7A6]"}`}
                        onClick={(event) => {
                          event.preventDefault();
                          setRotationId(
                            Math.min(
                              rotationId + 1,
                              rotationPeriods.length - 1,
                            ),
                          );
                        }}
                      ></i>
                    )}
                </div>
              </div>
              <div className="flex flex-row items-center flex-end gap-5 w-2/5 h-12">
                <span>{t("Advanced search")}</span>
                <i
                  class="fas fa-sliders-h text-2xl text-[#686868] mr-1 cursor-pointer"
                  onClick={() => setOpenFilter(!openFilter)}
                ></i>
              </div>
            </div>
          )}

          {openFilter ? (
            <div
              className={`absolute w-full z-10 mt-14 bg-[#f8f9fa] p-5 border-2 border-[#D0D0D0] rounded-xl transition duration-200`}
            >
              <div className="flex flex-row gap-2 w-full">
                <div className="flex flex-col w-1/2">
                  <span className="font-semibold">
                    {t("Characterization zone")}
                  </span>
                  <div className="border-2 border-[#D0D0D0] rounded-md">
                    <Input
                      type="select"
                      style={{ borderWidth: "0px" }}
                      name="select-graphe"
                      onChange={(event) =>
                        setSelectedContainer(
                          containers.find(
                            (obj) => obj._id === event.target.value,
                          ) || null,
                        )
                      }
                    >
                      {superContainer ? (
                        <option
                          value={containers[0]._id}
                          key={`container-${containers[0]._id}`}
                        >
                          {containers[0].addressName +
                            " | " +
                            containers[0].wasteName +
                            " | " +
                            containers[0].volume +
                            " " +
                            containers[0].volumeUnit +
                            (containers[0].positioning
                              ? " | " + containers[0].positioning
                              : "")}
                        </option>
                      ) : (
                        containers.map((container) => (
                          <option
                            value={container._id}
                            key={`container-${container._id}`}
                          >
                            {container.addressName +
                              " | " +
                              container.wasteName +
                              " | " +
                              container.volume +
                              " " +
                              container.volumeUnit +
                              (container.positioning
                                ? " | " + container.positioning
                                : "")}
                          </option>
                        ))
                      )}
                    </Input>
                  </div>
                </div>
                {customDate && (
                  <div className="w-1/3 mt-4">
                    {/* Calendar */}
                    <CalendarComponent
                      classNameBase="relative flex mx-2"
                      onChangeCalendar={onChangeCalendar}
                      displayCalendar={displayCalendar}
                      setDisplayCalendar={setDisplayCalendar}
                      periodHistoryStart={periodMaterialStartSubfilter}
                      periodHistoryStop={periodMaterialStopSubfilter}
                    />
                  </div>
                )}
                <div
                  className={`flex flex-col ${customDate ? "w-1/3" : "w-1/2"}`}
                >
                  <span className="font-semibold">{t("Period")}</span>
                  <div className="rounded-md border-2 border-[#D0D0D0]">
                    <Input
                      type="select"
                      style={{ borderWidth: "0px" }}
                      name="select-graphe"
                      value={periodMaterialStart}
                      onChange={(event) =>
                        selectPeriodFilter(event.target.value)
                      }
                    >
                      <option value="0">
                        {t("Current month")} :{" "}
                        {capitalizeFirstLetter(currentMonthName(i18n.language))}
                      </option>
                      <option value="1">
                        {t("Last month")} :{" "}
                        {capitalizeFirstLetter(
                          previousMonthName(i18n.language),
                        )}
                      </option>
                      <option value="-3mo">{t("Last 3 months")}</option>
                      <option value="-6mo">{t("Last 6 months")}</option>
                      <option value="-12mo">{t("Last 12 months")}</option>
                      <option value="calendar">{t("Custom period")}</option>
                    </Input>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full mt-3">
                <span className="font-semibold">{`${t("Choose my characterizations from the")} ${rotationPeriods.length} ${t("completed")}`}</span>
                <ContainerViewMultiSelect
                  rotationPeriods={rotationPeriods}
                  multiPeriods={multiPeriods}
                  setMultiPeriods={setMultiPeriods}
                  setSelectedNumberOfCycleMaterial={
                    setSelectedNumberOfCycleMaterial
                  }
                  setHistoryId={setHistoryId}
                  periodMaterialStart={periodMaterialStart}
                  setRotationId={setRotationId}
                  rotationId={rotationId}
                  setCharacterizationName={setCharacterizationName}
                  handleClear={handleClear}
                  selectedList={selectedList}
                  setSelectedList={setSelectedList}
                />
              </div>
              <div className="flex justify-center w-full">
                <Button
                  className="bg-[#00A7A6] w-40 mt-4"
                  onClick={() => handleValidate()}
                >
                  {t("Validate")}
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
      {!expendImage && (
        <section className="flex flex-row flex-end w-full mb-2">
          <article className="flex flex-row flex-end w-5/6 gap-2 items-center">
            <span className="w-5/6 flex flex-end">
              {t(
                "Export the page with the outlet in the characterization report",
              )}
            </span>
            <Input
              className="information-data-check mt-auto"
              type="checkbox"
              checked={characterizationOutlet}
              onChange={(event) =>
                setCharacterizationOutlet(event.target.checked)
              }
              name="characterizationOutlet"
            />
          </article>
          <article className="mr-4">
            <Dropdown
              text={t("Export")}
              disabled={
                disableDropdown || characterizationInProgress || isLoading
              }
              children={
                <>
                  <span
                    onClick={() => handleCsvExport()}
                    className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 cursor-pointer"
                  >
                    CSV (.csv)
                  </span>
                  <span
                    onClick={() => generatePdf()}
                    className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 cursor-pointer"
                  >
                    PDF (.pdf)
                  </span>
                </>
              }
            />
          </article>
          {/* Action */}
          <article
            className="absolute right-6"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <i
              className={`button-action mt-2.5 ${!isOpen ? "fas fa-ellipsis-v" : "fas fa-times ml-36"}`}
              onClick={() => setIsOpen(!isOpen)}
            ></i>
            <div
              className={`action-choice ${isOpen && "display-action absolute"}`}
            >
              <button
                className="action"
                onClick={() => {
                  handleOpenModalClick(
                    data,
                    formData,
                    setFormData,
                    setModalEdit,
                  );
                  setIsOpen(!isOpen);
                }}
              >
                {t("Update")}
              </button>
              <button
                className="action"
                onClick={() => {
                  setShowDetails(!showDetails);
                  setIsOpen(!isOpen);
                }}
              >
                {t("Characteristics")}
              </button>
            </div>
          </article>
        </section>
      )}
      {!isLoading && showData ? (
        <>
          <section className="modal-item-key">
            <article className="modal-item-key-content">
              <div className="modal-item-key-title text-blue">
                <span>Client</span>
              </div>
              <div className="mt-2">
                <h5
                  className="font-bold"
                  style={{ color: "var(--blush)", fontSize: "28px" }}
                >
                  {clientName !== "" && multiPeriods?.selectedList?.length === 1
                    ? clientName
                    : "-"}
                </h5>
              </div>
            </article>
            <article className="modal-item-key-content">
              <div className="modal-item-key-title text-blue">
                <span>{t("Address")}</span>
              </div>
              <div className="mt-2">
                <h5
                  className="font-bold"
                  style={{ color: "var(--blush)", fontSize: "28px" }}
                >
                  {clientAddress !== "" &&
                  clientAddress !== "undefined" &&
                  multiPeriods?.selectedList?.length === 1
                    ? clientAddress
                    : "-"}
                </h5>
              </div>
            </article>
            <article className="modal-item-key-content">
              <div className="modal-item-key-title text-blue">
                <span>{t("Weight")}</span>
              </div>
              {multiPeriods?.selectedList?.length > 1 ? (
                missingWeight ? (
                  <div className="flex flex-row mt-2">
                    <span className="text-blue mt-3">
                      {t(
                        "The weight is missing on one of the selected characterizations",
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="flex flex-row mt-2">
                    <h5
                      className="font-bold"
                      style={{ color: "var(--blush)", fontSize: "28px" }}
                    >
                      {totalWeight !== "" ? toFrenchNumber(totalWeight) : "-"}
                    </h5>
                    <span className="text-blue mt-3 ml-3">
                      {t("tons of waste")}
                    </span>
                  </div>
                )
              ) : (
                <div className="flex flex-row mt-2">
                  <h5
                    className="font-bold"
                    style={{ color: "var(--blush)", fontSize: "28px" }}
                  >
                    {totalWeight !== "" ? toFrenchNumber(totalWeight) : "-"}
                  </h5>
                  <span className="text-blue mt-1.5 ml-3">
                    {t("tons of waste")}
                  </span>
                </div>
              )}
            </article>
            <article className="modal-item-key-content">
              <div className="modal-item-key-title text-blue">
                <span>{t("Waste name")}</span>
                {multiPeriods?.selectedList &&
                  multiPeriods?.selectedList?.length === 1 && (
                    <div
                      className="w-7 h-7 rounded-full"
                      style={{ backgroundColor: "var(--akanthas-blue)" }}
                    >
                      <i
                        className="fas fa-pen cursor-pointer text-white mt-1.5 ml-1.5"
                        onClick={() => {
                          if (
                            historyId === 0 &&
                            multiPeriods?.selectedList?.length <= 1
                          ) {
                            toggleDisplayWeight();
                            setDisplayClient(true);
                            setNewClient(false);
                          }
                        }}
                      ></i>
                    </div>
                  )}
              </div>
              <div className="flex flex-row mt-2">
                <h5
                  className="font-bold"
                  style={{ color: "var(--blush)", fontSize: "28px" }}
                >
                  {wasteCategoryName !== "" &&
                  multiPeriods?.selectedList?.length === 1
                    ? JSON.parse(wasteCategoryName).wasteCategoryName
                    : "-"}
                </h5>
              </div>
            </article>
          </section>
          <ContainerModalClient
            data={data}
            displayClient={displayClient}
            setDisplayClient={setDisplayClient}
            weightRef={weightRef}
            newClient={newClient}
            setNewClient={setNewClient}
            handleSelectionChange={handleSelectionChange}
            setClientCode={setClientCode}
            clientName={clientName}
            setClientName={setClientName}
            setClientAddress={setClientAddress}
            weight={weight}
            setWeight={setWeight}
            wasteCategoryName={wasteCategoryName}
            setWasteCategoryName={setWasteCategoryName}
            wasteCategory={wasteCategory}
            saveWeight={saveWeight}
            totalWeight={totalWeight}
            superContainer={superContainer}
            isCharacterization={true}
          />
        </>
      ) : (
        ""
      )}
      {superContainer && superContainer._id !== undefined ? (
        <SuperContainerViewMaterial
          data={data}
          expendImage={expendImage}
          setExpendImage={setExpendImage}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          multiPeriods={multiPeriods}
          setMaterialDelivery={setMaterialDelivery}
          materialDelivery={materialDelivery}
          setHistoryMaterial={setHistoryMaterial}
          historyMaterial={historyMaterial}
          setParentCategoriesList={setParentCategoriesList}
          parentCategoriesList={parentCategoriesList}
          setCharacterizationInProgress={setCharacterizationInProgress}
          characterizationInProgress={characterizationInProgress}
          setTotalWasteMass={setTotalWasteMass}
          totalWasteMass={totalWasteMass}
          setTotalWasteVolume={setTotalWasteVolume}
          totalWasteVolume={totalWasteVolume}
          setMaterialTotal={setMaterialTotal}
          materialTotal={materialTotal}
          setTotalMassRecoverable={setTotalMassRecoverable}
          totalMassRecoverable={totalMassRecoverable}
          setTotalVolumeRecoverable={setTotalVolumeRecoverable}
          totalVolumeRecoverable={totalVolumeRecoverable}
          periodMaterialStart={periodMaterialStart}
          periodMaterialStop={periodMaterialStop}
          mergeMaterialFluxes={mergeMaterialFluxes}
          mergeSimilarMaterials={mergeSimilarMaterials}
          selectedNumberOfCycleMaterial={selectedNumberOfCycleMaterial}
          historyId={historyId}
          setHistoryId={setHistoryId}
          setDisplayMassChart={setDisplayMassChart}
          displayMassChart={displayMassChart}
          setDisplayVolumeChart={setDisplayVolumeChart}
          displayVolumeChart={displayVolumeChart}
          displayOutletChart={displayOutletChart}
          setDisplayOutletChart={setDisplayOutletChart}
          setDisplayNumberOfObjectChart={setDisplayNumberOfObjectChart}
          displayNumberOfObjectChart={displayNumberOfObjectChart}
          setHelpVolumeRecoverable={setHelpVolumeRecoverable}
          helpVolumeRecoverable={helpVolumeRecoverable}
          toggleDisplayWeight={toggleDisplayWeight}
          setDisplayWeight={setDisplayWeight}
          displayWeight={displayWeight}
          totalWeight={totalWeight}
          missingWeight={missingWeight}
          weightRef={weightRef}
          tableRef={tableRef}
          chartRef={chartRef}
          volumeRef={volumeRef}
          materialRef={materialRef}
          setDisplayClient={setDisplayClient}
          displayClient={displayClient}
          setNewClient={setNewClient}
          newClient={newClient}
          setClientName={setClientName}
          clientName={clientName}
          setClientCode={setClientCode}
          setClientAddress={setClientAddress}
          setWeight={setWeight}
          weight={weight}
          saveWeight={saveWeight}
          setHelpMassRecoverable={setHelpMassRecoverable}
          helpMassRecoverable={helpMassRecoverable}
          setToogleMassChart={setToogleMassChart}
          toogleMassChart={toogleMassChart}
          toogleOutletChart={toogleOutletChart}
          setToogleOutletChart={setToogleOutletChart}
          handleSelectionChange={handleSelectionChange}
          superContainer={superContainer}
          containers={containers}
          caracterisationData={caracterisationData}
          setCaracterisationData={setCaracterisationData}
          setLoadWeight={setLoadWeight}
          loadWeight={loadWeight}
          formatOutletData={formatOutletData}
          outletData={outletData}
          setOutletData={setOutletData}
          outletRef={outletRef}
          tableOutletRef={tableOutletRef}
        />
      ) : (
        <SingleContainerViewMaterial
          data={data}
          expendImage={expendImage}
          setExpendImage={setExpendImage}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          multiPeriods={multiPeriods}
          setMaterialDelivery={setMaterialDelivery}
          materialDelivery={materialDelivery}
          setHistoryMaterial={setHistoryMaterial}
          historyMaterial={historyMaterial}
          setParentCategoriesList={setParentCategoriesList}
          parentCategoriesList={parentCategoriesList}
          setCharacterizationInProgress={setCharacterizationInProgress}
          characterizationInProgress={characterizationInProgress}
          setTotalWasteMass={setTotalWasteMass}
          totalWasteMass={totalWasteMass}
          setTotalWasteVolume={setTotalWasteVolume}
          totalWasteVolume={totalWasteVolume}
          setMaterialTotal={setMaterialTotal}
          materialTotal={materialTotal}
          setTotalMassRecoverable={setTotalMassRecoverable}
          totalMassRecoverable={totalMassRecoverable}
          setTotalVolumeRecoverable={setTotalVolumeRecoverable}
          totalVolumeRecoverable={totalVolumeRecoverable}
          periodMaterialStart={periodMaterialStart}
          periodMaterialStop={periodMaterialStop}
          mergeMaterialFluxes={mergeMaterialFluxes}
          mergeSimilarMaterials={mergeSimilarMaterials}
          selectedNumberOfCycleMaterial={selectedNumberOfCycleMaterial}
          historyId={historyId}
          setHistoryId={setHistoryId}
          setDisplayMassChart={setDisplayMassChart}
          displayMassChart={displayMassChart}
          setDisplayVolumeChart={setDisplayVolumeChart}
          displayVolumeChart={displayVolumeChart}
          displayOutletChart={displayOutletChart}
          setDisplayOutletChart={setDisplayOutletChart}
          setDisplayNumberOfObjectChart={setDisplayNumberOfObjectChart}
          displayNumberOfObjectChart={displayNumberOfObjectChart}
          setHelpVolumeRecoverable={setHelpVolumeRecoverable}
          helpVolumeRecoverable={helpVolumeRecoverable}
          toggleDisplayWeight={toggleDisplayWeight}
          setDisplayWeight={setDisplayWeight}
          displayWeight={displayWeight}
          totalWeight={totalWeight}
          missingWeight={missingWeight}
          weightRef={weightRef}
          tableRef={tableRef}
          chartRef={chartRef}
          volumeRef={volumeRef}
          materialRef={materialRef}
          setDisplayClient={setDisplayClient}
          displayClient={displayClient}
          setNewClient={setNewClient}
          newClient={newClient}
          setClientName={setClientName}
          clientName={clientName}
          setClientCode={setClientCode}
          setClientAddress={setClientAddress}
          setWeight={setWeight}
          weight={weight}
          saveWeight={saveWeight}
          setHelpMassRecoverable={setHelpMassRecoverable}
          helpMassRecoverable={helpMassRecoverable}
          setToogleMassChart={setToogleMassChart}
          toogleMassChart={toogleMassChart}
          toogleOutletChart={toogleOutletChart}
          setToogleOutletChart={setToogleOutletChart}
          handleSelectionChange={handleSelectionChange}
          setLoadWeight={setLoadWeight}
          loadWeight={loadWeight}
          formatOutletData={formatOutletData}
          outletData={outletData}
          setOutletData={setOutletData}
          outletRef={outletRef}
          tableOutletRef={tableOutletRef}
          toogleCharacterization={toogleCharacterization}
          setToogleCharacterization={setToogleCharacterization}
        />
      )}

      {modalEdit && (
        <ContainerEdit
          tableData={data}
          editFormData={formData}
          setEditFormData={setFormData}
          modalEdit={modalEdit}
          setModalEdit={setModalEdit}
          superContainer={true}
          containerRight={superContainer ? containers[1] : data}
          setSelectedContainer={setSelectedContainer}
          fetchContainer={fetchContainer}
        />
      )}

      <Modal isOpen={showDetails}>
        <div className="base rounded" style={{ minWidth: "850px" }}>
          <div className="container-modal-header mt-2 mb-2">
            <ModalTitle>
              <div className="data-bold">
                <span>{data.addressName} | </span>
                <span style={{ color: "var(--blush)" }}>
                  {(data.contentCategory && data.contentCategory.label) ||
                    data.wasteName ||
                    "-"}{" "}
                </span>
                <span>
                  |{" "}
                  {((data.volume > 0 && data.volume) ||
                    data.volumeToCubicMeter) +
                    "" +
                    (data.volumeUnit || "m3") || "-"}
                </span>
                {data?.positioning && data.positioning !== "" && (
                  <>
                    <span> | </span>
                    <span style={{ color: "var(--gray)" }}>
                      {data.positioning}
                    </span>
                  </>
                )}
              </div>
            </ModalTitle>
            <CloseIcon
              style={{
                color: "var(--gray)",
                cursor: "pointer",
                marginTop: 8,
                marginLeft: 5,
              }}
              onClick={() => {
                setShowDetails(!showDetails);
              }}
            />
          </div>
          <ContainerViewDetails data={data} />
        </div>
      </Modal>

      {loading && <LoadingScreen />}
    </div>
  );
};

export default ContainerViewMaterial;
