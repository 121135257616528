import React from "react";
import { Image, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import akanthasLogo from "../../../static/assets/logo_rvg.png";
import wasteQualityLogo from "../../../static/assets/waste_quality_logo.png";
import { formattedLongHandFr } from "../../formatted/FormattedDateAndTime";
import MulishLight from "../../../fonts/Mulish/Mulish-Light.ttf";
import MulishMedium from "../../../fonts/Mulish/Mulish-Medium.ttf";
import ShowClientLogo from "./ClientLogo";
import { useTranslation } from "react-i18next";

Font.register({
  family: "Mulish",
  fonts: [
    { src: MulishLight, fontWeight: 200 },
    { src: MulishMedium, fontWeight: 500 },
  ],
});

const HeaderWasteQualityPDF = ({ caracDatetime, corporationID }) => {
  const { t, i18n } = useTranslation();

  const styles = StyleSheet.create({
    header: {
      height: "18%",
      backgroundColor: "#f0f0f0",
      width: "100%",
      top: 0,
      left: 0,
      display: "flex",
    },
    logos: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "40%",
    },
    akLogo: {
      width: "150px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    clientLogo: {
      height: "70%",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    logoAndTitle: {
      marginVertical: 3,
      height: "25%",
      backgroundColor: "#f0f0f0",
      width: "100%",
      flexDirection: "row",
    },
    wasteQualityLogo: {
      width: "18%",
      height: 80,
      justifyContent: "space-between",
      paddingLeft: "25px",
    },
    titleAndDateTime: {
      marginVertical: "auto",
      paddingLeft: "18px",
      width: "80%",
    },
    title: {
      fontSize: 25,
      color: "#00A7A6",
      fontFamily: "Mulish",
      fontWeight: 500,
      letterSpacing: "1px",
    },
    dateTime: {
      fontSize: 15,
      color: "#e81d7a",
      fontFamily: "Mulish",
      fontWeight: 200,
      letterSpacing: "1px",
    },
  });

  return (
    <View style={styles.header}>
      <View style={styles.logos}>
        <Image style={styles.akLogo} src={akanthasLogo} />
        {ShowClientLogo(corporationID, styles.clientLogo)}
      </View>

      <View style={styles.logoAndTitle}>
        <View style={styles.wasteQualityLogo}>
          <Image src={wasteQualityLogo} />
        </View>

        <View style={styles.titleAndDateTime}>
          <Text style={styles.title}>{t("My characterization report")}</Text>
          <Text style={styles.dateTime}>
            {formattedLongHandFr(caracDatetime, i18n.language)}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default HeaderWasteQualityPDF;
