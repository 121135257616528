import { useState } from "react";
import HelpOpenFile from "./HelpOpenFile";

const Help = () => {
  const [isModalDownload, setIsModalDownload] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [titleModal, setTitleModal] = useState(null);

  const openFile = (fileName, title) => {
    setFileName(fileName);
    setIsModalDownload(true);
    setTitleModal(title);
  };

  return (
    <div className="base">
      <h1>Aide et contact - Besoin d'assistance ?</h1>
      <section className="help">
        <article className="help-use">
          <h3>Pour l’utilisation de la plateforme ?</h3>
          <article className="help-list">
            <div
              className="help-block"
              onClick={() => {
                openFile("userguide_monitoring.pdf", "Monitoring des déchets");
              }}
            >
              <i className="fas fa-laptop help-icon"></i>
              <p>Monitoring des déchets</p>
            </div>

            <div
              className="help-block"
              onClick={() => {
                openFile("help_download.pdf", "Téléchargement d’un document");
              }}
            >
              <i className="fas fa-download help-icon"></i>
              <p>Téléchargement d’un document</p>
            </div>
          </article>
        </article>
      </section>

      {isModalDownload ? (
        <HelpOpenFile
          isModalDownload={isModalDownload}
          setIsModalDownload={setIsModalDownload}
          titleModal={titleModal}
          fileName={fileName}
        />
      ) : null}
    </div>
  );
};

export default Help;
