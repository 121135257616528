import React, { useState } from "react";
import { Card } from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";
import ReactSlider from "react-slider";
import {
  formattedContainerId,
  formattedStatus,
  formattedTypeContainer,
} from "../../../components/formatted/FormattedText";
import ToggleFilterButton from "../../ToggleFilterButton";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const ContainerFilter = ({
  arrayFilterFillingLevel,
  setArrayFilterFIllingLevel,
  keywordFilterOptions,
  keywordFilter,
  setKeywordFilter,
  contentKeysFiltered,
  setContentKeysFiltered,
  addressKeysFiltered,
  setAddressKeysFiltered,
  statusKeysFiltered,
  setStatusKeysFiltered,
  typeKeysFiltered,
  setTypeKeysFiltered,
  volumeKeysFiltered,
  setVolumeKeysFiltered,
  cleanFilters,
  cleanAllFilters,
  setCleanAllFilters,
  setTypeFillingFilter,
  multiselectTypeRef,
  multiselectContentRef,
  multiselectAddressRef,
  multiselectStatusRef,
  multiselectVolumeRef,
  hourKeysFiltered,
  setHourKeysFiltered,
  multiselectHour,
}) => {
  const { t } = useTranslation();
  const cookieValue = Cookies.get("containerFilter");
  const parsedValue = cookieValue ? JSON.parse(cookieValue) : false;
  const [filterActive, setFilterActive] = useState(parsedValue);

  const labeledkeywordFilterOptions = keywordFilterOptions.map((keyword) => {
    if (keyword.type === "type") {
      keyword = { ...keyword, key: formattedTypeContainer(keyword.key) };
    } else if (keyword.type === "id") {
      keyword = {
        ...keyword,
        key: formattedContainerId(keyword.key) + keyword.key,
      };
    } else if (keyword.type === "status") {
      keyword = { ...keyword, key: formattedStatus(keyword.key) };
    }
    return keyword;
  });

  const listDayHours = () => {
    const now = new Date();
    const hours = [];
    for (let hour = 0; hour <= now.getHours(); hour++) {
      const date = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        hour,
      );
      if (date.getDate() === now.getDate()) {
        hours.push({ key: ` ${date.getHours()}h`, value: date.toISOString() });
      }
    }
    return hours;
  };

  const typeKeys = labeledkeywordFilterOptions.filter((v) => v.type === "type");
  const contentKeys = labeledkeywordFilterOptions.filter(
    (v) => v.type === "waste",
  );
  const addressKeys = labeledkeywordFilterOptions.filter(
    (v) => v.type === "address",
  );
  const statusKeys = labeledkeywordFilterOptions.filter(
    (v) => v.type === "status",
  );
  const volumeKeys = labeledkeywordFilterOptions.filter(
    (v) => v.type === "volume",
  );
  const listHours = listDayHours().reverse();

  return (
    <>
      <ToggleFilterButton
        cookiesKey="containerFilter"
        filterActive={filterActive}
        setFilterActive={setFilterActive}
      />
      {filterActive && (
        <Card className="card-filter">
          <section className="top-filter w-full">
            <div className="table-filter">
              <span className="filter-name">{t("Container Id")}</span>
              <input
                className="operation-sort h-10 rounded"
                type="text"
                id="word"
                name="word"
                value={keywordFilter.length > 0 ? keywordFilter[0].key : ""}
                placeholder={`${t("ID")} : 35, 670`}
                onChange={(event) =>
                  setKeywordFilter([{ type: "id", key: event.target.value }])
                }
              ></input>
            </div>

            <div className="table-filter">
              <div className="filter-name">
                <i className="fas fa-tag ico-action"></i>
                <span>{t("Status")}</span>
              </div>
              <Multiselect
                options={statusKeys}
                placeholder={t("Select a value")}
                displayValue="key"
                onSelect={(value) => setStatusKeysFiltered(value)}
                onRemove={(value) => setStatusKeysFiltered(value)}
                ref={multiselectStatusRef}
                showArrow={true}
                hidePlaceholder={true}
                selectedValues={statusKeysFiltered}
              />
            </div>

            <div className="table-filter">
              <div className="filter-name">
                <i className="fas fa-archive ico-action"></i>
                <span>{t("Container type")}</span>
              </div>
              <Multiselect
                options={typeKeys}
                placeholder={t("Filter by type")}
                displayValue="key"
                onSelect={(value) => setTypeKeysFiltered(value)}
                onRemove={(value) => setTypeKeysFiltered(value)}
                ref={multiselectTypeRef}
                showArrow={true}
                hidePlaceholder={true}
                selectedValues={typeKeysFiltered}
              />
            </div>

            <div className="table-filter">
              <div className="filter-name">
                <i className="fas fa-layer-group ico-action"></i>
                <span>
                  Volume m<sup>3</sup>
                </span>
              </div>
              <Multiselect
                options={volumeKeys}
                placeholder={t("Filter by volume")}
                displayValue="key"
                onSelect={(value) => setVolumeKeysFiltered(value)}
                onRemove={(value) => setVolumeKeysFiltered(value)}
                ref={multiselectVolumeRef}
                showArrow={true}
                hidePlaceholder={true}
                selectedValues={volumeKeysFiltered}
              />
            </div>

            <div className="table-filter">
              <div className="filter-name">
                <i className="fas fa-leaf ico-action"></i>
                <span>{t("Material")}</span>
              </div>
              <Multiselect
                options={contentKeys}
                placeholder={t("Filter by waste")}
                displayValue="key"
                onSelect={(value) => setContentKeysFiltered(value)}
                onRemove={(value) => setContentKeysFiltered(value)}
                ref={multiselectContentRef}
                showArrow={true}
                hidePlaceholder={true}
                selectedValues={contentKeysFiltered}
              />
            </div>

            <div className="table-filter">
              <div className="filter-name">
                <i className="fas fa-map-pin ico-action"></i>
                <span>{t("Near")}</span>
              </div>
              <Multiselect
                options={addressKeys}
                placeholder={t("Filter by address")}
                displayValue="key"
                onSelect={(value) => setAddressKeysFiltered(value)}
                onRemove={(value) => setAddressKeysFiltered(value)}
                ref={multiselectAddressRef}
                showArrow={true}
                selectionLimit={1}
                hidePlaceholder={true}
                selectedValues={addressKeysFiltered}
              />
            </div>

            <div className="table-filter">
              <span className="filter-name">{t("Filling level")}</span>
              <ReactSlider
                className="horizontal-slider"
                thumbClassName="example-thumb"
                thumbActiveClassName="example-active-thumb"
                trackClassName="example-track"
                value={arrayFilterFillingLevel}
                ariaLabel={["Lower thumb", "Upper thumb"]}
                ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                renderThumb={(props, state) => (
                  <div {...props}>{state.valueNow}</div>
                )}
                minDistance={10}
                onAfterChange={(value) => {
                  setArrayFilterFIllingLevel(value);
                }}
                pearling
              />
              <div className="label-slider-value">
                <p>0%</p>
                <p>100%</p>
              </div>
            </div>

            <div className="table-filter">
              <div className="filter-name">
                <i className="fas fa-clock ico-action"></i>
                <span>{t("State at")}</span>
              </div>
              <Multiselect
                options={listHours}
                placeholder={t("Filter by schedule")}
                displayValue="key"
                onSelect={(selectedList) => setHourKeysFiltered(selectedList)}
                onRemove={(selectedList) => setHourKeysFiltered(selectedList)}
                ref={multiselectHour}
                showArrow={true}
                selectionLimit={1}
                hidePlaceholder={true}
                selectedValues={hourKeysFiltered}
              />
            </div>

            <div className="table-filter">
              <button className="delete-button" onClick={cleanFilters}>
                {t("Clear filters")}
              </button>
            </div>

            <div className="table-filter"></div>
          </section>
        </Card>
      )}
    </>
  );
};

export default ContainerFilter;
