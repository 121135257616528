import React, { useRef, useState } from "react";
import { Card } from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";
import { formattedRoleAddress } from "../../../components/formatted/FormattedText";
import ToggleFilterButton from "../../ToggleFilterButton";
import Cookies from "js-cookie";

const AddressFilter = ({
  setKeywordFilter,
  keywordFilter,
  postalCodeKeys,
  setListPostalCodeFilter,
  cityKeys,
  setListCityFilter,
  roleKeys,
  setListRoleFilter,
}) => {
  const multiselectCityRef = useRef();
  const multiselectPostalCodeRef = useRef();
  const multiselectRoleRef = useRef();

  const cookieValue = Cookies.get("addressFilter");
  const parsedValue = cookieValue ? JSON.parse(cookieValue) : false;
  const [filterActive, setFilterActive] = useState(parsedValue);

  const labeledRoleKeys = roleKeys.map((v) => ({
    ...v,
    label: formattedRoleAddress(v.value),
  }));

  const cleanFilters = () => {
    setListCityFilter({});
    multiselectCityRef.current.resetSelectedValues();
    setListPostalCodeFilter({});
    multiselectPostalCodeRef.current.resetSelectedValues();
    setListRoleFilter({});
    multiselectRoleRef.current.resetSelectedValues();
    // setRoleFilter("");
    setKeywordFilter("");
  };

  return (
    <>
      <ToggleFilterButton
        cookiesKey="addressFilter"
        filterActive={filterActive}
        setFilterActive={setFilterActive}
      />
      {filterActive && (
        <Card className="card-filter">
          <section className="top-filter">
            <div className="table-filter">
              <span className="filter-name">Filtre par mot clé</span>
              <input
                className="operation-sort"
                type="text"
                id="word"
                name="word"
                value={keywordFilter}
                placeholder="Nom, Adresse"
                onChange={(event) => setKeywordFilter(event.target.value)}
              ></input>
            </div>

            <div className="table-filter">
              <div className="filter-name">
                <i className="fas fa-filter ico-action"></i>
                <span>Rôle</span>
              </div>
              <Multiselect
                options={labeledRoleKeys}
                displayValue="label"
                onSelect={(selectedList) => setListRoleFilter({ selectedList })}
                onRemove={(selectedList) => setListRoleFilter({ selectedList })}
                ref={multiselectRoleRef}
                placeholder="Toutes les villes"
                showArrow={true}
                hidePlaceholder={true}
              />
            </div>

            <div className="table-filter">
              <div className="filter-name">
                <i className="fas fa-map-pin ico-action"></i>
                <span>Code Postal</span>
              </div>
              <Multiselect
                options={postalCodeKeys}
                displayValue="value"
                onSelect={(selectedList) =>
                  setListPostalCodeFilter({ selectedList })
                }
                onRemove={(selectedList) =>
                  setListPostalCodeFilter({ selectedList })
                }
                ref={multiselectPostalCodeRef}
                placeholder="Tous les codes postaux"
                showArrow={true}
                hidePlaceholder={true}
              />
            </div>

            <div className="table-filter">
              <div className="filter-name">
                <i className="fas fa-building ico-action"></i>
                <span>Ville</span>
              </div>
              <Multiselect
                options={cityKeys}
                displayValue="value"
                onSelect={(selectedList) => setListCityFilter({ selectedList })}
                onRemove={(selectedList) => setListCityFilter({ selectedList })}
                ref={multiselectCityRef}
                placeholder="Toutes les villes"
                showArrow={true}
                hidePlaceholder={true}
              />
            </div>

            <div className="table-filter">
              <div className="filter-name"></div>
              <button className="delete-button" onClick={cleanFilters}>
                Effacer les filtres
              </button>
            </div>

            <div className="table-filter"></div>
          </section>
        </Card>
      )}
    </>
  );
};

export default AddressFilter;
