import { React } from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { toFrenchNumber } from "../../formatted/FormattedText";
import "react-calendar/dist/Calendar.css";
import HeaderWasteQualityPDF from "./HeaderWasteQualityPDF";
import { wasteQualityStyleSheet } from "../styles/wasteQualityStyleSheet";
import caracterisation from "../../../static/assets/caracterisation.png";
import CoverWasteQualityPdf from "./CoverWasteQualityPDF";
import { WasteQualityImageComponent } from "./WasteQualityImageComponent";
import { WasteQualityFooter } from "./WasteQualityFooter";
import { useTranslation } from "react-i18next";

const WasteQualityPDF = ({
  caracDatetime,
  corporationID,
  totalWasteMass,
  totalMassRecoverable,
  chartImageUrl,
  tableImageUrl,
  materialImageUrl,
  selectedNumberOfCycleMaterial,
  volumeImgData,
  outletImgData,
  tableOutletImgData,
  totalWasteVolume,
  totalVolumeRecoverable,
  temporalDataArray,
  container,
  totalWeight,
  clientName,
  corporationName,
  clientCode,
  clientAddress,
  superContainer,
  wasteCategoryName,
  parentCategoriesList,
  characterizationOutlet,
}) => {
  const { t, i18n } = useTranslation();

  const totalMassInOutlet = parentCategoriesList.reduce(
    (sum, item) => sum + item.massInOutlet,
    0,
  );
  const totalMassNotInOutlet = parentCategoriesList.reduce(
    (sum, item) => sum + item.massNotInOutlet,
    0,
  );

  return (
    <Document>
      <Page size="A4">
        <CoverWasteQualityPdf
          caracDatetime={caracDatetime}
          corporationID={corporationID}
          clientName={clientName}
          container={container}
          corporationName={corporationName}
          totalWeight={totalWeight}
          clientCode={clientCode}
          clientAddress={clientAddress}
          wasteCategoryName={
            wasteCategoryName !== ""
              ? JSON.parse(wasteCategoryName).wasteCategoryName
              : null
          }
          wasteCategoryCode={
            wasteCategoryName !== ""
              ? JSON.parse(wasteCategoryName).wasteCategoryCode
              : null
          }
        >
          {" "}
        </CoverWasteQualityPdf>
        <WasteQualityFooter page={1} cover={true} />
      </Page>
      <Page size="A4">
        <HeaderWasteQualityPDF
          caracDatetime={caracDatetime}
          corporationID={corporationID}
        />
        <View style={{ height: "79%" }}>
          <View style={wasteQualityStyleSheet.tableContent}>
            <View style={{ flex: 3 }}>
              <Text
                style={{
                  ...wasteQualityStyleSheet.ChartTitle,
                  marginBottom: 20,
                }}
              >
                {t("Results")}
              </Text>
              <View style={wasteQualityStyleSheet.caracterisationAndStats}>
                <View style={wasteQualityStyleSheet.caracterisationLogo}>
                  <Image src={caracterisation} />
                </View>
                <View style={{ marginRight: 2 }}>
                  <Text style={wasteQualityStyleSheet.caracterisationContainer}>
                    {selectedNumberOfCycleMaterial}
                  </Text>
                </View>
                <View>
                  {i18n.language === "fr" ? (
                    <>
                      <Text style={wasteQualityStyleSheet.caracterisationText}>
                        contenant
                      </Text>
                      <Text style={wasteQualityStyleSheet.caracterisationText}>
                        caractérisé
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={wasteQualityStyleSheet.caracterisationText}>
                        Characterized
                      </Text>
                      <Text style={wasteQualityStyleSheet.caracterisationText}>
                        container
                      </Text>
                    </>
                  )}
                </View>
              </View>
            </View>
            <View style={wasteQualityStyleSheet.image}>
              {tableImageUrl && <Image src={tableImageUrl} />}
            </View>
          </View>
          <View style={wasteQualityStyleSheet.chartContent}>
            <View style={{ flex: 3 }}>
              {i18n.language === "fr" ? (
                <>
                  <Text style={wasteQualityStyleSheet.ChartTitle}>
                    Détail analyse
                  </Text>
                  <Text style={wasteQualityStyleSheet.ChartTitle}>
                    massique
                  </Text>
                </>
              ) : (
                <>
                  <Text style={wasteQualityStyleSheet.ChartTitle}>
                    Mass analysis
                  </Text>
                  <Text style={wasteQualityStyleSheet.ChartTitle}>details</Text>
                </>
              )}
              <View>
                <Text style={wasteQualityStyleSheet.stats}>
                  {totalWeight ? toFrenchNumber(totalWeight / 1000, 1) : "-"}{" "}
                  {t("tons")}
                </Text>
                <Text style={wasteQualityStyleSheet.text}>
                  {t("of weighed waste")}
                </Text>
              </View>
              <View>
                <Text style={wasteQualityStyleSheet.stats}>
                  {totalMassRecoverable > 0
                    ? toFrenchNumber(
                        (totalMassRecoverable / totalWasteMass) * 100,
                        1,
                      )
                    : "0,0"}{" "}
                  %
                </Text>
                {i18n.language === "fr" ? (
                  <>
                    <Text style={wasteQualityStyleSheet.text}>de matières</Text>
                    <Text style={wasteQualityStyleSheet.text}>
                      valorisables en masse
                    </Text>
                  </>
                ) : (
                  <>
                    <Text style={wasteQualityStyleSheet.text}>mass of </Text>
                    <Text style={wasteQualityStyleSheet.text}>
                      recoverable materials
                    </Text>
                  </>
                )}
              </View>
              {characterizationOutlet && (
                <View style={{ marginTop: 20 }}>
                  {i18n.language === "fr" ? (
                    <>
                      <View style={wasteQualityStyleSheet.noticeText}>
                        <Text style={wasteQualityStyleSheet.notice}>*</Text>
                        <Text style={wasteQualityStyleSheet.textSmall}>
                          Pour plus de détails sur les filières,
                        </Text>
                      </View>
                      <Text
                        style={{
                          ...wasteQualityStyleSheet.textSmall,
                          marginLeft: 6,
                        }}
                      >
                        veuillez vous rendre à la fin du document.
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={wasteQualityStyleSheet.textSmall}>
                        Pour plus de détails sur les filières,
                      </Text>
                      <Text style={wasteQualityStyleSheet.textSmall}>
                        veuillez vous rendre à la fin du document.
                      </Text>
                    </>
                  )}
                </View>
              )}
            </View>
            <View style={wasteQualityStyleSheet.image}>
              {chartImageUrl && <Image src={chartImageUrl} />}
            </View>
          </View>
        </View>
        <WasteQualityFooter page={3} />
      </Page>
      <Page size="A4">
        <HeaderWasteQualityPDF
          caracDatetime={caracDatetime}
          corporationID={corporationID}
        />
        <View style={{ height: "79%" }}>
          <View style={wasteQualityStyleSheet.volumeContent}>
            <View style={{ flex: 3, marginTop: 40 }}>
              {i18n.language === "fr" ? (
                <>
                  <Text style={wasteQualityStyleSheet.ChartTitle}>
                    Détail analyse
                  </Text>
                  <Text style={wasteQualityStyleSheet.ChartTitle}>
                    volumique
                  </Text>
                </>
              ) : (
                <>
                  <Text style={wasteQualityStyleSheet.ChartTitle}>Volume</Text>
                  <Text style={wasteQualityStyleSheet.ChartTitle}>
                    analysis
                  </Text>
                  <Text style={wasteQualityStyleSheet.ChartTitle}>details</Text>
                </>
              )}
              <View>
                <Text style={wasteQualityStyleSheet.stats}>
                  {totalVolumeRecoverable > 0
                    ? toFrenchNumber(
                        (totalVolumeRecoverable / totalWasteVolume) * 100,
                        1,
                      )
                    : "0,0"}{" "}
                  %
                </Text>
                {i18n.language === "fr" ? (
                  <>
                    <Text style={wasteQualityStyleSheet.text}>de matières</Text>
                    <Text style={wasteQualityStyleSheet.text}>
                      valorisables en volume
                    </Text>
                  </>
                ) : (
                  <>
                    <Text style={wasteQualityStyleSheet.text}>volume of </Text>
                    <Text style={wasteQualityStyleSheet.text}>
                      recoverable materials
                    </Text>
                  </>
                )}
              </View>
            </View>
            <View style={{ ...wasteQualityStyleSheet.image, marginTop: 40 }}>
              {volumeImgData && <Image src={volumeImgData} />}
            </View>
          </View>
          <View style={wasteQualityStyleSheet.materialContent}>
            <View style={{ flex: 3, marginTop: 50 }}>
              <View>
                <Text style={wasteQualityStyleSheet.ChartTitle}>
                  {t("Details by")}
                </Text>
                <Text style={wasteQualityStyleSheet.ChartTitle}>
                  {t("number")}
                </Text>
                <Text style={wasteQualityStyleSheet.ChartTitle}>
                  {t("of objects")}
                </Text>
              </View>
            </View>
            <View style={wasteQualityStyleSheet.materialChart}>
              {materialImageUrl && <Image src={materialImageUrl} />}
            </View>
          </View>
        </View>
        <WasteQualityFooter page={2} />
      </Page>
      {!superContainer
        ? temporalDataArray.map((item, index) => (
            <WasteQualityImageComponent
              key={item._id}
              data={item}
              page={index + 4}
              caracDatetime={caracDatetime}
              corporationID={corporationID}
              container={container}
            />
          ))
        : temporalDataArray[0].map((item, index) => (
            <>
              <WasteQualityImageComponent
                key={item._id}
                data={item}
                page={index + 4}
                caracDatetime={caracDatetime}
                corporationID={corporationID}
                container={container}
                superContainer={superContainer}
              />
              {temporalDataArray[1].filter((i) => i._time === item._time)
                .length > 0 && (
                <WasteQualityImageComponent
                  key={item._id}
                  data={
                    temporalDataArray[1].filter(
                      (i) => i._time === item._time,
                    )[0]
                  }
                  page={index + 4}
                  caracDatetime={caracDatetime}
                  corporationID={corporationID}
                  container={container}
                  superContainer={superContainer}
                />
              )}
            </>
          ))}
      {characterizationOutlet && (
        <Page size="A4">
          <HeaderWasteQualityPDF
            caracDatetime={caracDatetime}
            corporationID={corporationID}
          />
          <View style={{ height: "79%" }}>
            <View style={wasteQualityStyleSheet.volumeContent}>
              <View style={{ flex: 3, marginTop: 40 }}>
                {i18n.language === "fr" ? (
                  <>
                    <Text style={wasteQualityStyleSheet.ChartTitle}>
                      Détail par
                    </Text>
                    <Text style={wasteQualityStyleSheet.ChartTitle}>
                      filière
                    </Text>
                  </>
                ) : (
                  <>
                    <Text style={wasteQualityStyleSheet.ChartTitle}>
                      Détail par
                    </Text>
                    <Text style={wasteQualityStyleSheet.ChartTitle}>
                      filière
                    </Text>
                  </>
                )}
                <View>
                  <Text style={wasteQualityStyleSheet.stats}>
                    {totalMassInOutlet > 0
                      ? toFrenchNumber(
                          (totalMassInOutlet / totalWasteMass) * 100,
                          1,
                        )
                      : "0,0"}{" "}
                    %
                  </Text>
                  {i18n.language === "en" ? (
                    <>
                      <Text style={wasteQualityStyleSheet.text}>
                        de matières
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          fontFamily: "Mulish",
                          marginTop: 3,
                        }}
                      >
                        avec filière
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={wasteQualityStyleSheet.text}>
                        de matières
                      </Text>
                      <Text style={wasteQualityStyleSheet.text}>
                        avec filière
                      </Text>
                    </>
                  )}
                </View>
                <View>
                  <Text style={wasteQualityStyleSheet.stats}>
                    {totalMassNotInOutlet > 0
                      ? toFrenchNumber(
                          (totalMassNotInOutlet / totalWasteMass) * 100,
                          1,
                        )
                      : "0,0"}{" "}
                    %
                  </Text>
                  {i18n.language === "en" ? (
                    <>
                      <Text style={wasteQualityStyleSheet.text}>
                        de matières
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          fontFamily: "Mulish",
                          marginTop: 3,
                        }}
                      >
                        sans filière
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={wasteQualityStyleSheet.text}>
                        de matières
                      </Text>
                      <Text style={wasteQualityStyleSheet.text}>
                        sans filière
                      </Text>
                    </>
                  )}
                </View>
              </View>
              <View style={{ ...wasteQualityStyleSheet.image, marginTop: 40 }}>
                {tableOutletImgData && (
                  <Image
                    style={{ width: "400px", height: "500px" }}
                    src={tableOutletImgData}
                  />
                )}
              </View>
            </View>
            <View style={wasteQualityStyleSheet.materialContent}>
              <View style={{ flex: 3, marginTop: 50 }}>
                <View>
                  <Text style={wasteQualityStyleSheet.ChartTitle}>
                    Répartition par
                  </Text>
                  <Text style={wasteQualityStyleSheet.ChartTitle}>
                    exutoire
                  </Text>
                </View>
              </View>
              <View style={wasteQualityStyleSheet.materialChart}>
                {outletImgData && <Image src={outletImgData} />}
              </View>
            </View>
          </View>
          <WasteQualityFooter page={5} />
        </Page>
      )}
    </Document>
  );
};

export default WasteQualityPDF;
