import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  status: "idle",
  error: null,
};

export const containerSlice = createSlice({
  name: "containers",
  initialState,
  reducers: {
    toggleContainer: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { toggleContainer } = containerSlice.actions;
