import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Input, Label } from "reactstrap";
import { formattedDataChart } from "../../../../components/formatted/FormattedChart";
import {
  formattedDate,
  formattedTime,
  getEndOfMonth,
  getMonthsAgo,
  getStartOfMonth,
} from "../../../../components/formatted/FormattedDateAndTime";
import {
  formattedColorMaterial,
  formattedMaterial,
} from "../../../../components/formatted/FormattedText";
import Loading from "../../../../components/utils/Loading";
import DoughnutAndPieChart from "../../../charts/DoughnutAndPieChart";
import LineAndBarChart from "../../../charts/LineAndBarChart";
import {
  currentMonthName,
  previousMonthName,
  capitalizeFirstLetter,
} from "../../../../components/utils/Date";
import CalendarComponent from "../../../../components/CalendarComponent";
import { sortObjectbyKeys } from "../../../../components/utils/Object";
import {
  countAnomaliesData,
  getHistoryQuality,
} from "../../../../services/container";
import { useTranslation } from "react-i18next";

function mergeQualityObjectKeys(initialObj, mergeConfig) {
  let newObj = {};

  mergeConfig.forEach(({ referenceName, namesToMerge }) => {
    let sum = 0;
    namesToMerge.forEach((name) => {
      sum += initialObj[name] || 0;
      delete initialObj[name];
    });
    if (sum > 0) {
      newObj[referenceName] = sum;
    }
  });
  return { ...initialObj, ...newObj };
}

const ContainerViewQuality = ({ data }) => {
  const { t, i18n } = useTranslation();
  const [anomalyDelivery, setAnomalyDelivery] = useState({});
  const [anomalyTotal, setAnomalyTotal] = useState([]);
  const [customDate, setCustomDate] = useState(false);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [historyAnomaly, setHistoryAnomaly] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lastAnomaly, setLastAnomaly] = useState([]);
  const [periodQualityStart, setPeriodQualityStart] = useState(
    getStartOfMonth(new Date()).toISOString(),
  );
  const [periodQualityStop, setPeriodQualityStop] = useState(new Date());
  const [periodQuality, setPeriodQuality] = useState("0");
  let dataAnomalyDelivery = [];
  let labelsAnomalyDelivery = [];
  let colorAnomalyDelivery = [];

  const fetchData = useCallback(() => {
    axios
      .all([
        getHistoryQuality(
          {
            periodQualityStart: periodQualityStart,
            periodQualityStop: periodQualityStop,
            customDate: periodQualityStop ? true : false,
          },
          data._id,
        ),
        countAnomaliesData({
          periodStart: periodQualityStart,
          periodStop: periodQualityStop,
          keywordFilter: [],
          customDate: periodQualityStop ? true : false,
          containerIds: [data._id],
        }),
      ])
      .then(
        axios.spread((response, responseAnomalyCount) => {
          if (response.status === 200 && responseAnomalyCount.status === 200) {
            //Response history anomaly
            setLastAnomaly(response.data.lastAnomaly[0]);
            setHistoryAnomaly(response.data.historyAnomaly);

            const mergeConfig = JSON.parse(
              sessionStorage.getItem("materialsDescription"),
            ).map((v) => ({
              referenceName: v.referenceName,
              namesToMerge: v.possibleNames,
            }));

            const qualityObject = mergeQualityObjectKeys(
              response.data.anomalyDelivery,
              mergeConfig,
            );
            const sortedQualityObject = sortObjectbyKeys(qualityObject);
            setAnomalyDelivery(sortedQualityObject);

            setAnomalyTotal(responseAnomalyCount.data.countAnomalies);
            setIsLoading(false);
          }
        }),
      );
  }, [
    data,
    periodQualityStart,
    periodQualityStop,
    setAnomalyDelivery,
    setAnomalyTotal,
    setHistoryAnomaly,
    setLastAnomaly,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const selectPeriodFilter = (value) => {
    setPeriodQuality(value);
    setDisplayCalendar(value === "calendar");
    setCustomDate(value === "calendar");
    setHistoryAnomaly([]);
    const now = new Date();

    switch (value) {
      case "0": {
        const startOfMonth = getStartOfMonth(now);
        setPeriodQualityStart(startOfMonth.toISOString());
        setPeriodQualityStop(now.toISOString());
        break;
      }
      case "1": {
        const startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1),
        );
        const endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1),
        );

        setPeriodQualityStart(startOfLastMonth.toISOString());
        setPeriodQualityStop(endOfLastMonth.toISOString());
        break;
      }
      case "-3mo":
        setPeriodQualityStart(getMonthsAgo(now, 3).toISOString());
        setPeriodQualityStop(now.toISOString());
        break;

      case "-6mo":
        setPeriodQualityStart(getMonthsAgo(now, 6).toISOString());
        setPeriodQualityStop(now.toISOString());
        break;

      case "-12mo":
        setPeriodQualityStart(getMonthsAgo(now, 12).toISOString());
        setPeriodQualityStop(now.toISOString());
        break;

      default:
        break;
    }
  };

  const onChangeCalendar = (event) => {
    setPeriodQualityStop(new Date(event[1]).toISOString());
    setPeriodQualityStart(new Date(event[0]).toISOString());

    setDisplayCalendar(false);
  };

  const dataHistoryQuality = formattedDataChart(
    historyAnomaly,
    "_time",
    "anomalies",
  );
  const labelsHistoryQuality = [...historyAnomaly.map((hist) => hist._time)];

  if (anomalyTotal) {
    dataAnomalyDelivery = Object.values(anomalyDelivery);

    for (const [key] of Object.entries(anomalyDelivery)) {
      labelsAnomalyDelivery.push(formattedMaterial(key, i18n.language));
      colorAnomalyDelivery.push(formattedColorMaterial(key));
    }
  } else {
    dataAnomalyDelivery.push(100);
    colorAnomalyDelivery.push("#9095a469");
    labelsAnomalyDelivery.push("Aucune anomalie");
  }

  return isLoading ? (
    <Loading color="#efefeffb" />
  ) : (
    <div className="modal-nav-item">
      <section className="flex flex-end">
        {customDate ? (
          /* Calendar */
          <CalendarComponent
            classNameBase="relative flex mx-2"
            onChangeCalendar={onChangeCalendar}
            displayCalendar={displayCalendar}
            setDisplayCalendar={setDisplayCalendar}
            periodHistoryStart={periodQualityStart}
            periodHistoryStop={periodQualityStop}
          />
        ) : null}

        <article className="flex">
          <Label className="mx-1 my-auto">{t("Period")}:</Label>
          <Input
            type="select"
            name="select-graphe"
            onChange={(event) => selectPeriodFilter(event.target.value)}
          >
            <option value="0">
              {t("Current month")} : {capitalizeFirstLetter(currentMonthName())}
            </option>
            <option value="1">
              {t("Last month")} : {capitalizeFirstLetter(previousMonthName())}
            </option>
            <option value="-3mo">{t("Last 3 months")}</option>
            <option value="-6mo">{t("Last 6 months")}</option>
            <option value="-12mo">{t("Last 12 month")}</option>
            <option value="calendar">{t("Custom period")}</option>
          </Input>
        </article>
      </section>

      <section className="modal-item-container">
        <article className="modal-item-container-1-content-4 modal-item-container-content">
          <div className="modal-item-container-title-column">
            <h5 className="data-bold modal-item-container-quality-current">
              {t("Number of anomalies")}
              {periodQuality === "0" ? (
                <span> | {t("Current month")}</span>
              ) : null}
              {periodQuality === "1" ? <span> | {t("Last month")}</span> : null}
              {periodQuality === "-3mo" ? (
                <span> | {t("Last 3 months")}</span>
              ) : null}
              {periodQuality === "-6mo" ? (
                <span> | {t("Last 6 months")}</span>
              ) : null}
              {periodQuality === "-12mo" ? (
                <span> | {t("Last 12 months")}</span>
              ) : null}
              {customDate ? (
                <span>
                  {" "}
                  | {i18n.language === "fr" && "Du"}{" "}
                  {formattedDate(periodQualityStart, i18n.language)} {t("to")}{" "}
                  {formattedDate(periodQualityStop, i18n.language)}
                </span>
              ) : null}
            </h5>
            <article className="modal-item-key-value">
              <article>
                {/* <i className="fas fa-long-arrow-alt-up arrow-positive"></i> */}
                <h5 className="font-size-35">
                  {anomalyTotal ? anomalyTotal : t("No anomalies")}
                </h5>
              </article>
            </article>
            {/* <p>Dernier enlèvement<p className="data-bold">: Bientôt</p>​</p> */}
          </div>

          <div className="modal-item-container-title">
            <h5 className="data-bold modal-item-container-quality-current">
              {t("Distribution of anomalies")}
              {periodQuality === "0" ? (
                <span> | {t("Current month")}</span>
              ) : null}
              {periodQuality === "1" ? <span> | {"Last month"}</span> : null}
              {periodQuality === "-3mo" ? (
                <span> | {t("Last 3 months")}</span>
              ) : null}
              {periodQuality === "-6mo" ? (
                <span> | {t("Last 6 months")}</span>
              ) : null}
              {periodQuality === "-12mo" ? (
                <span> | {t("Last 12 months")}</span>
              ) : null}
              {customDate ? (
                <span>
                  {" "}
                  | {i18n.language === "fr" && "Du"}{" "}
                  {formattedDate(periodQualityStart, i18n.language)} {t("to")}{" "}
                  {formattedDate(periodQualityStop, i18n.language)}
                </span>
              ) : null}
              ​
            </h5>
          </div>

          <div className="modal-item-chart modal-item-chart-pie">
            <DoughnutAndPieChart
              countTotal={anomalyTotal ? anomalyTotal : 0}
              colorChart={colorAnomalyDelivery}
              dataChart={dataAnomalyDelivery}
              diameterCutout={0}
              displayLegend={true}
              labelChart={labelsAnomalyDelivery}
              legendPosition="bottom"
              subtextCenterPlugin=""
              textCenterPlugin=""
              titleTooltip={t("Number of anomalies")}
              type="pie"
              activePluginChartLabel={false}
            />
          </div>
        </article>

        <article className="modal-item-container-3-content-4 modal-item-container-content">
          <div className="modal-item-container-title-column">
            <h5 className="data-bold">
              {t("Anomalies")} {t("per month")}​
            </h5>
            <div>
              <p>{t("Most recent anomaly")} : &nbsp;</p>
              <p className="data-bold">
                {lastAnomaly && lastAnomaly.lastAnomalyDelivery
                  ? `${lastAnomaly.lastAnomalyDelivery.map((type, index) => {
                      return " " + formattedMaterial(type, i18n.language);
                    })} | ${formattedDate(lastAnomaly._time, i18n.language)} ${i18n.language === "en" ? "at" : "à"} ${formattedTime(lastAnomaly._time)}`
                  : t("No anomalies")}
              </p>
            </div>
          </div>

          <div className="modal-item-chart">
            <LineAndBarChart
              dataChart={dataHistoryQuality[0]}
              type="bar"
              labelsChart={labelsHistoryQuality}
              xAxisType="time"
              unitValueChart=""
              maxYAxis={undefined}
              stepYAxis={undefined}
              messageLabelTooltip={t("Number of anomalies")}
            />
          </div>
        </article>
      </section>
    </div>
  );
};

export default ContainerViewQuality;
