export default function CharacterisationSvg({
  width,
  height,
  widthViewBox,
  heightViewBox,
}) {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${widthViewBox} ${heightViewBox}`}
    >
      <g>
        <g id="svg_1" stroke="null">
          <rect
            strokeWidth="2"
            rx="1"
            id="svg_13"
            height="4.63716"
            width="41.99179"
            y="31.37569"
            x="196.12976"
            fill="#bfbfbf"
            transform="matrix(0.476884 -0.00692919 0.00762357 0.43345 -92.7702 -8.93276)"
            stroke="#bfbfbf"
          />
          <path
            strokeWidth="2"
            fill="none"
            opacity="undefined"
            d="m4.33906,1.85039c-0.0064,-0.36406 0.31018,-0.67467 0.69333,-0.68023l11.70487,-0.17007c0.38314,-0.00557 0.71049,0.29568 0.71689,0.65974l0.02357,1.33998c0.0064,0.36406 -0.31018,0.67467 -0.69333,0.68023l-11.70487,0.17007c-0.38314,0.00557 -0.71049,-0.29568 -0.71689,-0.65974l-0.02357,-1.33998z"
            id="svg_14"
            stroke="#bfbfbf"
          />
          <path
            strokeWidth="2"
            fill="none"
            opacity="undefined"
            d="m3.6368,4.85128l14.94839,-0.2172l0.36295,20.63576l-14.94839,0.2172l-0.36295,-20.63576z"
            id="svg_9"
            stroke="#bfbfbf"
          />
          <path
            fill="#00a7a6"
            opacity="undefined"
            d="m4.10852,19.64163l14.52532,-0.21106l0.09781,5.56094l-14.52532,0.21106l-0.09781,-5.56094z"
            id="svg_10"
            stroke="#bfbfbf"
          />
          <path
            fill="#0f265c"
            opacity="undefined"
            d="m4.04513,17.44622l14.52532,-0.21106l0.0373,2.12044l-14.52532,0.21106l-0.0373,-2.12044z"
            id="svg_11"
            stroke="#bfbfbf"
          />
          <path
            fill="#00a7a6"
            opacity="undefined"
            d="m3.99019,14.32262l14.52532,-0.21106l0.05322,3.02584l-14.52532,0.21106l-0.05322,-3.02584z"
            id="svg_12"
            stroke="#bfbfbf"
          />
          <path
            fill="#008784"
            opacity="undefined"
            d="m3.93605,11.24428l14.52532,-0.21106l0.05322,3.02584l-14.52532,0.21106l-0.05322,-3.02584z"
            id="svg_15"
            stroke="#bfbfbf"
          />
          <path
            fill="#00a7a6"
            opacity="undefined"
            d="m3.83811,5.67612l14.52532,-0.21106l0.09621,5.47039l-14.52532,0.21106l-0.09621,-5.47039z"
            id="svg_16"
            stroke="#bfbfbf"
          />
        </g>
      </g>
    </svg>
  );
}
