import { useEffect } from "react";
import * as echarts from "echarts";
import { useTranslation } from "react-i18next";

export const OutletPieChart = ({
  dataMaterialChart,
  labelChart,
  materialTotal,
  colorChart,
  outletRef,
  fontSizeLabel,
  totalWeight,
  size,
  padding,
  marginLeft,
  childrenValueFontSize,
  childrenTextFontSize,
  radius,
  space,
  pdf,
}) => {
  const { t } = useTranslation();
  useEffect(
    () => {
      const chartDom = document.getElementById("outlet-chart");
      const materialChart = echarts.init(chartDom);
      const total =
        typeof totalWeight === "number" ? totalWeight * 1000 : materialTotal;
      const data = dataMaterialChart.map((value, index) => ({
        value: ((value * 100) / materialTotal).toFixed(1),
        name: labelChart[index],
        itemStyle: {
          color: colorChart[index],
        },
      }));
      let option;
      option = {
        tooltip: {
          trigger: "item",
        },
        graphic: [
          {
            type: "group",
            left: "center",
            top: "middle",
            children: [
              {
                type: "text",
                style: {
                  text: (total / 1000).toFixed(2),
                  fontSize: childrenValueFontSize,
                  fill: "#E95280",
                  font: 'bolder 46px "Mulish", sans-serif',
                  fontWeight: "bold",
                  textAlign: "center",
                },
              },
              {
                type: "text",
                style: {
                  text: `${t("ton")}${total / 1000 > 1 ? "s" : ""}`,
                  fontSize: childrenTextFontSize,
                  fill: "#E95280",
                  fontWeight: "bold",
                  textAlign: "center",
                  y: space,
                },
              },
            ],
          },
        ],

        series: [
          {
            type: "pie",
            radius: ["20%", radius],
            label: {
              formatter: "{b} ({c}%)",
              position: "outside",
              fontSize: fontSizeLabel,
              fontWeight: 400,
              textAlign: "left",
            },
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      option && materialChart.setOption(option);
    },
    // eslint-disable-next-line
    [totalWeight, pdf],
  );

  return (
    <div
      id="outlet-chart"
      ref={outletRef}
      style={{
        width: size,
        height: size,
        padding: padding,
        marginLeft: marginLeft,
      }}
    />
  );
};
