import { Label, CustomInput, Alert } from "reactstrap";
import Loading from "../../../components/utils/Loading";
import { useEffect, useState } from "react";
import { PdfDocumentViewer } from "../../../components/download/PdfDocumentViewer";
import { Dropdown } from "../../../components/dropdownMenu/Dropdown";
import { formatAzureBlob } from "../../../components/formatted/FormattedAzureSasToken";
import { getAzureBlob, getHistoryActivity } from "../../../services/container";
import ActivityHistoryTimeLineRow from "./ActivityHistoryTimeLineRow";
import { LoadingScreen } from "../../../components/loader/LoadingScreen";

const ActivityHistoryTimeLine = ({
  keywordFilter,
  periodHistoryStart,
  periodHistoryStop,
  superContainers,
}) => {
  const [displayAlert, setDisplayAlert] = useState(false);
  const [disabledDownload, setDisabledDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalDownload, setIsModalDownload] = useState(false);
  const [itemIndex, setItemIndex] = useState(null);
  const [dataHistoryContainer, setDataHistoryContainer] = useState([]);
  const [countDataHistory, setCountDataHistory] = useState(0);
  const [displayAnomaly, setDisplayAnomaly] = useState(false);
  const [dataHistoryExport, setDataHistoryExport] = useState([]);
  const [downloadProgress, setDownloadProgress] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const response = await getHistoryActivity({
        periodStart: periodHistoryStart,
        periodStop: periodHistoryStop,
        displayAnomalie: displayAnomaly,
        keywordFilter: JSON.stringify(keywordFilter.value),
        toDownload: true,
      });
      if (response.status === 200) {
        setDataHistoryContainer(response.data.historyList);
        if (response.data.historyList) {
          setCountDataHistory(response.data.historyList.length);
        }
        setIsLoading(false);
      }
    };

    fetchData();
  }, [displayAnomaly, keywordFilter, periodHistoryStart, periodHistoryStop]);

  useEffect(() => {
    setTimeout(() => {
      if (displayAlert) {
        if (countDataHistory > 50 && countDataHistory < 100) {
          setDisplayAlert(false);
        }
      }
    }, 5000);
  }, [countDataHistory, displayAlert]);

  useEffect(
    () => {
      setDisplayAlert(false);
      setDisabledDownload(false);
    },
    //eslint-disable-next-line
    [periodHistoryStart, periodHistoryStop],
  );

  const closeAlert = () => setDisplayAlert(false);

  const fetchAzureData = async (history) => {
    try {
      const wasteObjects = await getAzureBlob({
        blobUrl: formatAzureBlob(
          history.image_url
            .replace("pictures", "output-surface")
            .replace("-R.jpeg", "-" + history._measurement + "-surface.json")
            .replace("-L.jpeg", "-" + history._measurement + "-surface.json"),
        ),
      });
      if (wasteObjects.status === 200) {
        history.wasteObjectsList = wasteObjects.data.object_segmentations;
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Resource not found (404)");
      } else {
        console.error("Error during the request :", error.message);
      }
    }
    return history;
  };

  const updateProgress = (index, totalLength) => {
    const newProgress = ((index + 1) / totalLength) * 100;
    setProgress(newProgress);
  };

  const exportPDF = async () => {
    setDownloadProgress(true);
    setDisplayAlert(true);

    if (countDataHistory > 100) {
      setDisabledDownload(true);
    } else {
      let completedCount = 0;

      const promises = dataHistoryContainer.map((item, index) =>
        fetchAzureData(item).then((result) => {
          completedCount++;
          updateProgress(completedCount, dataHistoryContainer.length);
          return result;
        }),
      );

      const temporalDataArray = await Promise.all(promises);
      setDataHistoryExport(temporalDataArray);
      setIsModalDownload(true);
    }
    setProgress(0);
    setDownloadProgress(false);
  };

  return (
    <div className="card-block-activity-item">
      <section className="card-block-header-history">
        <section className="relative flex">
          <article className="flex mx-2">
            <Label className="m-auto ">Erreur de tri</Label>
            <CustomInput
              className="my-auto mx-4"
              type="switch"
              id="displayAnomalie"
              defaultChecked={displayAnomaly}
              onClick={() => {
                setDisplayAnomaly(!displayAnomaly);
                setCountDataHistory(0);
              }}
              bsSize="lg"
            />
          </article>
        </section>

        <section className="flex mx-2 my-auto">
          <span className="font-semibold mr-1">
            {isLoading ? "-" : countDataHistory}
          </span>{" "}
          donnée{countDataHistory > 1 ? "s" : ""}
        </section>
      </section>

      <section className="flex flex-end w-full mt-2">
        <div className="flex m-auto">
          {countDataHistory === 0 && !isLoading && (
            <Alert
              className="download-alert m-auto py-1.5 modal-alert-red"
              isOpen={displayAlert}
              toggle={closeAlert}
            >
              Aucune donnée à télécharger.
            </Alert>
          )}

          {countDataHistory > 50 && countDataHistory < 100 && (
            <Alert
              className="download-alert m-auto py-1.5 modal-alert-orange"
              isOpen={displayAlert}
              toggle={closeAlert}
            >
              Attention le nombre d'images risque de ralentir le chargement du
              pdf.
            </Alert>
          )}

          {countDataHistory > 100 && !isLoading && (
            <Alert
              className="download-alert m-auto py-1.5 modal-alert-red"
              isOpen={displayAlert}
              toggle={closeAlert}
            >
              Pour limiter la taille du pdf, le nombre d'images doit être
              inférieur à 100.
            </Alert>
          )}
        </div>

        <Dropdown
          text={"Exporter"}
          disabled={disabledDownload || isLoading}
          children={
            <>
              <span
                onClick={() => exportPDF()}
                className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 cursor-pointer"
              >
                PDF (.pdf)
              </span>
            </>
          }
        />
      </section>

      {isLoading ? (
        <Loading color="#F5F5F5" />
      ) : (
        <div>
          <section className="time-line">
            <article className="column-row time-line-col">
              <h6 className="time-line-title-medium column-title">Date</h6>
              <h6 className="time-line-data-large column-title">Adresse</h6>
              <h6 className="time-line-title-medium column-title">Flux</h6>
              <h6 className="time-line-title-large column-title">Niveau</h6>
              <h6 className="time-line-title column-title">Compactage</h6>
              <h6 className="time-line-title column-title">Anomalies</h6>
              <h6 className="time-line-title-large column-title">Contenant</h6>
              <h6 className="time-line-title column-title"> </h6>
            </article>
            {countDataHistory === 0 ? (
              <div className="no-data">
                Pas de données permettant d'afficher une TimeLine
              </div>
            ) : (
              <>
                <article className="time-line-list">
                  {dataHistoryContainer.map((timeLine, index) => {
                    return (
                      <ActivityHistoryTimeLineRow
                        timeLine={timeLine}
                        index={index}
                        itemIndex={itemIndex}
                        setItemIndex={setItemIndex}
                        superContainers={superContainers}
                      />
                    );
                  })}
                </article>
              </>
            )}
          </section>

          {isModalDownload && !disabledDownload && (
            <PdfDocumentViewer
              countDataHistory={countDataHistory}
              data={dataHistoryExport}
              isModalView={isModalDownload}
              setIsModalView={setIsModalDownload}
              periodStart={periodHistoryStart}
              periodStop={periodHistoryStop}
              setDisplayAlert={setDisplayAlert}
            />
          )}

          {downloadProgress && (
            <LoadingScreen isProgress={true} progress={progress} />
          )}
        </div>
      )}
    </div>
  );
};

export default ActivityHistoryTimeLine;
