import React from "react";
import { Image, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import akanthasLogo from "../../../static/assets/logo_rvg.png";
import wasteQualityLogo from "../../../static/assets/waste_quality_logo.png";
import MulishLight from "../../../fonts/Mulish/Mulish-Light.ttf";
import MulishMedium from "../../../fonts/Mulish/Mulish-Medium.ttf";
import MulishBold from "../../../fonts/Mulish/Mulish-Bold.ttf";
import MulishBlack from "../../../fonts/Mulish/Mulish-Black.ttf";
import ShowClientLogo from "./ClientLogo";
import moment from "moment";
import { useTranslation } from "react-i18next";

// Font registration
Font.register({
  family: "Mulish",
  fonts: [
    { src: MulishLight, fontWeight: 200 },
    { src: MulishMedium, fontWeight: 500 },
    { src: MulishBold, fontWeight: 700 },
    { src: MulishBlack, fontWeight: 900 },
  ],
});

// Stylesheet object
const styles = StyleSheet.create({
  coverSheet: {
    height: "97%",
    top: 0,
    left: 0,
    display: "flex",
    fontFamily: "Mulish",
  },
  logos: {
    marginTop: "9%",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "8%",
    gap: "100px",
  },
  clientLogo: {
    height: "120%",
  },
  akLogo: {
    width: "250px",
  },
  footer: {
    width: "90%",
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: "5%",
    padding: "10px",
    color: "rgb(15, 38, 92)",
  },
  footerInfo: {
    width: "70%",
    paddingLeft: "5%",
  },
  footerInfoTitle: {
    fontSize: 17,
    fontWeight: 900,
  },
  footerInfoText: {
    marginTop: "1%",
    fontSize: 12,
    textSpacing: "1px",
    fontWeight: 500,
  },
  wasteMonitoringLogo: {
    width: "130px",
  },
  logoAndTitle: {
    marginVertical: 3,
    height: "25%",
    backgroundColor: "#f0f0f0",
    flexDirection: "row",
  },
  wasteQualityLogo: {
    width: "18%",
    height: 80,
    justifyContent: "space-between",
    paddingLeft: "25px",
  },
  titleAndDateTime: {
    marginVertical: "auto",
    paddingLeft: "18px",
    width: "80%",
  },
  title: {
    fontSize: 26,
    fontWeight: 900,
    letterSpacing: "1px",
    marginTop: "7%",
    textAlign: "center",
    color: "rgb(15, 38, 92)",
    paddingHorizontal: 20,
  },
  clientInfoTitle: {
    fontSize: 11,
    fontWeight: 600,
    color: "rgb(15, 38, 92)",
    paddingHorizontal: "50px",
    textAlign: "justify",
    marginBottom: 12,
  },
  clientInfo: {
    fontSize: 11,
    fontWeight: 500,
    paddingHorizontal: "50px",
    textAlign: "justify",
    color: "#e81d7a",
    marginBottom: 1,
  },
  titleSmall: {
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: "1px",
    textAlign: "center",
    color: "#e81d7a",
  },
  infoText: {
    fontSize: 12,
    color: "rgb(15, 38, 92)",
    fontWeight: 500,
    paddingHorizontal: "50px",
  },
  disclaimer: {
    fontSize: 14,
    color: "#00A7A6",
    fontWeight: 700,
    textAlign: "center",
    paddingHorizontal: "50px",
  },
});

const CoverWasteQualityPdf = ({
  caracDatetime,
  corporationID,
  clientName,
  container,
  corporationName,
  totalWeight,
  clientCode,
  clientAddress,
  wasteCategoryName,
  wasteCategoryCode,
}) => {
  const { t } = useTranslation();

  function caracDateTimeDuration(datetime) {
    const year = moment(datetime).format("YYYY");
    const month = moment(datetime).format("MM");
    const day = moment(datetime).format("DD");
    return [
      `${day}/${month}/${Number(year)}`,
      `${day}/${month}/${Number(year) + 1}`,
    ];
  }

  return (
    <View style={styles.coverSheet}>
      <View style={styles.logos}>
        <Image style={styles.akLogo} src={akanthasLogo} />
        {ShowClientLogo(corporationID, styles.clientLogo)}
      </View>

      <Text style={styles.title}>{t("My Characterization Report")}</Text>

      <View style={{ marginTop: 1, width: "100%", textAlign: "center" }}>
        <Text style={styles.infoText}>
          {t("Waste Compliance According to Decree No. 2021-1199 of 09/16/21")}
        </Text>
        <Text style={styles.infoText}>
          {t("Validity Period")} : {t("valid until")}{" "}
          {caracDateTimeDuration(caracDatetime)[1]}
        </Text>
      </View>

      <View style={{ marginTop: "8%", width: "100%" }}>
        <Text style={styles.clientInfoTitle}>
          1.{" "}
          <Text style={{ textDecoration: "underline", marginLeft: 5 }}>
            {t("General Information")}
          </Text>
        </Text>
        <Text style={styles.clientInfo}>
          {t("Producer name")} / {t("Owner")} :{" "}
          <Text style={{ color: "rgb(15, 38, 92)" }}>
            {clientName && ![undefined, "undefined"].includes(clientName)
              ? clientName
              : "-"}
          </Text>
        </Text>
        <Text style={styles.clientInfo}>
          Siret :{" "}
          <Text style={{ color: "rgb(15, 38, 92)" }}>
            {clientCode && ![undefined, "undefined"].includes(clientCode)
              ? clientCode
              : "-"}
          </Text>
        </Text>
        <Text style={styles.clientInfo}>
          {t("Address")}, {t("production site")} :{" "}
          <Text style={{ color: "rgb(15, 38, 92)" }}>
            {clientAddress && ![undefined, "undefined"].includes(clientAddress)
              ? clientAddress
              : "-"}
          </Text>
        </Text>
        <Text style={styles.clientInfo}>
          {t("Waste name")} :{" "}
          <Text style={{ color: "rgb(15, 38, 92)" }}>
            {wasteCategoryName ? wasteCategoryName : "Déchets ultimes"}
          </Text>
        </Text>
        <Text style={styles.clientInfo}>
          {t("Waste code")} :{" "}
          <Text style={{ color: "rgb(15, 38, 92)" }}>
            {wasteCategoryCode ? wasteCategoryCode : "20 03 01"}
          </Text>
        </Text>
      </View>

      <View style={{ marginTop: "5%", width: "100%" }}>
        <Text style={styles.clientInfoTitle}>
          2.{" "}
          <Text style={{ textDecoration: "underline", marginLeft: 5 }}>
            {"Reporting emission details"}
          </Text>
        </Text>
        <Text
          style={{
            ...styles.clientInfo,
            color: "rgb(15, 38, 92)",
            marginBottom: 10,
          }}
        >
          {t(
            "This report consolidates the results of AI-assisted characterization of a waste container, carried out at the client's request",
          )}{" "}
          :
        </Text>
        <Text style={styles.clientInfo}>
          {t("Date of completion")} :{" "}
          <Text style={{ color: "rgb(15, 38, 92)" }}>
            {caracDateTimeDuration(caracDatetime)[0]}
          </Text>
        </Text>
        <Text style={styles.clientInfo}>
          {t("Certificate Issuer")} :{" "}
          <Text style={{ color: "rgb(15, 38, 92)" }}>
            {container.addressJoin.name}
          </Text>
        </Text>
        <Text style={styles.clientInfo}>
          {t("Site of completion")} :{" "}
          <Text style={{ color: "rgb(15, 38, 92)" }}>
            {container.addressJoin.street} {container.addressJoin.postalCode}{" "}
            {container.addressJoin.city}
          </Text>
        </Text>
        <Text style={styles.clientInfo}>
          {t("Net weight of the shipment")} / {t("Characterized sample")} :{" "}
          <Text style={{ color: "rgb(15, 38, 92)" }}>
            {totalWeight > 0 && `${totalWeight / 1000}`} {t("tons")}
          </Text>
        </Text>
      </View>
      <View style={{ marginTop: "5%" }}>
        <Text style={styles.clientInfoTitle}>
          3.{" "}
          <Text style={{ textDecoration: "underline", marginLeft: 5 }}>
            {t("Sampling method")}
          </Text>
        </Text>
        <View style={styles.footer}>
          <View>
            <Image style={styles.wasteMonitoringLogo} src={wasteQualityLogo} />
          </View>
          <View style={styles.footerInfo}>
            <Text style={styles.footerInfoText}>
              {t(
                "The sample was characterized on-site in accordance with legal standards using the Waste Quality automated characterization system, employing optical sensors and Akanthas’ artificial intelligence models to assess and classify the waste",
              )}
              .
            </Text>
          </View>
        </View>
      </View>

      <View style={{ marginTop: "10%", width: "100%" }}>
        <Text style={styles.disclaimer}>
          {t("AI for optimized management of professional waste")}
        </Text>
      </View>
    </View>
  );
};

export default CoverWasteQualityPdf;
