const AddressHeader = ({ classColumnTable }) => {
  return (
    <article className={classColumnTable}>
      <h6 className="column-title ml-2.5">Nom</h6>
      <h6 className="column-title">Rôle</h6>
      <h6 className="column-title column-address">Adresse</h6>
      <h6 className="column-title">Latitude</h6>
      <h6 className="column-title">Longitude</h6>
    </article>
  );
};

export default AddressHeader;
