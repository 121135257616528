import { Image, Page, Text, View } from "@react-pdf/renderer";
import { ActivityPdfStyleSheet } from "../styles/ActivityPdfStyleSheet";
import logo from "../../../static/assets/logo_rvg.jpg";
import { formattedDateDocument } from "../../formatted/FormattedDateAndTime";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { CoverPageStyleSheet } from "../styles/CoverPageStyleSheet";

export const CoverPageComponent = ({ periodStart, periodStop }) => {
  const [userData, setUserData] = useState({});
  const [corporationName, setCorporationName] = useState(null);

  const createdDate = new Date();

  useEffect(() => {
    async function fetchData() {
      const API = process.env.REACT_APP_API_BACKEND;
      const token = Cookies.get("userToken");
      const stored = localStorage.getItem("user");
      setUserData(JSON.parse(stored));

      await axios
        .get(`${API}corporation/account`, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          setCorporationName(response.data.corporation.corporationName);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    fetchData();
  }, []);

  return (
    <Page size="A4" style={ActivityPdfStyleSheet.page} orientation="landscape">
      <Image src={logo} style={CoverPageStyleSheet.logo} />

      <View style={CoverPageStyleSheet.coverTitle}>
        <Text style={CoverPageStyleSheet.title}>
          MES HISTORIQUES : ACTIVITE
        </Text>
        <Text style={CoverPageStyleSheet.secondTitle}>
          Periode: {formattedDateDocument(periodStart)} au{" "}
          {formattedDateDocument(periodStop)}
        </Text>
      </View>

      <View style={CoverPageStyleSheet.coverTitle}>
        <Text style={CoverPageStyleSheet.thirdTitle}>
          Entreprise: {corporationName}
        </Text>
        <Text style={CoverPageStyleSheet.thirdTitle}>
          Date d'édition: {formattedDateDocument(createdDate)}
        </Text>
        <Text style={CoverPageStyleSheet.thirdTitle}>
          Utilisateur: {userData.firstName} {userData.lastName}
        </Text>
      </View>
    </Page>
  );
};
