import React from "react";
import { Document } from "@react-pdf/renderer";
import { ActivityPageComponent } from "./componentDownload/ActivityPageComponent";
import { CoverPageComponent } from "./componentDownload/CoverPageComponent";
import "./styles/Font";

export const ActivityPdfDocument = ({ data, periodStart, periodStop }) => {
  return (
    <Document
      creator="Akanthas"
      producer="Akanthas"
      style={{ fontFamily: "Open Sans" }}
    >
      <CoverPageComponent periodStart={periodStart} periodStop={periodStop} />

      {data.map((item) => {
        return (
          <ActivityPageComponent data={item} key={`page-data-${item._id}`} />
        );
      })}
    </Document>
  );
};
