import { Multiselect } from "multiselect-react-dropdown";
import { useTranslation } from "react-i18next";

export const ImageCardDropdownMaterial = ({
  materialList,
  materialSelectRef,
  materialsToDisplay,
  setMaterialsToDisplay,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="dropdown-materials">
      <Multiselect
        options={materialList}
        ref={materialSelectRef}
        placeholder={`${
          materialsToDisplay.length === 0
            ? t("Select the materials to display")
            : i18n.language === "fr"
              ? `${materialsToDisplay.length} matière${materialsToDisplay.length > 1 ? "s" : ""} sélectionnée${materialsToDisplay.length > 1 ? "s" : ""}`
              : `${materialsToDisplay.length} material${materialsToDisplay.length > 1 ? "s" : ""} selected`
        }`}
        displayValue="nameAndCount"
        onSelect={(selectedList) =>
          setMaterialsToDisplay(
            selectedList.map((item) => item.value.toLowerCase()),
          )
        }
        onRemove={(selectedList) =>
          setMaterialsToDisplay(
            selectedList.map((item) => item.value.toLowerCase()),
          )
        }
        showArrow={true}
        hidePlaceholder={false} //Keep placeholder visible when selected
        showCheckbox={true}
        closeOnSelect={false}
      />
    </div>
  );
};
