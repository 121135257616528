import {
  Chart as ChartJS,
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  TimeScale,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  TimeScale,
  Filler,
);
const HorizontalBarChart = ({
  dataChart,
  labelsChart,
  unitValueChart,
  messageLabelTooltip,
  fontSizeLabel,
}) => {
  return (
    <Line
      type="bar"
      data={{
        labels: labelsChart,
        datasets: [
          {
            label: "Cycle",
            type: "bar",
            data: dataChart,
            tension: 0.1,
            backgroundColor: "rgba(75,192,192,0.6)",
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 2,
            fill: true,
          },
        ],
      }}
      options={{
        indexAxis: "y",
        maintainAspectRatio: false,
        scales: {
          x: {
            max: 100,
            stacked: true,
            ticks: {
              display: true,
              autoSkip: true,
              maxTicksLimit: 10,
              maxRotation: 0,
              source: "data",
              major: {
                enabled: true,
              },
              callback: function (value, index, values) {
                return value + " " + unitValueChart;
              },
              font: (context) => {
                const boldedTiks =
                  context.tick && context.tick.major ? "bold" : "";
                return {
                  weight: boldedTiks,
                  size: 14,
                };
              },
            },
          },
          y: {
            beginAtZero: true,
            min: 0,
            stacked: true,
            ticks: {
              autoSkip: false,
              callback: function (value, index, values) {
                return labelsChart[value];
              },
              font: {
                size: 14,
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            backgroundColor: "#f8AA00",
            titleAlign: "center",
            callbacks: {
              title: function (context) {
                let labelCat = context[0].label;
                return labelCat;
              },
              label: function (context) {
                let messageCat = `${context.formattedValue}${unitValueChart}`;
                return messageCat;
              },
            },
          },
          datalabels: {
            color: "#696969",
            anchor: "end",
            clamp: false,
            align: function (context) {
              const value = context.dataset.data[context.dataIndex] || 0;
              if (value > 0 && value < 85) {
                return "end";
              } else {
                return "start";
              }
            },
            font: {
              weight: "bold",
              size: 14,
            },
            formatter: (value, ctx) => {
              return `${value.toFixed(1)}%`;
            },
            textAlign: "center",
            display: function (context) {
              return context.dataset.data[context.dataIndex].toFixed(1) > 0;
            },
          },
        },
      }}
      plugins={[ChartDataLabels]}
    />
  );
};

export default HorizontalBarChart;
