import { StyleSheet } from "@react-pdf/renderer";

export const TitleAndLegendStyleSheet = StyleSheet.create({
  contentValue: {
    padding: 4,
    minHeight: "70px",
    width: "100%",
  },

  title: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: 5,
  },

  secondTitle: {
    fontSize: 15,
    fontWeight: 900,
    marginBottom: 10,
  },

  lineValue: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "2px",
    marginBottom: "2px",
  },
});
