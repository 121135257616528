import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { getContainerClient } from "../../../../services/container";
import { useTranslation } from "react-i18next";

const ContainerModalClient = ({
  data,
  displayClient,
  setDisplayClient,
  weightRef,
  newClient,
  setNewClient,
  handleSelectionChange,
  setClientCode,
  clientName,
  setClientName,
  setClientAddress,
  weight,
  setWeight,
  wasteCategoryName,
  setWasteCategoryName,
  wasteCategory,
  saveWeight,
  totalWeight,
  superContainer,
  isCharacterization,
  refreshAction,
}) => {
  const { t } = useTranslation();
  const [clientList, setClientList] = useState([]);

  const fetchContainerClient = async (id) => {
    getContainerClient(id).then((response) => {
      if (response?.data) {
        const newClientList = response.data.reduce(
          (acc, item) => {
            const key = `${item.name}-${item.address}`;
            if (!acc.keys.has(key)) {
              acc.keys.add(key);
              acc.items.push(item);
            }
            return acc;
          },
          { keys: new Set(), items: [] },
        ).items;
        setClientList(newClientList);
      }
      return null;
    });
  };

  useEffect(
    () => {
      fetchContainerClient(
        superContainer && superContainer._id !== undefined
          ? superContainer._id
          : data._id,
      );
    },
    // eslint-disable-next-line
    [data, displayClient],
  );

  return (
    <Modal ref={weightRef} className="w-1/3" isOpen={displayClient}>
      <ModalHeader>
        <span className="text-blue">
          {newClient
            ? "Client"
            : isCharacterization
              ? t("Characterization")
              : ""}
        </span>
        <CloseIcon
          style={{
            color: "var(--gray)",
            cursor: "pointer",
          }}
          onClick={() => {
            if (!isCharacterization) {
              refreshAction();
            }
            setDisplayClient(false);
          }}
        />
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col w-full">
          {!newClient ? (
            <div className="flex flex-col">
              <div className="w-full">
                <span className="text-blue">Client</span>
                <select
                  id="client"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mr-2"
                  onChange={(event) => handleSelectionChange(event)}
                >
                  {clientList.map((item) =>
                    clientName === item.name ? (
                      <option
                        selected
                        key={item.id}
                        value={JSON.stringify(item)}
                      >
                        {item.name}{" "}
                        {`(${item?.address && item?.address !== " " && ![undefined, "undefined"].includes(item.address) ? item?.address : "-"})`}
                      </option>
                    ) : (
                      <option key={item.id} value={JSON.stringify(item)}>
                        {item.name}{" "}
                        {`(${item?.address && item?.address !== " " && ![undefined, "undefined"].includes(item.address) ? item?.address : "-"})`}
                      </option>
                    ),
                  )}
                </select>
              </div>
              <p
                class="text-center italic text-[#00A7A6] hover:text-gray-500 hover:underline cursor-pointer mt-2"
                onClick={() => setNewClient(true)}
              >
                {t("Add a new client")} ?
              </p>
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="mb-2">
                <span className="text-blue">{t("Client name")}</span>
                <input
                  onChange={(event) => setClientName(event.target.value)}
                  type="text"
                  id="weight"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div className="mb-2">
                <span className="text-blue">SIRET</span>
                <input
                  onChange={(event) => setClientCode(event.target.value)}
                  type="text"
                  id="code"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div className="mb-2">
                <span className="text-blue">{t("Address")}</span>
                <input
                  onChange={(event) => setClientAddress(event.target.value)}
                  type="text"
                  id="weight"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
            </div>
          )}
          {!newClient && (
            <>
              <div className="flex flex-col mb-3">
                <div className="w-full">
                  <span className="text-blue">
                    {t("Weight")} {`(${t("tons")})`}
                  </span>
                  <input
                    onChange={(event) => setWeight(event.target.value)}
                    value={weight}
                    type="text"
                    id="weight"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col mb-3">
                <div className="w-full">
                  <span className="text-blue">{t("Waste name")}</span>
                  <select
                    id="client"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 mr-2"
                    onChange={(event) => {
                      setWasteCategoryName(event.target.value);
                    }}
                  >
                    <option value="" className="text-gray-300">
                      ---- {t("Please select")} ----
                    </option>
                    {wasteCategory.map((item) =>
                      wasteCategoryName !== "" &&
                      JSON.parse(wasteCategoryName).wasteCategoryName ===
                        item.label ? (
                        <option
                          selected
                          key={item._id}
                          value={JSON.stringify({
                            id: item._id,
                            wasteCategoryName: item.label,
                            wasteCategoryCode: item.wasteCodeFull,
                          })}
                        >
                          {item.label}
                        </option>
                      ) : (
                        <option
                          key={item.id}
                          value={JSON.stringify({
                            wasteCategoryName: item.label,
                            wasteCategoryCode: item.wasteCodeFull,
                          })}
                        >
                          {item.label}
                        </option>
                      ),
                    )}
                  </select>
                  <Link
                    to={{
                      pathname: "/waste",
                    }}
                    target="_blank"
                    class="text-center italic text-[#00A7A6] hover:text-gray-500 hover:underline cursor-pointer mt-2"
                  >
                    <div>{t("Set up a new waste category")} ?</div>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="w-full text-center justify-center">
          <button
            type="button"
            class="p-2 w-28 text-md font-medium text-white rounded-lg"
            style={{ backgroundColor: "#00A7A6" }}
            onClick={() =>
              saveWeight(
                superContainer && superContainer._id !== undefined
                  ? superContainer._id
                  : data._id,
              )
            }
          >
            {(totalWeight !== "" && !totalWeight) || displayClient
              ? t("Save")
              : t("Update")}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ContainerModalClient;
