import axios from "axios";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../static/assets/logoHeader.png";
import FooterPage from "../../components/Footer.js";
import {
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import { formattedError } from "../../components/formatted/FormattedText";

const ForgotPassword = ({ API }) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [validForm, setValidForm] = useState(false);
  const [errorForm, setErrorForm] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (email) {
        await axios
          .post(`${API}password/forgot`, {
            email: email,
          })
          .then((response) => {
            if (response.status === 200) {
              setValidForm(true);
            }
          });
      }
    } catch (error) {
      setErrorForm(formattedError(error.response.data.error));
    }
  };

  return (
    <div className="login-signin-container">
      <div className="col-left">
        <Modal isOpen={validForm}>
          <ModalHeader>
            <h2>Mot de passe oublié</h2>
            <CloseIcon
              style={{
                color: "var(--gray)",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/login");
              }}
            />
          </ModalHeader>

          <Form
            onSubmit={() => {
              history.push("/login");
            }}
          >
            <ModalBody>
              <p>
                Un e-mail vient d'être envoyé pour modifier votre mot de passe
              </p>
            </ModalBody>
            <ModalFooter className="information-footer-modal">
              <Link to="/login">
                <button className="button-submit" type="submit">
                  Fermer
                </button>
              </Link>
            </ModalFooter>
          </Form>
        </Modal>

        <div className="login-form-container">
          <div className="login-title">
            <h1>Mot de passe oublié</h1>
            <Link to="/login" className="login-option">
              <span>Se connecter</span>
            </Link>
          </div>

          <section className={`error-form ${errorForm ? "display-error" : ""}`}>
            <p>{errorForm}</p>
          </section>

          <Form className="login-form" onSubmit={handleSubmit}>
            <FormGroup className="form-edit-password">
              <Input
                placeholder="Email"
                type="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                required
              />
            </FormGroup>
            <button className="identification-button bg-cyan" type="submit">
              Réinitialiser le mot de passe
            </button>
          </Form>
        </div>
      </div>

      <div className="col-right">
        <div className="signin">
          <img src={Logo} alt="logo" />
        </div>
      </div>
      <FooterPage />
    </div>
  );
};

export default ForgotPassword;
