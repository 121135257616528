import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { PDFViewer } from "@react-pdf/renderer";
import "react-calendar/dist/Calendar.css";
import { ModalTitle } from "react-bootstrap";
import { ActivityPdfStyleSheet } from "./styles/ActivityPdfStyleSheet";
import { ActivityPdfDocument } from "./ActivityPdfDocument";

export const PdfDocumentViewer = ({
  countDataHistory,
  data,
  isModalView,
  setIsModalView,
  periodStart,
  periodStop,
  setDisplayAlert,
}) => {
  const toggle = () => {
    setIsModalView(!isModalView);
    setDisplayAlert(false);
  };

  return (
    <Modal isOpen={isModalView} toggle={toggle}>
      <ModalHeader>
        <ModalTitle className="modal-title-download">
          Téléchargement des données |{" "}
          <span>
            {countDataHistory} image{countDataHistory > 1 ? "s" : null}
          </span>
        </ModalTitle>
        <CloseIcon
          style={{
            color: "var(--gray)",
            cursor: "pointer",
          }}
          onClick={() => {
            toggle();
          }}
        />
      </ModalHeader>

      <ModalBody className="modal-confirmation" isOpen={true}>
        {/* For the moment the best solution to have a complete pdf: you have to wait for the loading of the complete pdf in the viewer */}
        <PDFViewer style={ActivityPdfStyleSheet.viewer}>
          <ActivityPdfDocument
            data={data.sort(
              (a, b) =>
                a._id.localeCompare(b._id) || a._time.localeCompare(b._time),
            )}
            periodStart={periodStart}
            periodStop={periodStop}
          />
        </PDFViewer>
      </ModalBody>
    </Modal>
  );
};
