import React, { useState } from "react";
import DeleteModal from "../../../components/modals/DeleteModal";
import { formattedRole } from "../../../components/formatted/FormattedText";
import { handleOpenModalClick } from "../../../components/modals/EditableTable";
import UserHeader from "./UserHeader";

const UserAccessRow = ({
  data,
  tableData,
  setTableData,
  removeDeletedItem,
  setIsDeleted,
  formData,
  setFormData,
  setModalEdit,
}) => {
  const [trashModal, setTrashModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="table-block">
      <UserHeader classColumnTable={"table-column-block"} />
      <article className="table-line">
        <div className="data-table">
          <span className="">{data.lastName}</span>
        </div>
        <div className="data-table">
          <span className="">{data.firstName}</span>
        </div>
        <div className="data-table">
          <span className="">
            {data.authorizedAddresses && data.authorizedAddresses.length > 0
              ? data.authorizedAddresses.length
              : 0}
          </span>
        </div>
        <div className="data-table">{formattedRole(data.role, data.admin)}</div>
        <div className="data-table data-mail">
          <span className="">
            <i
              className={`fa fa-envelope ${data.mailRecipient ? "fa-envelope-true" : "fa-envelope-false"}`}
            ></i>
            {data.email}
          </span>
        </div>
        <div className="data-table">
          <span className="">
            <i
              className={`fas fa-comment-dots ${data.smsRecipient ? "fa-comment-dots-true" : "fa-comment-dots-false"}`}
            ></i>
            {data.phone}
          </span>
        </div>
        <article className="data-table data-action">
          <i
            className={` button-action ${!isOpen ? "fas fa-ellipsis-v" : "fas fa-times"}`}
            onClick={() => setIsOpen(!isOpen)}
          ></i>
          <div className={`action-choice ${isOpen ? "display-action" : ""}`}>
            <button
              className="action"
              onClick={() => {
                handleOpenModalClick(data, formData, setFormData, setModalEdit);
                setIsOpen(!isOpen);
              }}
            >
              Modifier
            </button>
            <button
              className="action"
              onClick={() => {
                setTrashModal(true);
                setIsOpen(!isOpen);
              }}
            >
              Supprimer
            </button>
          </div>
        </article>

        {trashModal && (
          <DeleteModal
            tableData={tableData}
            setTableData={setTableData}
            id={data._id}
            message1={
              "Voulez vous vraiment supprimer l'utilisateur : \n" +
              data.firstName +
              " ?"
            }
            message2={data.email}
            messageValidation={"L'utilisateur a bien été supprimé"}
            modal={trashModal}
            removeDeletedItem={removeDeletedItem}
            path={"user/delete/"}
            setIsDeleted={setIsDeleted}
            setModal={setTrashModal}
          />
        )}
      </article>
    </section>
  );
};

export default UserAccessRow;
