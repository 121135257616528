import { StyleSheet } from "@react-pdf/renderer";

// Create styles
export const ActivityPdfStyleSheet = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 30,
    display: "flex",
    flexDirection: "column",
    marginHorizontal: "auto",
  },

  dataTable: {
    fontSize: 11,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "stretch",
    flexWrap: "wrap",
    alignItems: "stretch",
    width: "100%",
  },

  viewer: {
    minHeight: "70vh",
    width: "100%",
  },

  lineValue: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "2px",
    marginBottom: "2px",
  },

  contentValue: {
    border: "1px 1px 1px 0px solid #000",
    padding: 4,
    minHeight: "70px",
  },

  dataValue: {
    color: "#3a3b3c",
  },
});
