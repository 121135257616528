import React, { useState } from "react";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const DeleteModal = ({
  setModal,
  modal,
  id,
  message1,
  message2,
  messageValidation,
  path,
  tableData,
  setTableData,
}) => {
  const { t } = useTranslation();
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");

  const [deleteMessage, setDeleteMessage] = useState("");
  const [errorConfirmation, setErrorConfirmation] = useState(false);

  const deleteElement = async (e) => {
    e.preventDefault();
    try {
      if (deleteMessage === message2) {
        const response = await axios.delete(`${API}${path}${id}`, {
          headers: {
            authorization: "Bearer " + token,
          },
        });

        if (response.status === 200) {
          const newData = [...tableData];
          const index = tableData.findIndex((elem) => elem._id === id);
          newData.splice(index, 1);
          setTableData(newData);
          toast.success(messageValidation, { className: "custom-toast" });
          setModal(false);
        }
      } else {
        setErrorConfirmation(true);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <Modal isOpen={modal}>
      <ModalHeader>
        {t("Deletion")}
        <CloseIcon
          style={{
            color: "var(--gray)",
            cursor: "pointer",
          }}
          onClick={() => {
            setModal(false);
          }}
        />
      </ModalHeader>
      <Form
        onSubmit={(event) => {
          deleteElement(event);
        }}
      >
        <section>
          <ModalBody>
            <section
              className={`error-form ${errorConfirmation ? "display-error" : ""}`}
            >
              <p>{t("The entered value is not correct")}</p>
            </section>

            <article className="informahazation-bloc">
              <p>{message1}</p>
              <FormGroup className="information-line">
                <Label className="information-label">
                  {t("Please enter the following to confirm")} : {message2}
                </Label>
                <Input
                  className="information-column-data"
                  type="text"
                  placeholder={message2}
                  name="delete-message"
                  onChange={(event) => setDeleteMessage(event.target.value)}
                  required
                />
              </FormGroup>
            </article>
          </ModalBody>
          <ModalFooter>
            <Button type="submit"> {t("Validate")} </Button>
            <Button
              onClick={() => {
                setModal(false);
              }}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </section>
      </Form>
    </Modal>
  );
};

export default DeleteModal;
