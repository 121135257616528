const WasteHeader = ({ classColumnTable }) => {
  return (
    <section className={classColumnTable}>
      <h6 className="w-1/6 font-semibold text-sm my-auto">Flux</h6>
      <h6 className="w-1/6 font-semibold text-sm my-auto">Code Déchet</h6>
      <h6 className="w-1/6 font-semibold text-sm my-auto mr-4">
        Code transport ONU
      </h6>
      <h6 className="w-1/6 font-semibold text-sm my-auto mr-4">
        Densité ( kg/m<sup>3</sup> )
      </h6>
      <h6 className="w-1/6 pr-10 font-semibold text-sm my-auto mr-4">
        Coût de traitement {`( €/_ )`}
      </h6>
      <h6 className="flex flex-col w-1/6 font-semibold text-sm my-auto mr-8">
        <span>Flux non autorisés</span>
        <span>{`(erreurs de tri)`}</span>
      </h6>
    </section>
  );
};

export default WasteHeader;
