import React, { useEffect, useState } from "react";

const ContainerViewMultiSelect = ({
  rotationPeriods,
  multiPeriods,
  setHistoryId,
  selectedList,
  setSelectedList,
}) => {
  const [hoveredItems, setHoveredItems] = useState(
    Array(rotationPeriods.length).fill(false),
  );

  useEffect(
    () => {
      setSelectedList(multiPeriods?.selectedList || []);
    },
    // eslint-disable-next-line
    [],
  );

  const handleCheck = (checked, period, index) => {
    const currentSelectedList = selectedList || [];
    const updatedList = checked
      ? [...currentSelectedList, period]
      : currentSelectedList.filter((element) => element?.name !== period.name);

    const filteredList = updatedList.filter(Boolean);
    setSelectedList(filteredList);
    if (!checked) setHistoryId(0);
  };

  const handleHoverChange = (index, isHovered) => {
    setHoveredItems((prevState) => {
      const newState = [...prevState];
      newState[index] = isHovered;
      return newState;
    });
  };

  return (
    <div>
      {rotationPeriods.length > 0 && (
        <div className="flex-grow overflow-y-auto w-full border-2 border-[#D0D0D0] rounded-md max-h-64">
          <ul
            class="text-md text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownCheckboxButton"
          >
            {rotationPeriods.map((item, index) => (
              <li
                onMouseOver={() => handleHoverChange(index, true)}
                onMouseOut={() => handleHoverChange(index, false)}
              >
                <div
                  onClick={() =>
                    handleCheck(!selectedList.includes(item), item)
                  }
                  className={`reset-margin flex items-center cursor-pointer pt-2 hover:bg-[#00a7a6] ${selectedList.length > 0 && selectedList.includes(item) && "bg-gray-300"}`}
                >
                  <input
                    style={{ marginTop: -9 }}
                    onChange={(e) => {
                      handleCheck(e.target.checked, item, index);
                    }}
                    id={`checkbox-item-${index}`}
                    type="checkbox"
                    value={item}
                    checked={
                      selectedList.length > 0 && selectedList.includes(item)
                    }
                    className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label
                    style={{
                      color: hoveredItems[index] ? "#FFFFFF" : "#111827",
                    }}
                    className="cursor-pointer text-md font-medium"
                  >
                    {item.name}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ContainerViewMultiSelect;
