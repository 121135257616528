import React from "react";
import TablePagination from "@material-ui/core/TablePagination";

const Pagination = ({
  totalEntry,
  recordPerPage,
  setRecordPerPage,
  currentPage,
  setCurrentPage,
  resultPage,
  setResultPage,
  screenWidth,
}) => {
  /**
   * A ... :
   * -
   *
   * @param {dict} pageNumber
   */
  const handleChangePage = (event, pageNumber) => {
    setCurrentPage(pageNumber + 1);
    setResultPage(resultPage + recordPerPage);
  };

  return (
    <TablePagination
      component="div"
      count={totalEntry}
      labelRowsPerPage={screenWidth > 1280 ? "Lignes par page:" : ""}
      page={currentPage - 1}
      onPageChange={handleChangePage}
      rowsPerPage={recordPerPage}
      onRowsPerPageChange={(event) => setRecordPerPage(event.target.value)}
      onClick={() => {}}
    />
  );
};

export default Pagination;
