import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

const FooterPage = () => (
  <div className="footer">
    <MDBFooter color="blue" className="font-small pt-1 mt-1">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="3">
            <p>
              &copy; {new Date().getFullYear()} Copyright:{" "}
              <a href="https://www.akanthas.com"> Akanthas.com </a>
            </p>
          </MDBCol>
          <MDBCol md="6">
            <ul>
              <li className="list-unstyled">
                <a href="/confidentialityAgreement">
                  Politique de confidentialité
                </a>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  </div>
);

export default FooterPage;
