export default function TruckSvg({ color, width, height, className }) {
  return (
    <svg
      fill={`${color}`}
      height={`${height}px`}
      width={`${width}px`}
      className={className}
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <path d="m497.75,245.13l-38.867-25.914-12.523-37.456c-4.36-13.07-16.587-21.76-30.36-21.76h-16.736l6.4-16h26.336c17.672,0 32-14.328 32-32s-14.328-32-32-32h-48-352c-17.672,0-32,14.083-32,31.755v128c0,12.711 7.523,24.219 19.172,29.32l79.998,35c-1.072-0.055-2.084-0.32-3.17-0.32-35.346,0-64,28.652-64,64 0,35.346 28.654,64 64,64 29.869,0 54.747-20.428 61.806-48.1 0.738,0.051 1.442,0.345 2.194,0.345h128 2.263c7.138,27 31.95,48 61.737,48s54.6-21 61.737-48h66.263c17.672,0 32-14.573 32-32.245v-80c0-10.703-5.344-20.688-14.25-26.625zm-401.75,138.625c-8.836,0-16-7.164-16-16 0-8.836 7.164-16 16-16 8.837,0 16,7.164 16,16 0,8.836-7.163,16-16,16zm-12.516-160.546l-29.341-12.775 33-82.434h34.475l-38.134,95.209zm59.585,101.491c-0.651-0.713-1.166-1.547-1.85-2.229l3.297,1.443c-0.479,0.266-0.983,0.493-1.447,0.786zm16.677-68.127l-29.341-12.775 46.346-115.798h34.475l-51.48,128.573zm81.073,29.25c-0.75,1.867-1.883,3.372-3.174,4.767l-27.41-12.023c0.121-1.582 0.245-3.066 0.865-4.619l58.425-145.948h34.475l-63.181,157.823zm111.181,97.932c-8.836,0-16-7.164-16-16 0-8.836 7.164-16 16-16 8.837,0 16,7.164 16,16 0,8.836-7.163,16-16,16zm80-127.755h-48v-16.245-47.755h32l16,47.755v16.245z" />
    </svg>
  );
}
