import React from "react";
const imageAmpliroll = require("../../static/assets/Ampliroll.png");
const imageChain = require("../../static/assets/Chain.png");
const imageCompactor = require("../../static/assets/Compactor.png");
const imageGeobox = require("../../static/assets/Geobox.png");
const imageLego = require("../../static/assets/Lego.png");
const imageMetallicDrum = require("../../static/assets/MetallicDrum.png");
const imagePlasticDrum = require("../../static/assets/PlasticDrum.png");
const imageUnknown = require("../../static/assets/Unknown.png");
const imageStorageArea = require("../../static/assets/StorageArea.png");

export default function ContainerVisual({ type, width, height }) {
  const imagePath = (type) => {
    if (type === "ampliroll_bin") {
      return imageAmpliroll;
    } else if (type === "chain_bin") {
      return imageChain;
    } else if (type === "compactor") {
      return imageCompactor;
    } else if (type === "geobox") {
      return imageGeobox;
    } else if (type === "lego") {
      return imageLego;
    } else if (type === "metallic_drum") {
      return imageMetallicDrum;
    } else if (type === "plastic_drum") {
      return imagePlasticDrum;
    } else if (type === "storage_area") {
      return imageStorageArea;
    } else {
      return imageUnknown;
    }
  };

  return (
    <img
      className="m-auto"
      src={imagePath(type)}
      alt="iconApp"
      width={width}
      height={height}
    />
  );
}
