import { useEffect } from "react";
import * as echarts from "echarts";
import { useTranslation } from "react-i18next";

export const MaterialCountingPieChart = ({
  dataChart,
  labelChart,
  materialTotal,
  colorChart,
}) => {
  const { t, i18n } = useTranslation();
  const materialLengt = materialTotal.toString().length;
  useEffect(() => {
    const chartDom = document.getElementById("material-counting");
    const materialChart = echarts.init(chartDom);

    let fontSize;
    let space;
    if (materialLengt < 4) {
      fontSize = "2.5rem";
      space = 35;
    } else if (materialLengt === 4) {
      fontSize = "2rem";
      space = 30;
    } else if (materialLengt > 4) {
      fontSize = "1.8rem";
      space = 25;
    }

    const data = dataChart.map((value, index) => ({
      value: ((value * 100) / materialTotal).toFixed(1),
      name: labelChart[index],
      itemStyle: {
        color: colorChart[index],
      },
    }));

    let option;
    option = {
      tooltip: {
        trigger: "item",
      },
      graphic: [
        {
          type: "group",
          left: "center",
          top: "middle",
          children: [
            {
              type: "text",
              style: {
                text: materialTotal,
                fontSize: fontSize,
                fill: "#E95280",
                font: 'bolder 48px "Mulish", sans-serif',
                fontWeight: "bold",
                textAlign: "center",
              },
            },
            {
              type: "text",
              style: {
                text: t("Detected materials"),
                fontSize: i18n.language === "en" ? "0.5rem" : "0.6rem",
                fill: "#E95280",
                fontWeight: "bold",
                textAlign: "center",
                y: space,
              },
            },
          ],
        },
      ],
      series: [
        {
          type: "pie",
          radius: ["20%", "55%"],
          label: {
            formatter: "{b} ({c}%)",
            position: "outside",
            fontSize: "0.8rem",
            fontWeight: 400,
          },
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
    option && materialChart.setOption(option);
  });

  return (
    <div
      id="material-counting"
      style={{
        height: "100%",
        width: "100%",
        marginLeft: -30,
      }}
    ></div>
  );
};
