import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import { Redirect, useLocation } from "react-router-dom";
import { Card } from "reactstrap";
import AddressCreate from "./AddressCreate";
import AddressEdit from "./AddressEdit";
import AddressFilter from "./AddressFilter";
import AddressRow from "./AddressRow";
import AddressHeader from "./AddressHeader";
import Pagination from "../../../components/utils/Pagination";
import Loading from "../../../components/utils/Loading";
import { ToastContainer } from "react-toastify";

const Address = ({ token, API }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const openCreate = searchParams.get("openCreate");

  const defaultData = {
    name: "",
    number: "",
    street: "",
    postalcode: 0,
    city: "",
    location: [0, 0],
    opening: {
      0: [
        ["", ""],
        ["", ""],
      ], //Sunday
      1: [
        ["", ""],
        ["", ""],
      ], //Monday
      2: [
        ["", ""],
        ["", ""],
      ], //Tuesday
      3: [
        ["", ""],
        ["", ""],
      ], //Wednesday
      4: [
        ["", ""],
        ["", ""],
      ], //Thursday
      5: [
        ["", ""],
        ["", ""],
      ], //Friday
      6: [
        ["", ""],
        ["", ""],
      ], //Saturday
    },
  };

  const [tableData, setTableData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [totalEntry, setTotalEntry] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [resultPage, setResultPage] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);
  const [keywordFilter, setKeywordFilter] = useState("");
  const [listPostalCodeFilter, setListPostalCodeFilter] = useState({});
  const [listCityFilter, setListCityFilter] = useState({});
  const [postalCodeKeys, setPostalCodeKeys] = useState([""]);
  const [cityKeys, setCityKeys] = useState([""]);
  const [addFormData, setAddFormData] = useState(defaultData);
  const [formData, setFormData] = useState(defaultData);
  const [modalCreate, setModalCreate] = useState(openCreate ? true : false);
  const [sortByField, setSortByField] = useState("name");
  const [orderSortByField, setOrderSortByField] = useState("1");
  const [roleKeys, setRoleKeys] = useState([""]);
  const [listRoleFilter, setListRoleFilter] = useState({});
  const [modalEdit, setModalEdit] = useState(false);
  const [addInfo, setAddInfo] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${API}address?page=${currentPage}&limit=${recordPerPage}`,
        {
          headers: {
            authorization: "Bearer " + token,
          },
          params: {
            keywordFilter: keywordFilter,
            listPostalCodeFilter: listPostalCodeFilter,
            listCityFilter: listCityFilter,
            sortByField: sortByField,
            orderSortByField: orderSortByField,
            listRoleFilter: listRoleFilter,
          },
        },
      );
      setTotalEntry(response.data.count);
      setTableData(response.data.data);
      setPostalCodeKeys(response.data.postalCodeKeys);
      setCityKeys(response.data.cityKeys);
      setRoleKeys(response.data.roleKeys);
      setIsLoading(false);
      setIsDeleted(false);
    };
    fetchData();
  }, [
    token,
    API,
    totalEntry,
    resultPage,
    currentPage,
    recordPerPage,
    isDeleted,
    listPostalCodeFilter,
    listCityFilter,
    listRoleFilter,
    keywordFilter,
    sortByField,
    orderSortByField,
  ]);

  if (token) {
    return isLoading ? (
      <Loading color="#F5F5F5" />
    ) : (
      <div className="base">
        <h1>Mes Sites et Filières</h1>

        <AddressFilter
          keywordFilter={keywordFilter}
          setKeywordFilter={setKeywordFilter}
          postalCodeKeys={postalCodeKeys}
          setListPostalCodeFilter={setListPostalCodeFilter}
          cityKeys={cityKeys}
          setListCityFilter={setListCityFilter}
          roleKeys={roleKeys}
          setListRoleFilter={setListRoleFilter}
        />

        <section className="top-table">
          <article className="operation-table">
            <div className="flex relative">
              <button
                className="operation-data operation-button my-auto"
                onClick={() => setModalCreate(true)}
                onMouseOver={() => setAddInfo(true)}
                onMouseOut={() => setAddInfo(false)}
              >
                <i className="fas fa-plus-circle icon-header"></i>
              </button>
              {addInfo && (
                <span className="absolute bg-yellow text-white -right-32 px-2 rounded -top-5">
                  Ajouter une adresse
                </span>
              )}
            </div>
            <div className="operation-data">
              <i className="fas fa-sort-amount-up ico-action "></i>

              <select
                className="sort-select"
                name="sortByField"
                onChange={(event) => setSortByField(event.target.value)}
              >
                <option value="name">Nom</option>
                <option value="role">Rôle</option>
                <option value="street">Nom de rue</option>
                <option value="postalCode">Code postale</option>
                <option value="city">Ville</option>
              </select>

              <select
                className="sort-select"
                name="orderSortByField"
                onChange={(event) => setOrderSortByField(event.target.value)}
              >
                <option value="1">Croissant</option>
                <option value="-1">Décroissant</option>
              </select>
            </div>
          </article>

          <Pagination
            totalEntry={totalEntry}
            recordPerPage={recordPerPage}
            setRecordPerPage={setRecordPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            resultPage={resultPage}
            setResultPage={setResultPage}
          />
        </section>

        <Card className="table">
          <div>
            <AddressHeader
              classColumnTable={`hidden lg:flex xl:flex 2xl:flex w-full justify-between bg-white rounded h-14 mx-0 my-2`}
            />
            <article className="content-table">
              {tableData &&
                tableData.map((address, index) => {
                  if (address) {
                    return (
                      <Fragment key={address._id}>
                        {modalEdit && formData._id === address._id ? (
                          <AddressEdit
                            editFormData={formData}
                            setEditFormData={setFormData}
                            tableData={tableData}
                            setTableData={setTableData}
                            modalEdit={modalEdit}
                            setModalEdit={setModalEdit}
                          />
                        ) : null}
                        <AddressRow
                          data={address}
                          tableData={tableData}
                          setTableData={setTableData}
                          setIsDeleted={setIsDeleted}
                          formData={formData}
                          setFormData={setFormData}
                          setModalEdit={setModalEdit}
                        />
                      </Fragment>
                    );
                  }
                  return null;
                })}
              {modalCreate && (
                <AddressCreate
                  addFormData={addFormData}
                  setAddFormData={setAddFormData}
                  defaultData={defaultData}
                  tableData={tableData}
                  setTableData={setTableData}
                  totalEntry={totalEntry}
                  setTotalEntry={setTotalEntry}
                  modalCreate={modalCreate}
                  setModalCreate={setModalCreate}
                />
              )}
            </article>
          </div>
          <ToastContainer autoClose={3000} style={{ marginTop: "60px" }} />
        </Card>
      </div>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
};

export default Address;
