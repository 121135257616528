import { Text, View } from "@react-pdf/renderer";
import { wasteQualityStyleSheet } from "../styles/wasteQualityStyleSheet";

export const WasteQualityLegend = ({ data }) => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: 20,
      }}
    >
      {data.materialsTemporalData.map(
        (material, index) =>
          material && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginVertical: 5,
              }}
              key={material._id}
            >
              <View
                style={{
                  borderRadius: "50%",
                  padding: "5px",
                  margin: "auto 5px",
                  backgroundColor: `${material.color}`,
                }}
              />
              <Text style={wasteQualityStyleSheet.legendText}>
                {material.nameAndCount}
              </Text>
            </View>
          ),
      )}
    </View>
  );
};
