import React, { useEffect, useState } from "react";
import {
  formattedDateDocument,
  formattedDayName,
  formattedTime,
} from "../../formatted/FormattedDateAndTime";
import {
  formattedColorMaterial,
  formattedMaterial,
} from "../../formatted/FormattedText";
import { Text, View } from "@react-pdf/renderer";
import { TitleAndLegendStyleSheet } from "../styles/LegendeAndTitle";
import { useTranslation } from "react-i18next";

export const TitleAndLengend = ({ data }) => {
  const { i18n } = useTranslation();
  const [materialList, setMaterialList] = useState([]);
  const [wasteQuality, setWasteQuality] = useState(false);
  const [wasteMonitoring, setWasteMonitoring] = useState(false);

  const updateMaterialList = (annotation, materialData) => {
    const anomalyToLowerCase = annotation.cls.toLowerCase();
    const index = materialData.findIndex(
      (material) => material.value === anomalyToLowerCase,
    );

    if (index === -1) {
      materialData.push({
        name: formattedMaterial(annotation.cls, i18n.language),
        value: annotation.cls,
        color: formattedColorMaterial(annotation.cls, i18n.language),
        count: 1,
        nameAndCount: `${formattedMaterial(annotation.cls, i18n.language)} (${1})`,
      });
    } else {
      materialData[index].count += 1;
      materialData[index].nameAndCount =
        `${formattedMaterial(annotation.cls, i18n.language)} (${materialData[index].count})`;
    }
  };

  useEffect(
    () => {
      const materialsTemporalData = [];

      if (data.characterization) {
        if (
          data.wasteObjectsList !== undefined &&
          data.wasteObjectsList.length > 0
        ) {
          data.wasteObjectsList.forEach((annotation) => {
            if (annotation.cls !== undefined) {
              updateMaterialList(annotation, materialsTemporalData);
            }
          });
        }

        setWasteQuality(true);
      }

      if (data.activatedActivityOption && data.activatedSortingErrorOption) {
        if (data.anomalies > 0) {
          if (
            data.wasteObjectsList !== undefined &&
            data.wasteObjectsList.length > 0
          ) {
            data.wasteObjectsList.forEach((annotation) => {
              if (
                annotation.cls !== undefined &&
                !annotation.isAuthorizedMaterial
              ) {
                updateMaterialList(annotation, materialsTemporalData);
              }
            });
          }
        }

        setWasteMonitoring(true);
      }

      setMaterialList(materialsTemporalData);
    },
    // eslint-disable-next-line
    [data],
  );

  useEffect(() => {}, [data]);

  return (
    <View style={TitleAndLegendStyleSheet.contentValue}>
      <View
        style={{
          marginBottom: 5,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: 600,
            textTransform: "capitalize",
          }}
        >
          {formattedDayName(data._time)}{" "}
          {formattedDateDocument(data?._time, true)},{" "}
          {formattedTime(data?._time)} – {data?.addressName} ​
        </Text>
        <Text
          style={{
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          {data?.wasteName} – {data?.filling_level}% rempli |{" "}
          {wasteQuality &&
            `${data?.wasteObjectsList.length} objet${data.wasteObjectsList.length > 1 ? "s" : ""}`}
          {wasteQuality && wasteMonitoring ? " – " : ""}
          {wasteMonitoring &&
            `${data?.anomalies} erreur${data.anomalies > 1 ? "s" : ""} de tri​`}
        </Text>
      </View>

      {materialList.length > 0 && (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            border: "1px solid #000",
            padding: "5px",
          }}
        >
          {materialList.map(
            (material, index) =>
              material && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginHorizontal: 5,
                    marginVertical: 2,
                  }}
                  key={material._id}
                >
                  <View
                    style={{
                      borderRadius: "50%",
                      padding: "3px",
                      margin: "auto 5px",
                      backgroundColor: `${material.color}`,
                    }}
                  ></View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    {material.nameAndCount}
                  </Text>
                </View>
              ),
          )}
        </View>
      )}
    </View>
  );
};
