import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formattedDate } from "./formatted/FormattedDateAndTime.js";
import { Input, Label } from "reactstrap";
import { Calendar } from "react-calendar";
import { useTranslation } from "react-i18next";

const CalendarComponent = ({
  classNameBase,
  onChangeCalendar,
  displayCalendar,
  setDisplayCalendar,
  periodHistoryStart,
  periodHistoryStop,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <section className={classNameBase}>
      {/* Input Start Date*/}
      <div className="flex w-1/2 mx-1">
        <Label className="my-auto mr-1">{t("Start")}</Label>
        <Input
          onClick={() => setDisplayCalendar(true)}
          value={formattedDate(periodHistoryStart, i18n.language)}
          type="text"
          // readOnly={false}
          onChange={(event) => {}}
        />
      </div>

      {/* Input End Date*/}
      <div className="flex w-1/2 mx-1">
        <Label className="my-auto mr-1">{t("Stop")}</Label>
        <Input
          onClick={() => setDisplayCalendar(true)}
          value={formattedDate(periodHistoryStop, i18n.language)}
          type="text"
          // readOnly={false}
          onChange={(event) => {}}
        />
      </div>

      {/* Calendar */}
      <div
        className="z-10 shadow-xl	absolute top-10 rounded right-0 bg-white px-3"
        style={displayCalendar ? { display: "block" } : { display: "none" }}
      >
        {/* Close Calendar */}
        <div
          onClick={() => setDisplayCalendar(false)}
          className="form-calendar-close"
        >
          <span>Fermer</span>
          <FontAwesomeIcon icon="times" />
        </div>

        {/* Calendar */}
        <Calendar
          onChange={(event) => onChangeCalendar(event)}
          selectRange={true}
          showNavigation={true}
          maxDate={new Date()}
          closeCalendar={true}
        />
      </div>
    </section>
  );
};

export default CalendarComponent;
