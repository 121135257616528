import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, ModalBody, ModalFooter, ModalHeader, Form } from "reactstrap";
import {
  handleEditFormCancel,
  handleEditFormSubmit,
  handleFormChange,
} from "../../../components/modals/EditableTable";
import { toast } from "react-toastify";
import AddressGeneralInformations from "./AddressGeneralInformations";
import Outlet from "./Outlet";

const AddressEdit = ({
  editFormData,
  setEditFormData,
  tableData,
  setTableData,
  modalEdit,
  setModalEdit,
}) => {
  const path = "address";
  const toggle = () => setModalEdit(false);
  const [errorForm, setErrorForm] = useState("");
  const [showGeneralInformations, setShowGeneralInformations] = useState(true);
  const [showOutlet, setShowOutlet] = useState(false);

  return (
    <Modal isOpen={modalEdit} toggle={toggle}>
      <ModalHeader>
        <h2>Modifier l'adresse</h2>
        <CloseIcon
          style={{
            color: "var(--gray)",
            cursor: "pointer",
          }}
          onClick={() => {
            handleEditFormCancel(setModalEdit);
          }}
        />
      </ModalHeader>

      <Form
        onSubmit={async (event) => {
          event.preventDefault();

          const response = await handleEditFormSubmit(editFormData, path);

          if (response.status === 200) {
            const newData = [...tableData];
            const index = tableData.findIndex(
              (elem) => elem._id === editFormData._id,
            );
            newData[index] = editFormData;
            setTableData(newData);
            setModalEdit(false);
            toast.success("L'adresse a bien été modifiée", {
              className: "custom-toast",
            });
          } else {
            setErrorForm(response.data.error);
          }
        }}
      >
        <section className="flex flex-col p-3">
          <section className="flex max-md:flex-col text-base text-cyan ">
            <div
              className={`mr-2 px-1 cursor-pointer ${showGeneralInformations ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(true);
                setShowOutlet(false);
              }}
            >
              Infos générales
            </div>
            <div
              className={`mr-2 px-1 cursor-pointer ${showOutlet ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(false);
                setShowOutlet(true);
              }}
            >
              Filière/Exutoire
            </div>
          </section>
        </section>

        <ModalBody>
          {showGeneralInformations && (
            <AddressGeneralInformations
              editFormData={editFormData}
              setEditFormData={setEditFormData}
              handleFormChange={handleFormChange}
              errorForm={errorForm}
            />
          )}
          {showOutlet && (
            <Outlet
              editFormData={editFormData}
              setEditFormData={setEditFormData}
              handleFormChange={handleFormChange}
              errorForm={errorForm}
            />
          )}
        </ModalBody>

        <ModalFooter className="information-footer-modal">
          <button className="button-submit" type="submit">
            Sauvegarder
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddressEdit;
