import { Redirect, useLocation } from "react-router";
import { useEffect, useState, useRef } from "react";
import ActivityHistoryTimeLine from "./ActivityHistoryTimeLine";
import { Multiselect } from "multiselect-react-dropdown";
import {
  formattedContainerId,
  formattedTypeContainer,
} from "../../../components/formatted/FormattedText";
import CalendarComponent from "../../../components/CalendarComponent";
import ActivityCollection from "./ActivityCollection";
import Cookies from "js-cookie";
import {
  getKeywordFilterOptions,
  getSuperContainerList,
} from "../../../services/container";
import { useTranslation } from "react-i18next";
import {
  getEndOfMonth,
  getStartOfMonth,
} from "../../../components/formatted/FormattedDateAndTime";
import {
  capitalizeFirstLetter,
  currentMonthName,
  getMonthName,
  previousMonthName,
} from "../../../components/utils/Date";
import { Input } from "reactstrap";

const Activity = () => {
  const { t, i18n } = useTranslation();
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");

  const location = useLocation();
  const stateContainer = location.state || undefined;
  const displayCollecte =
    stateContainer !== undefined ? stateContainer.displayCollecte : false;
  const keywordF =
    stateContainer !== undefined ? stateContainer.keywordFilter : [];

  const [keywordFilter, setKeywordFilter] = useState(keywordF || []);
  const [keywordFilterOptions, setKeywordFilterOptions] = useState([]);

  const [periodHistoryStop, setPeriodHistoryStop] = useState(new Date());
  const [periodHistoryStart, setPeriodHistoryStart] = useState(
    getStartOfMonth(new Date()).toISOString(),
  );

  const [showHistory, setShowHistory] = useState(
    displayCollecte ? true : false,
  );
  const [showContainerCollection, setShowContainerCollection] = useState(
    displayCollecte ? false : true,
  );
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const multiselectKeywordRef = useRef(null);
  const [superContainers, setSuperContainers] = useState({});
  const [showDate, setShowDate] = useState(false);

  useEffect(() => {
    getKeywordFilterOptions({
      keywordFilter: JSON.stringify(keywordFilter.value),
    }).then((res) => {
      if (res.status === 200) {
        setKeywordFilterOptions(res.data.keywordFilterOptions);
      }
    });
  }, [keywordFilter]);

  useEffect(() => {
    getSuperContainerList()
      .then((response) => {
        if (response.data.superContainers.length > 0) {
          setSuperContainers(response.data.superContainers[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [API, token]);

  const labeledkeywordFilterOptions = keywordFilterOptions
    .filter((keyword) => keyword.key !== null)
    .map((keyword) => {
      if (keyword.type === "type") {
        keyword = { ...keyword, key: formattedTypeContainer(keyword.key) };
      } else if (keyword.type === "id") {
        keyword = {
          ...keyword,
          key: formattedContainerId(keyword.key) + keyword.key,
        };
      }
      return keyword;
    });

  const selectPeriodFilter = (value) => {
    setShowDate(value === "calendar");
    const now = new Date();
    let startOfLastMonth;
    let endOfLastMonth;
    switch (value) {
      case "0": {
        const startOfMonth = getStartOfMonth(now);
        setPeriodHistoryStart(startOfMonth.toISOString());
        setPeriodHistoryStop(now.toISOString());
        break;
      }
      case "1": {
        startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1),
        );
        endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1),
        );
        break;
      }
      case "2":
        startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 2),
        );
        endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 2),
        );
        break;

      case "3":
        startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 3),
        );
        endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 3),
        );
        break;

      case "4":
        startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 4),
        );
        endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 4),
        );
        break;

      case "5":
        startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 5),
        );
        endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 5),
        );
        break;

      default:
        break;
    }
    setPeriodHistoryStart(startOfLastMonth.toISOString());
    setPeriodHistoryStop(endOfLastMonth.toISOString());
  };

  const onChangeCalendar = (event) => {
    setPeriodHistoryStop(new Date(event[1]).toISOString());
    setPeriodHistoryStart(new Date(event[0]).toISOString());

    setDisplayCalendar(false);
  };

  if (token) {
    return (
      <div className="base">
        <h1>{t("My Historical Data")}</h1>

        <nav
          className="flex mx-1 justify-between"
          style={{ marginTop: "30px", marginBottom: "20px" }}
        >
          <div className="w-1/4">
            <span
              onClick={() => {
                setShowHistory(false);
                setShowContainerCollection(true);
              }}
              className={`text-cyan text-base cursor-pointer px-5 py-2 ${showContainerCollection ? "bg-inherit border-b-4 border-cyan" : "bg-gainsboro rounded-md"}`}
            >
              {t("Rotation")}
            </span>
            <span
              onClick={() => {
                setShowHistory(true);
                setShowContainerCollection(false);
              }}
              className={`text-cyan text-base cursor-pointer px-5 py-2 ml-2 ${showHistory ? "bg-inherit border-b-4 border-cyan" : "bg-gainsboro rounded-md"} `}
            >
              {t("Activity")}
            </span>
          </div>
        </nav>

        <div className="w-full grid justify-items-end mb-2">
          <div className="w-3/4 flex justify-end">
            {/* Calendar */}
            {showDate ? (
              <CalendarComponent
                classNameBase="relative w-2/5 flex"
                onChangeCalendar={onChangeCalendar}
                displayCalendar={displayCalendar}
                setDisplayCalendar={setDisplayCalendar}
                periodHistoryStart={periodHistoryStart}
                periodHistoryStop={periodHistoryStop}
              />
            ) : null}

            {/* Multiselect */}
            <div className="relative w-1/4">
              <Multiselect
                options={labeledkeywordFilterOptions}
                groupBy="cat"
                displayValue="key"
                onSelect={(value) => {
                  setKeywordFilter({ value });
                }}
                onRemove={(value) => {
                  setKeywordFilter({ value });
                }}
                hidePlaceholder={true}
                emptyRecordMsg={t("No available option")}
                placeholder={t("Search")}
                closeIcon="circle"
              />
              {keywordFilter.length > 0 ? (
                <i
                  className="fas fa-trash absolute top-3 right-2"
                  onClick={() => {
                    multiselectKeywordRef.current.resetSelectedValues();
                    setKeywordFilter([]);
                  }}
                ></i>
              ) : null}
            </div>
            <div className="flex w-1/4 ml-2">
              <Input
                type="select"
                style={{ borderWidth: "0px" }}
                name="select-graphe"
                onChange={(event) => selectPeriodFilter(event.target.value)}
              >
                <option value="0">
                  {t("Current month")} :{" "}
                  {capitalizeFirstLetter(currentMonthName(i18n.language))}
                </option>
                <option value="1">
                  {t("Last month")} :{" "}
                  {capitalizeFirstLetter(previousMonthName(i18n.language))}
                </option>
                <option value="2">
                  {i18n.language === "fr" && "Mois de "}
                  {capitalizeFirstLetter(getMonthName(2, i18n.language))}
                </option>
                <option value="3">
                  {i18n.language === "fr" && "Mois de "}
                  {capitalizeFirstLetter(getMonthName(3, i18n.language))}
                </option>
                <option value="4">
                  {i18n.language === "fr" && "Mois de "}
                  {capitalizeFirstLetter(getMonthName(4, i18n.language))}
                </option>
                <option value="5">
                  {i18n.language === "fr" && "Mois de "}
                  {capitalizeFirstLetter(getMonthName(5, i18n.language))}
                </option>
                <option value="calendar">{t("Custom period")}</option>
              </Input>
            </div>
          </div>
        </div>

        {showHistory && (
          <ActivityHistoryTimeLine
            keywordFilter={keywordFilter}
            periodHistoryStart={periodHistoryStart}
            periodHistoryStop={periodHistoryStop}
            setKeywordFilterOptions={setKeywordFilterOptions}
            onChangeCalendar={onChangeCalendar}
            displayCalendar={displayCalendar}
            setDisplayCalendar={setDisplayCalendar}
            superContainers={superContainers}
          />
        )}

        {showContainerCollection && (
          <ActivityCollection
            keywordFilter={keywordFilter}
            periodHistoryStart={periodHistoryStart}
            periodHistoryStop={periodHistoryStop}
            onChangeCalendar={onChangeCalendar}
            displayCalendar={displayCalendar}
            setDisplayCalendar={setDisplayCalendar}
            superContainers={superContainers}
          />
        )}
      </div>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
};

export default Activity;
