import React, { useEffect, useState } from "react";
import { ModalTitle } from "react-bootstrap";
import Cookies from "js-cookie";
import ContainerViewActivity from "./containerView/ContainerViewActivity";
import ContainerViewFilling from "./containerView/ContainerViewFilling";
import ContainerViewQuality from "./containerView/ContainerViewQuality";
import ContainerViewDetails from "./containerView/ContainerViewDetails";
import { Redirect } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ContainerDetailsView = () => {
  const token = Cookies.get("userToken");
  const { t } = useTranslation();
  const [container, setContainer] = useState(
    JSON.parse(sessionStorage.getItem("containerDetails")).container,
  );
  const { containers, countContentCategory } = JSON.parse(
    sessionStorage.getItem("containerDetails"),
  );
  const max_session_size = process.env.REACT_APP_MAX_SESSION_SIZE;
  const [tableData, setTableData] = useState(containers || []);
  const [showActivity, setShowActivity] = useState(
    container.activatedActivityOption ? true : false,
  );
  const [showFilling, setShowFilling] = useState(
    !container.activatedActivityOption && container.activatedFillingLevelOption
      ? true
      : false,
  );
  const [showQuality, setShowQuality] = useState(
    !container.activatedActivityOption &&
      !container.activatedFillingLevelOption &&
      container.activatedSortingErrorOption
      ? true
      : false,
  );
  const [showDetails, setShowDetails] = useState(
    !container.activatedActivityOption &&
      !container.activatedFillingLevelOption &&
      !container.activatedSortingErrorOption &&
      !container.characterization
      ? true
      : false,
  );
  const [isAkanthasAccount, setIsAkanthasAccount] = useState(true);

  const updateData = (newData) => {
    const historyCurrentSize = new Blob([
      JSON.stringify(newData.historyCurrent),
    ]).size;
    if (historyCurrentSize < max_session_size) {
      const containerDetails = {
        _id: container._id,
        _time: new Date().toISOString(),
        container: newData,
        containers: containers,
      };
      sessionStorage.setItem(
        "containerDetails",
        JSON.stringify(containerDetails),
      );
    }
    setContainer(newData);
  };

  useEffect(() => {
    const email = Cookies.get("userEmail") || "";
    setIsAkanthasAccount(email.includes("@akanthas.com"));
  }, [isAkanthasAccount]);

  if (token) {
    return (
      <div className="base" style={{ minWidth: "850px" }}>
        <div>
          <div className="container-modal-header">
            <ModalTitle>
              <div className="data-bold">
                <span>{container.addressName} | </span>
                <span style={{ color: "var(--blush)" }}>
                  {(container.contentCategory &&
                    container.contentCategory.label) ||
                    container.wasteName ||
                    "-"}{" "}
                </span>
                <span>
                  |{" "}
                  {((container.volume > 0 && container.volume) ||
                    container.volumeToCubicMeter) +
                    "" +
                    (container.volumeUnit || "m3") || "-"}
                </span>
                {container?.positioning && container.positioning !== "" && (
                  <>
                    <span> | </span>
                    <span style={{ color: "var(--gray)" }}>
                      {container.positioning}
                    </span>
                  </>
                )}
              </div>
            </ModalTitle>
          </div>

          <nav className="container-modal-nav">
            <div>
              {container.activatedActivityOption || isAkanthasAccount ? (
                <span
                  onClick={() => {
                    setShowActivity(true);
                    setShowFilling(false);
                    setShowQuality(false);
                    setShowDetails(false);
                  }}
                  className={`${showActivity ? "container-modal-select" : ""}`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {container.activatedActivityOption === false && (
                    <FaLock style={{ marginRight: "5px" }} />
                  )}
                  {t("Activity")}
                </span>
              ) : null}
              {container.activatedFillingLevelOption || isAkanthasAccount ? (
                <span
                  onClick={() => {
                    setShowActivity(false);
                    setShowFilling(true);
                    setShowQuality(false);
                    setShowDetails(false);
                  }}
                  className={`${showFilling ? "container-modal-select" : ""}`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {container.activatedFillingLevelOption === false && (
                    <FaLock style={{ marginRight: "5px" }} />
                  )}
                  {t("Filling level")}
                </span>
              ) : null}
              {container.activatedSortingErrorOption || isAkanthasAccount ? (
                <span
                  onClick={() => {
                    setShowActivity(false);
                    setShowFilling(false);
                    setShowQuality(true);
                    setShowDetails(false);
                  }}
                  className={`${showQuality ? "container-modal-select" : ""}`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {container.activatedSortingErrorOption === false && (
                    <FaLock style={{ marginRight: "5px" }} />
                  )}
                  {t("Classification/Quality")}
                </span>
              ) : null}
              <span
                onClick={() => {
                  setShowActivity(false);
                  setShowFilling(false);
                  setShowQuality(false);
                  setShowDetails(true);
                }}
                className={`${showDetails ? "container-modal-select" : ""}`}
              >
                {t("Characteristics")}
              </span>
            </div>
          </nav>
        </div>

        {showActivity && (
          <ContainerViewActivity data={container} updateData={updateData} />
        )}

        {showFilling && (
          <ContainerViewFilling
            data={container}
            tableData={tableData}
            setTableData={setTableData}
            updateData={updateData}
          />
        )}

        {showQuality && (
          <ContainerViewQuality data={container} updateData={updateData} />
        )}

        {showDetails && (
          <ContainerViewDetails
            data={container}
            tableData={tableData}
            setTableData={setTableData}
            countContentCategory={countContentCategory}
          />
        )}
      </div>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
};

export default ContainerDetailsView;
