import reactImageSize from "react-image-size";

export async function getDimensions(
  src,
  setWidthDimensions,
  setHeightDimensions,
) {
  const { width, height } = await reactImageSize(src);
  setWidthDimensions(width);
  setHeightDimensions(height);
}
