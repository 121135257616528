import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import Cookies from "js-cookie";
import ContainerViewMaterial from "./containerView/ContainerViewMaterial";
import Loading from "../../../components/utils/Loading";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getContainersList,
  getSuperContainerList,
} from "../../../services/container";
import ForbiddenModule from "../../../components/ForbiddenModule";
import { ToastContainer } from "react-toastify";

const sortByImageUrl = (a, b) => {
  // Extract the suffix -L and -R
  const regex = /-(L|R)\./;
  const suffixA = a.image_url.match(regex)?.[1];
  const suffixB = b.image_url.match(regex)?.[1];

  // Priority : -L first, then -R
  if (suffixA === "L" && suffixB !== "L") {
    return -1;
  } else if (suffixA !== "L" && suffixB === "L") {
    return 1;
  } else if (suffixA === "R" && suffixB !== "R") {
    return -1;
  } else if (suffixA !== "R" && suffixB === "R") {
    return 1;
  } else {
    return 0;
  }
};

const CharacterizationsView = () => {
  const location = useLocation();
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");
  const { t } = useTranslation();

  const [containers, setContainers] = useState([]);
  const [superContainer, setSuperContainer] = useState(null);
  const [selectedContainer, setSelectedContainer] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [expendImage, setExpendImage] = useState(false);

  const isAkanthasAccount = (Cookies.get("userEmail") || "").includes(
    "@akanthas.com",
  );

  let characterization = false;

  const containersOptions = JSON.parse(
    localStorage.getItem("containersOptions"),
  );
  if (containersOptions) {
    characterization =
      containersOptions.characterizationCount > 0 || isAkanthasAccount;
  }

  const fetchContainer = async () => {
    setContainers([]);
    setIsLoading(true);
    await getContainersList({
      page: null,
      limit: null,
      fieldSort: "filling_level",
      orderSort: "-1",
      typeFillingFilter: "all",
    }).then((response) => {
      if (response.status === 200) {
        setContainers(response.data.data.sort(sortByImageUrl));
        setSelectedContainer(response.data.data.sort(sortByImageUrl)[0]);
      }
    });

    await getSuperContainerList()
      .then((response) => {
        if (response.data.superContainers.length > 0) {
          setSuperContainer(response.data.superContainers[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchContainer();
  }, [API, token]);

  if (token) {
    return (
      <div className="base" style={{ minWidth: "850px" }}>
        <h1 className="flex flex-row">
          {!characterization && (
            <FaLock style={{ marginRight: "5px", height: 25, marginTop: 10 }} />
          )}
          {t("My Characterizations")}
        </h1>
        {!characterization &&
        location.pathname.includes("characterizations") ? (
          <ForbiddenModule viewType={"characterization"} />
        ) : (
          <>
            {isLoading ? (
              <Loading color="#F5F5F5" />
            ) : (
              Object.keys(selectedContainer).length !== 0 && (
                <ContainerViewMaterial
                  data={selectedContainer}
                  expendImage={expendImage}
                  setExpendImage={setExpendImage}
                  superContainer={superContainer}
                  containers={containers}
                  setSelectedContainer={setSelectedContainer}
                  fetchContainer={fetchContainer}
                />
              )
            )}
            <ToastContainer autoClose={3000} style={{ marginTop: "60px" }} />
          </>
        )}
      </div>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
};

export default CharacterizationsView;
