const UserHeader = ({ classColumnTable }) => {
  return (
    <article className={classColumnTable}>
      <h6 className="column-title ml-2.5">Nom</h6>
      <h6 className="column-title">Prénom</h6>
      <h6 className="column-title">Adresses liées</h6>
      <h6 className="column-title">Rôle</h6>
      <h6 className="column-title column-mail">Mail</h6>
      <h6 className="column-title">Téléphone</h6>
      <span className="column-title column-action"></span>
    </article>
  );
};

export default UserHeader;
