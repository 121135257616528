import React, { useState } from "react";
import ContainerVisual from "./formatted/LoadImages";
import { formattedDate, formattedTime } from "./formatted/FormattedDateAndTime";
import { useTranslation } from "react-i18next";
import { CropImage } from "./utils/ImageTreatment";

const ImageListAnomalies = ({
  container,
  dataSortedByAnomalies,
  findHistoryId,
  imageWidth,
  imageHeight,
}) => {
  const { i18n } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <section
      className="grid grid-cols-8 gap-4 w-full bg-white px-2 py-4 mb-3"
      style={{ borderRadius: "10px" }}
    >
      {dataSortedByAnomalies.length > 0 &&
        dataSortedByAnomalies.map((item) => (
          <div className="flex flex-col" style={{ width: "12,5%" }}>
            <div className="flex flex-col items-center">
              {item.image_url ? (
                <div
                  className="border-2 border-red rounded-md p-1 cursor-pointer relative"
                  style={{ width: imageWidth, height: imageHeight }}
                  onClick={() => {
                    findHistoryId(item._time);
                    setSelectedItem(item._time);
                  }}
                >
                  <CropImage
                    container={container}
                    data={item}
                    imgDivWidth={imageWidth - 6}
                    imgDivHeight={imageHeight - 8}
                    isTooltip={true}
                    isMap={false}
                    isCharacterization={false}
                    displayLegend={false}
                    materialsDropdownToDisplay={[]}
                    showCheckbox={false}
                    displayToBlock={false}
                  />
                  {selectedItem === item._time && (
                    <div className="absolute top-0 left-0 w-full h-full bg-red opacity-10"></div>
                  )}
                  <div className="absolute top-0 left-0 p-1">
                    <i className="fas fa-exclamation-circle text-red text-lg"></i>
                  </div>
                </div>
              ) : (
                <ContainerVisual type={item.type} width={imageWidth} />
              )}
            </div>
            <div className="flex flex-col w-full items-center text-xs mt-2">
              <span className="font-medium">
                {formattedDate(item._time, i18n.language)} -{" "}
                {formattedTime(item._time)}
              </span>
              <span className="data-bold">
                {item.anomalies}{" "}
                {i18n.language === "en"
                  ? `sorting error${item.anomalies > 1 ? "s" : ""}`
                  : `erreur${item.anomalies > 1 ? "s" : ""} de tri`}
              </span>
            </div>
          </div>
        ))}
    </section>
  );
};

export default ImageListAnomalies;
