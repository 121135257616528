import { useEffect } from "react";
import * as echarts from "echarts";

export const VolumePieChart = ({
  dataChart,
  labelsChartWaste,
  totalVolumeUsed,
  wasteCategory,
}) => {
  useEffect(() => {
    const chartVolume = document.getElementById("volume-chart");
    const volumeChart = echarts.init(chartVolume);

    const wasteCategoryColor = (category, label) => {
      const res = category.find((item) => item.label === label);
      return res?.displayColor;
    };

    let data = [];
    for (let i = 0; i < dataChart.length; i++) {
      const item = {
        value: ((dataChart[i] * 100) / totalVolumeUsed).toFixed(1),
        name: labelsChartWaste[i],
        itemStyle: {
          color: wasteCategoryColor(wasteCategory, labelsChartWaste[i]),
        },
      };
      data.push(item);
    }

    let option;
    option = {
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          type: "pie",
          radius: ["60%", "100%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              formatter: "{c}%",
            },
          },
          labelLine: {
            show: false,
          },
          data: data,
        },
      ],
    };
    option && volumeChart.setOption(option);
  }, [dataChart, labelsChartWaste, totalVolumeUsed, wasteCategory]);

  return (
    <div
      id="volume-chart"
      style={{
        height: 180,
        width: 180,
      }}
    ></div>
  );
};
