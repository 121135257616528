import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { currentMonthName } from "../../components/utils/Date";
import { WarningComponent } from "../../components/WarningComponent";
import axios from "axios";
import Cookie from "js-cookie";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "../../components/utils/SkeletonLoader";
import { getCo2Generated } from "../../services/container";

export const DashboardCo2 = () => {
  const [helpCo2, setHelpCo2] = useState(false);
  const [isRequiredWarning, setIsRequiredWarning] = useState(false);
  const [co2Generated, setCo2Generated] = useState(0);
  const [countCollection, setCountCollection] = useState(0);
  const [totalCollection, setTotalCollection] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [countIsLoading, setCountIsLoading] = useState(true);
  const [co2IsLoading, setCo2IsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookie.get("userToken");

  // Get CO2 generated
  useEffect(() => {
    getCo2Generated({
      periodStart: 0,
    })
      .then((res) => {
        setCo2Generated(res.data.co2Generated);
        setCountCollection(res.data.rotationTotal);

        if (res.data.error) {
          setErrorMessage(
            i18n.language === "en"
              ? "Destination sites and/or densities are missing. Please configure them in your address and container settings."
              : "Sites de destination et/ou densités manquants. Configurez les dans vos pages.",
          );
        }
        setCo2IsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          if (err.response.data.error === "No containers completed") {
            setErrorMessage(
              i18n.language === "en"
                ? "Configure destination addresses and densities in your address and container settings"
                : "Configurez les adresses de destination et les densités sur vos pages.",
            );
            setIsRequiredWarning(true);
            setCo2IsLoading(false);
          }
        }
      });
  }, [API, token, i18n.language]);

  // Get number of collections
  useEffect(() => {
    axios
      .get(`${API}collection/count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          periodStart: 0,
          filterByAddress: true,
        },
      })
      .then((res) => {
        setTotalCollection(res.data.countCollection);
        setCountIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [API, token]);

  return (
    <section className="dashboard-card">
      <div className="flex relative w-full">
        <div className="flex flex-col w-full">
          <div
            className="flex font-semibold w-full"
            style={{ alignItems: "center", gap: "20px" }}
          >
            <i
              className="fas fa-cloud text-gainsboro"
              style={{ fontSize: "50px" }}
            ></i>
            <div className="ml-2.5">
              <h5 className="text text-blush text-3xl font-semibold mr-2 mb-0">
                {co2IsLoading || co2Generated === 0
                  ? "-"
                  : Math.round(co2Generated)}
                {errorMessage && !isRequiredWarning && "*"} kg&thinsp;
                <span className="text-2xl font-bolder text-blue">
                  CO
                  <sub style={{ fontWeight: "normal", fontSize: "14px" }}>
                    2
                  </sub>
                </span>
              </h5>
              <h6 className="text-blue text-lg font-semibold">
                {t("produced")} {t("during")}{" "}
                <span className="text-blush">
                  {currentMonthName(i18n.language)}
                </span>
              </h6>
            </div>
          </div>
        </div>
        <i
          className="fas fa-info-circle text-gainsboro"
          style={{ marginTop: "10px", marginRight: "-5px" }}
          onMouseOver={() => setHelpCo2(true)}
          onMouseOut={() => setHelpCo2(false)}
        ></i>
      </div>
      {co2IsLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          <div className="text-blue text-2xl font-semibold mb-2 flex flex-column py-0 px-6">
            <div className="flex flex-row items-end">
              <span className="text-4xl text-cyan mr-1">
                {co2IsLoading ? "-" : countCollection}
              </span>
              <span>{t("analyzed removals")}</span>
            </div>
            <span>
              {t("from a total of")} {countIsLoading ? "-" : totalCollection}
            </span>
          </div>

          {errorMessage ? (
            <WarningComponent
              required={isRequiredWarning}
              message={errorMessage}
            />
          ) : (
            <div></div>
          )}

          {helpCo2 && (
            <div className="modal-item-key-help">
              {/* Need to change message - see mockup*/}
              <p>
                {i18n.language === "fr" && "Le "}CO<sub>2</sub>{" "}
                {t(
                  "is determined using the straight-line distance between the origin and destination sites according to the formula",
                )}
                :
              </p>
              <p className="font-semibold">
                {t("Mass")}(Tn) x Distance (km) x {t("Emission coefficient")}
              </p>
              <ul className="mb-2" style={{ marginLeft: 10 }}>
                <li>
                  {t("Emission coefficient")} = 0.12 kg CO<sub>2</sub>(Tn.km)
                </li>
                <li>{t("Average truck weight")} = 10 Tn</li>
              </ul>

              <p>
                {t(
                  "Please note that the tonnage does not take compaction into account",
                )}
                .{t("The density found in the material catergory is used")}.
              </p>
            </div>
          )}
          <div className="dashboard-card-footer">
            <Link
              to={{
                pathname: "/statistics",
                state: {
                  activateCollectionField: false,
                  activateQualityField: false,
                  activateCostField: false,
                  activateCarbonField: true,
                },
              }}
            >
              <div className="dashboard-text-underline">
                {t("View details")}
              </div>
            </Link>
          </div>
        </>
      )}
    </section>
  );
};
