import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Logo from "../../static/assets/logoHeader.png";
import { Form, Alert } from "reactstrap";
import FooterPage from "../../components/Footer.js";
import { formattedError } from "../../components/formatted/FormattedText";

const Login = ({ setCookies, saveUser, API }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [secure, setSecure] = useState(true);

  const history = useHistory();

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      const response = await axios.post(`${API}user/login`, {
        email: email,
        password: password,
      });

      setCookies(
        response.data.token,
        response.data.admin,
        response.data.firstName,
        response.data.email,
      );
      saveUser(response.data);
      setErrorMessage("");
      history.push("/");
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <div className="login-signin-container">
      <div className="col-left">
        <div className="login-form-container">
          <h1>S'identifier sur Akanthas</h1>

          <Form onSubmit={handleSubmit} className="login-form">
            <input
              placeholder="Email"
              type="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setErrorMessage("");
              }}
            />

            <div className="signin-input-password">
              <input
                placeholder="Mot de passe"
                type={secure ? "password" : "text"}
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  setErrorMessage("");
                }}
                required
              />
              <FontAwesomeIcon
                className="signin-icon-eye"
                size={24}
                icon={secure ? "eye" : "eye-slash"}
                color="#828899"
                onClick={() => setSecure(!secure)}
              />
              {errorMessage !== "" ? (
                <Alert color="info" className="signin-error-message">
                  {formattedError(errorMessage)}
                </Alert>
              ) : null}
            </div>

            <Link to="/forgotPassword" className="login-option">
              <span>Mot de passe oublié</span>
            </Link>

            <button className="identification-button bg-cyan" type="submit">
              S'IDENTIFIER
            </button>
          </Form>
        </div>
      </div>

      <div className="col-right">
        <div className="signin">
          <img src={Logo} alt="logo" />
        </div>
      </div>

      <FooterPage />
    </div>
  );
};

export default Login;
