import React, { useState } from "react";
import ReactSlider from "react-slider";
import { Card, Label } from "reactstrap";
import { DatePicker } from "reactstrap-date-picker";
import ToggleFilterButton from "../../ToggleFilterButton";
import Cookies from "js-cookie";

const ContainerFilter = ({
  keywordFilter,
  setKeywordFilter,
  batteryLevelFilter,
  setBatteryLevelFilter,
  setStartDateFilter,
  setStopDateFilter,
  dateBoundaries,
  setDateBoundaries,
}) => {
  const cookieValue = Cookies.get("cameraFilter");
  const parsedValue = cookieValue ? JSON.parse(cookieValue) : false;
  const [filterActive, setFilterActive] = useState(parsedValue);

  const [errorMessageFilter, setErrorMessageFilter] = useState("");
  const cleanFilters = () => {
    setKeywordFilter("");
    setBatteryLevelFilter([0, 100]);
    setStartDateFilter(null);
    setStopDateFilter(null);
  };

  function validationDate(start, stop, name) {
    const startDate = new Date(start);
    const stopDate = new Date(stop);
    if (startDate > stopDate) {
      setErrorMessageFilter("Les valeurs de dates ne sont pas correctes");
    } else {
      setErrorMessageFilter("");
      if (name === "start") {
        setDateBoundaries([start, dateBoundaries[1]]);
        setStartDateFilter(start);
      } else {
        setDateBoundaries([dateBoundaries[0], stop]);
        setStopDateFilter(stop);
      }
    }
  }
  return (
    <>
      <ToggleFilterButton
        cookiesKey="cameraFilter"
        filterActive={filterActive}
        setFilterActive={setFilterActive}
      />
      {filterActive && (
        <Card className="card-filter">
          {errorMessageFilter !== "" ? (
            <div className="error-form display-error">{errorMessageFilter}</div>
          ) : null}
          <section className="top-filter">
            <div className="table-filter">
              <span className="filter-name">Filtre par mot clé</span>
              <input
                className="operation-sort"
                type="text"
                id="word"
                name="word"
                value={keywordFilter}
                placeholder="Identifiant, "
                onChange={(event) => setKeywordFilter(event.target.value)}
              ></input>
            </div>

            <div className="table-filter">
              <span className="filter-name">Batterie</span>
              <ReactSlider
                className="horizontal-slider"
                thumbClassName="example-thumb"
                thumbActiveClassName="example-active-thumb"
                trackClassName="example-track"
                value={batteryLevelFilter}
                ariaLabel={["Lower thumb", "Upper thumb"]}
                ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                renderThumb={(props, state) => (
                  <div {...props}>{state.valueNow}</div>
                )}
                minDistance={10}
                onAfterChange={(value) => setBatteryLevelFilter(value)}
                pearling
              />
              <div className="label-slider-value">
                <p>0%</p>
                <p>100%</p>
              </div>
            </div>

            <div className="table-filter">
              <span className="filter-name">Dernier Scan</span>
              <div className={`filter-date-picker`}>
                <Label>Du</Label>
                <DatePicker
                  value={dateBoundaries[0]}
                  dateFormat="DD-MM-YYYY"
                  onChange={(event) => {
                    validationDate(event, dateBoundaries[1], "start");
                  }}
                  name="productionDateStart"
                />
                <Label>Au</Label>
                <DatePicker
                  value={dateBoundaries[1]}
                  dateFormat="DD-MM-YYYY"
                  onChange={(event) => {
                    setDateBoundaries([dateBoundaries[0], event]);
                    validationDate(dateBoundaries[0], event, "stop");
                  }}
                  name="productionDateStop"
                />
              </div>
            </div>

            <div className="table-filter">
              <button className="delete-button" onClick={cleanFilters}>
                Effacer les filtres
              </button>
            </div>
          </section>
        </Card>
      )}
    </>
  );
};

export default ContainerFilter;
