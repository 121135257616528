import React from "react";
import { IndexLinkContainer } from "react-router-bootstrap";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { MdDashboard } from "react-icons/md";
import { MdGamepad } from "react-icons/md";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import caracterisation from "../static/assets/caracterisation.png";

const NavigationBar = ({ setSideNavExpanded, sideNavExpanded }) => {
  const { t } = useTranslation();

  const isAkanthasAccount = (Cookies.get("userEmail") || "").includes(
    "@akanthas.com",
  );

  return (
    <SideNav
      style={{ backgroundColor: "#00A7A6", position: "fixed" }}
      onToggle={() => {
        Cookies.set("sideNavExpanded", JSON.stringify(!sideNavExpanded), {
          secure: true,
        });
        setSideNavExpanded(!sideNavExpanded);
      }}
      expanded={sideNavExpanded}
    >
      <SideNav.Toggle />
      <SideNav.Nav defaultSelected={"/access"}>
        <IndexLinkContainer to={"/"} activeClassName="selected">
          <NavItem eventKey="/">
            <NavIcon>
              <div
                style={{
                  fontSize: "1.6em",
                  display: "flex",
                  justifyContent: "center",
                  height: "50px",
                  margin: "auto",
                  alignItems: "center",
                }}
                className="nav-icon"
              >
                <MdDashboard />
              </div>
            </NavIcon>
            <NavText>{t("Dashboard")}</NavText>
          </NavItem>
        </IndexLinkContainer>

        {isAkanthasAccount && (
          <IndexLinkContainer to={"/digitaltwins"} activeClassName="selected">
            <NavItem eventKey="/digitaltwins">
              <NavIcon>
                <div
                  style={{
                    fontSize: "1.6em",
                    display: "flex",
                    justifyContent: "center",
                    height: "50px",
                    margin: "auto",
                    alignItems: "center",
                  }}
                  className="nav-icon"
                >
                  <MdGamepad />
                </div>
              </NavIcon>
              <NavText>{t("Digital Twins")}</NavText>
            </NavItem>
          </IndexLinkContainer>
        )}

        <IndexLinkContainer to={"/containers"} activeClassName="selected">
          <NavItem eventKey="/containers">
            <NavIcon>
              <i
                className="fas fa-list nav-icon"
                style={{ fontSize: "1.3em" }}
              ></i>
            </NavIcon>
            <NavText>{t("Monitoring")}</NavText>
          </NavItem>
        </IndexLinkContainer>

        <IndexLinkContainer
          to={"/characterizations"}
          activeClassName="selected"
        >
          <NavItem eventKey="/characterizations">
            <NavIcon>
              <img
                alt="characterizations"
                style={{
                  width: 20,
                  marginTop: 13,
                  marginLeft: 20,
                }}
                src={caracterisation}
              />
            </NavIcon>
            <NavText>{t("Characterization")}s</NavText>
          </NavItem>
        </IndexLinkContainer>

        <IndexLinkContainer to={"/statistics"} activeClassName="selected">
          <NavItem eventKey="/statistics">
            <NavIcon>
              <i
                className="far fa-chart-bar nav-icon"
                style={{ fontSize: "1.3em" }}
              ></i>
            </NavIcon>
            <NavText>
              {t("Summaries")} & {t("Metrics")}
            </NavText>
          </NavItem>
        </IndexLinkContainer>

        <IndexLinkContainer to={"/activity"} activeClassName="selected">
          <NavItem eventKey="/activity">
            <NavIcon>
              <i
                className="fas fa-history nav-icon"
                style={{ fontSize: "1.3em" }}
              ></i>
            </NavIcon>
            <NavText>{t("Activities")}</NavText>
          </NavItem>
        </IndexLinkContainer>

        <IndexLinkContainer to={"/settings"} activeClassName="selected">
          <NavItem eventKey="/settings">
            <NavIcon>
              <i
                className="fas fa-cog nav-icon"
                style={{ fontSize: "1.3em" }}
              ></i>
            </NavIcon>
            <NavText>{t("Settings")}</NavText>
          </NavItem>
        </IndexLinkContainer>
      </SideNav.Nav>
    </SideNav>
  );
};

export default NavigationBar;
