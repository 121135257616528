import { G, Image, Polygon, Svg } from "@react-pdf/renderer";
import { azureSASTokenToUrl } from "../../formatted/FormattedAzureSasToken";
import { formattedColorMaterial } from "../../formatted/FormattedText";

function convertPoints(pointList, ratioImage) {
  let result = "";
  for (const point of pointList) {
    result += point * ratioImage + ",";
  }
  return result;
}

function deltaBox(point, ratioImage) {
  return Math.max(point * ratioImage, 0);
}

function getWidthOrHeightBox(dimensionBox, pointBox, sizeImage, ratioImage) {
  const maxPointBox = deltaBox(pointBox, ratioImage);
  const newDimensionBox = dimensionBox * ratioImage;
  if (maxPointBox + newDimensionBox > sizeImage * ratioImage) {
    return sizeImage * ratioImage - maxPointBox;
  } else {
    return newDimensionBox;
  }
}

export const ImageComponent = ({ data, drawMaterial }) => {
  const isLandscapeCrop = data.h_box / data.w_box < 0.5 ? true : false;
  let imageSize;
  if (isLandscapeCrop) {
    imageSize = data.h_box / data.w_box > 0.4 ? 670 : 820;
  } else {
    imageSize = data.h_box / data.w_box > 0.75 ? 400 : 820;
  }
  const ratioImage = imageSize / Math.max(data.w_box, data.h_box);

  return (
    <Svg
      width={getWidthOrHeightBox(
        data.w_box,
        data.x_box,
        data.imageDimensions.width,
        ratioImage,
      )}
      height={getWidthOrHeightBox(
        data.h_box,
        data.y_box,
        data.imageDimensions.heigth,
        ratioImage,
      )}
      viewBox={`${deltaBox(data.x_box, ratioImage)}
                ${deltaBox(data.y_box, ratioImage)}
                ${getWidthOrHeightBox(data.w_box, data.x_box, data.imageDimensions.width, ratioImage)}
                ${getWidthOrHeightBox(data.h_box, data.y_box, data.imageDimensions.heigth, ratioImage)}`}
      style={{
        marginVertical: isLandscapeCrop ? "5px" : "1px",
        marginHorizontal: isLandscapeCrop ? "auto" : "0px",
        backgroundColor: "#FFF",
        marginBottom: drawMaterial ? 15 : null,
      }}
    >
      <Image
        x={0}
        y={0}
        style={{
          width: data.imageDimensions.width * ratioImage,
          height: data.imageDimensions.heigth * ratioImage,
        }}
        src={azureSASTokenToUrl(data.image_url)}
      />
      {data.wasteObjectsList !== undefined &&
        data.wasteObjectsList.length > 0 &&
        data.wasteObjectsList.map((object, index) => {
          if (drawMaterial && object.cls !== undefined) {
            return (
              <G key={`annotation-${object._id}`}>
                <Polygon
                  points={convertPoints(object.points_konva, ratioImage)}
                  fill={formattedColorMaterial(object.cls)}
                  stroke={formattedColorMaterial(object.cls)}
                  strokeWidth={1}
                  opacity="0.25"
                />
                <Polygon
                  points={convertPoints(object.points_konva, ratioImage)}
                  stroke={formattedColorMaterial(object.cls)}
                  strokeWidth={1}
                />
              </G>
            );
          }
          return null;
        })}
    </Svg>
  );
};
