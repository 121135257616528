import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormGroup,
  Form,
} from "reactstrap";
import {
  handleAddFormSubmit,
  handleFormChange,
} from "../../../components/modals/EditableTable";
import { toast } from "react-toastify";
import Outlet from "./Outlet";

const AddressCreate = ({
  addFormData,
  setAddFormData,
  defaultData,
  tableData,
  setTableData,
  totalEntry,
  setTotalEntry,
  modalCreate,
  setModalCreate,
}) => {
  const path = "address";
  const [errorForm, setErrorForm] = useState("");
  const [showGeneralInformations, setShowGeneralInformations] = useState(true);
  const [showOutlet, setShowOutlet] = useState(false);
  // Allows you to close the modal without pressing the close button
  const toggle = () => setModalCreate(!modalCreate);

  return (
    <Modal isOpen={modalCreate} toggle={toggle}>
      <ModalHeader>
        <h2>Créer une nouvelle adresse</h2>
        <CloseIcon
          style={{
            color: "var(--gray)",
            cursor: "pointer",
          }}
          onClick={() => {
            setModalCreate(false);
            setAddFormData(defaultData);
          }}
        />
      </ModalHeader>

      <Form
        onSubmit={async (event) => {
          const response = await handleAddFormSubmit(
            event,
            addFormData,
            setAddFormData,
            defaultData,
            tableData,
            setTableData,
            totalEntry,
            setTotalEntry,
            path,
            setErrorForm,
          );
          if (response.status === 200) {
            setModalCreate(false);
            toast.success("L'adresse a bien été ajoutée", {
              className: "custom-toast",
            });
          }
        }}
      >
        <section className="flex flex-col p-3">
          <section className="flex max-md:flex-col text-base text-cyan ">
            <div
              className={`mr-2 px-1 cursor-pointer ${showGeneralInformations ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(true);
                setShowOutlet(false);
              }}
            >
              Infos générales
            </div>
            <div
              className={`mr-2 px-1 cursor-pointer ${showOutlet ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(false);
                setShowOutlet(true);
              }}
            >
              Filière/Exutoire
            </div>
          </section>
        </section>
        <ModalBody>
          {showGeneralInformations && (
            <section>
              <section
                className={`error-form ${errorForm ? "display-error" : ""}`}
              >
                <p>{errorForm}</p>
              </section>

              <article className="information-bloc">
                <FormGroup className="information-line toto">
                  <Label className="information-label">Nom</Label>
                  <Input
                    className="information-column-data"
                    type="text"
                    required="required"
                    placeholder="Nom"
                    value={addFormData.name}
                    onChange={(event) =>
                      handleFormChange(event, addFormData, setAddFormData)
                    }
                    name="name"
                  />
                </FormGroup>

                <FormGroup className="information-line">
                  <Label className="information-label">Rôle</Label>
                  <select
                    type="select"
                    value={addFormData.role}
                    onChange={(event) =>
                      handleFormChange(event, addFormData, setAddFormData)
                    }
                    name="role"
                  >
                    <option value="sorting_plant">C. de tri</option>
                    <option value="maintenance_centre">
                      C. de maintenance
                    </option>
                    <option value="client">Client</option>
                    <option value="building_site">Chantier</option>
                    <option value="dump">Déchetterie</option>
                    <option value="industrial_site">Site industriel</option>
                    <option value="landfill_site">
                      Centre d'enfouissement
                    </option>
                    <option value="incineration_centre">
                      Centre d'incinération
                    </option>
                  </select>
                </FormGroup>
              </article>

              <article className="information-bloc">
                <h3>Informations</h3>

                <FormGroup className="information-line">
                  <Label className="information-label">N° de rue</Label>
                  <Input
                    className="information-column-data"
                    type="text"
                    placeholder="Numéro de rue"
                    value={addFormData.number}
                    onChange={(event) =>
                      handleFormChange(event, addFormData, setAddFormData)
                    }
                    name="number"
                  />
                </FormGroup>

                <FormGroup className="information-line">
                  <Label className="information-label">Nom de rue</Label>
                  <Input
                    className="information-column-data"
                    type="text"
                    placeholder="Nom de rue"
                    value={addFormData.street}
                    onChange={(event) =>
                      handleFormChange(event, addFormData, setAddFormData)
                    }
                    name="street"
                  />
                </FormGroup>

                <FormGroup className="information-line">
                  <Label className="information-label">Code Postal</Label>
                  <Input
                    className="information-column-data"
                    type="number"
                    required="required"
                    placeholder="Code Postale"
                    value={addFormData.postalCode}
                    onChange={(event) =>
                      handleFormChange(event, addFormData, setAddFormData)
                    }
                    name="postalCode"
                    min="00000"
                    max="99999"
                    maxLength={5}
                  />
                </FormGroup>

                <FormGroup className="information-line">
                  <Label className="information-label">Ville</Label>
                  <Input
                    className="information-column-data"
                    type="text"
                    required="required"
                    placeholder="Ville"
                    value={addFormData.city}
                    onChange={(event) =>
                      handleFormChange(event, addFormData, setAddFormData)
                    }
                    name="city"
                  />
                </FormGroup>
              </article>

              <article className="information-bloc">
                <h3>Horaires d'ouvertures</h3>

                <FormGroup className="information-line">
                  <Label className="information-label">Lundi</Label>
                  <div>
                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Matin
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="1"
                        indexarray="0"
                        indexopening="0"
                        value={addFormData.opening["1"]["0"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="1"
                        indexarray="0"
                        indexopening="1"
                        value={addFormData.opening["1"]["0"]["1"]}
                      />
                    </article>

                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Après-midi
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="1"
                        indexarray="1"
                        indexopening="0"
                        value={addFormData.opening["1"]["1"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="1"
                        indexarray="1"
                        indexopening="1"
                        value={addFormData.opening["1"]["1"]["1"]}
                      />
                    </article>
                  </div>
                </FormGroup>

                <FormGroup className="information-line">
                  <Label className="information-label">Mardi</Label>
                  <div>
                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Matin
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="2"
                        indexarray="0"
                        indexopening="0"
                        value={addFormData.opening["2"]["0"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="2"
                        indexarray="0"
                        indexopening="1"
                        value={addFormData.opening["2"]["0"]["1"]}
                      />
                    </article>

                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Après-midi
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="2"
                        indexarray="1"
                        indexopening="0"
                        value={addFormData.opening["2"]["1"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="2"
                        indexarray="1"
                        indexopening="1"
                        value={addFormData.opening["2"]["1"]["1"]}
                      />
                    </article>
                  </div>
                </FormGroup>

                <FormGroup className="information-line">
                  <Label className="information-label">Mercredi</Label>
                  <div>
                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Matin
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="3"
                        indexarray="0"
                        indexopening="0"
                        value={addFormData.opening["3"]["0"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="3"
                        indexarray="0"
                        indexopening="1"
                        value={addFormData.opening["3"]["0"]["1"]}
                      />
                    </article>

                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Après-midi
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="3"
                        indexarray="1"
                        indexopening="0"
                        value={addFormData.opening["3"]["1"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="3"
                        indexarray="1"
                        indexopening="1"
                        value={addFormData.opening["3"]["1"]["1"]}
                      />
                    </article>
                  </div>
                </FormGroup>

                <FormGroup className="information-line">
                  <Label className="information-label">Jeudi</Label>
                  <div>
                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Matin
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="4"
                        indexarray="0"
                        indexopening="0"
                        value={addFormData.opening["4"]["0"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="4"
                        indexarray="0"
                        indexopening="1"
                        value={addFormData.opening["4"]["0"]["1"]}
                      />
                    </article>

                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Après-midi
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="4"
                        indexarray="1"
                        indexopening="0"
                        value={addFormData.opening["4"]["1"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="4"
                        indexarray="1"
                        indexopening="1"
                        value={addFormData.opening["4"]["1"]["1"]}
                      />
                    </article>
                  </div>
                </FormGroup>

                <FormGroup className="information-line">
                  <Label className="information-label">Vendredi</Label>
                  <div>
                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Matin
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="5"
                        indexarray="0"
                        indexopening="0"
                        value={addFormData.opening["5"]["0"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="5"
                        indexarray="0"
                        indexopening="1"
                        value={addFormData.opening["5"]["0"]["1"]}
                      />
                    </article>

                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Après-midi
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="5"
                        indexarray="1"
                        indexopening="0"
                        value={addFormData.opening["5"]["1"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="5"
                        indexarray="1"
                        indexopening="1"
                        value={addFormData.opening["5"]["1"]["1"]}
                      />
                    </article>
                  </div>
                </FormGroup>

                <FormGroup className="information-line">
                  <Label className="information-label">Samedi</Label>
                  <div>
                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Matin
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="6"
                        indexarray="0"
                        indexopening="0"
                        value={addFormData.opening["6"]["0"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="6"
                        indexarray="0"
                        indexopening="1"
                        value={addFormData.opening["6"]["0"]["1"]}
                      />
                    </article>

                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Après-midi
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="6"
                        indexarray="1"
                        indexopening="0"
                        value={addFormData.opening["6"]["1"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="6"
                        indexarray="1"
                        indexopening="1"
                        value={addFormData.opening["6"]["1"]["1"]}
                      />
                    </article>
                  </div>
                </FormGroup>

                <FormGroup className="information-line">
                  <Label className="information-label">Dimanche</Label>
                  <div>
                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Matin
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="0"
                        indexarray="0"
                        indexopening="0"
                        value={addFormData.opening["0"]["0"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="0"
                        indexarray="0"
                        indexopening="1"
                        value={addFormData.opening["0"]["0"]["1"]}
                      />
                    </article>

                    <article className="information-bloc-column">
                      <Label className="information-data-multiple information-label">
                        Après-midi
                      </Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="0"
                        indexarray="1"
                        indexopening="0"
                        value={addFormData.opening["0"]["1"]["0"]}
                      />
                      <Label className="information-data-multiple">à</Label>
                      <Input
                        className="information-data information-data-multiple"
                        type="time"
                        placeholder="Entrer une valeur"
                        dictcategory="opening"
                        onChange={(event) =>
                          handleFormChange(event, addFormData, setAddFormData)
                        }
                        name="0"
                        indexarray="1"
                        indexopening="1"
                        value={addFormData.opening["0"]["1"]["1"]}
                      />
                    </article>
                  </div>
                </FormGroup>
              </article>

              <article className="information-bloc">
                <h3>Coordonnées GPS</h3>

                <FormGroup className="information-line">
                  <Label className="information-label">Latitude *</Label>
                  <Input
                    className="information-column-data"
                    type="number"
                    required="required"
                    placeholder="40.7128"
                    value={addFormData.location[0]}
                    onChange={(event) =>
                      handleFormChange(event, addFormData, setAddFormData)
                    }
                    dictcategory="location"
                    name="0"
                    step="0.00000000000000001"
                    min="-90"
                    max="90"
                  />
                </FormGroup>

                <FormGroup className="information-line">
                  <Label className="information-label">Longitude *</Label>
                  <Input
                    className="information-column-data"
                    type="number"
                    required="required"
                    placeholder="-74.0060"
                    value={addFormData.location[1]}
                    onChange={(event) =>
                      handleFormChange(event, addFormData, setAddFormData)
                    }
                    dictcategory="location"
                    name="1"
                    step="0.00000000000000001"
                    min="-180"
                    max="180"
                  />
                </FormGroup>
              </article>
            </section>
          )}

          {showOutlet && (
            <Outlet
              editFormData={addFormData}
              setEditFormData={setAddFormData}
              handleFormChange={handleFormChange}
              errorForm={errorForm}
            />
          )}
        </ModalBody>
        <ModalFooter className="information-footer-modal">
          <button className="button-submit" type="submit">
            Sauvegarder
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddressCreate;
