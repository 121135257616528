import { Doughnut, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const DoughnutAndPieChart = ({
  countTotal,
  colorChart,
  dataChart,
  displayLegend,
  diameterCutout,
  labelChart,
  legendPosition,
  subtextCenterPlugin,
  textCenterPlugin,
  titleTooltip,
  type,
  activePluginChartLabel,
}) => {
  const data = {
    labels: labelChart,
    datasets: [
      {
        type: type,
        borderWidth: 2,
        data: dataChart,
        backgroundColor: colorChart,
        borderColor: colorChart,
        hoverOffset: 5,
        cutout: `${diameterCutout}%`,
        spacing: 0.5,
      },
    ],
  };

  let optionsChart = {
    textCenterPlugin,
    plugins: {
      legend: {
        position: legendPosition,
        display: displayLegend,
        align: "center",
        maxWidth: 600,
        fullSize: true,
        rtl: false,
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          padding: 15,
          textAlign: "left",
          usePointStyle: true,
          useBorderRadius: true,
          borderRadius: 30,
          font: {
            size: 10,
          },
        },
        title: {
          display: false,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#f8AA00",
        titleAlign: "center",
        callbacks: {
          title: function (context) {
            return titleTooltip;
          },
          label: function (context) {
            const value = context.dataset.data[context.dataIndex] || 0;
            const label = context.label || "";
            const percentageAnomaly = ((value / countTotal) * 100).toFixed(1);
            return countTotal ? `${percentageAnomaly}% ${label}` : "";
          },
        },
      },
      datalabels: {
        borderRadius: 8,
        color: "#fff",
        anchor: "left",
        clamp: false,
        align: "center",
        font: {
          weight: "bold",
          size: 14,
        },
        formatter: (value, ctx) => {
          const percentageValue = ((value / countTotal) * 100).toFixed(1);
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return percentageValue > 10 ? `${label}\n${percentageValue}%` : "";
        },
        textAlign: "center",
        display: "auto",
      },
      aspectRatio: 1 / 2,
      layout: {
        padding: 40,
      },
    },
  };

  const elementCenterPlugin = {
    id: "elementCenterPlugin",
    beforeDraw: (chart) => {
      const { ctx } = chart;
      ctx.save();

      ctx.font = 'bolder 48px "Mulish", sans-serif';
      ctx.fillStyle = "#E95280";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      ctx.fillText(
        `${chart.config.options.textCenterPlugin}`,
        chart.getDatasetMeta(0).data[0] !== undefined &&
          chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0] !== undefined &&
          chart.getDatasetMeta(0).data[0].y - 20,
      );
    },
  };

  const elementCenterBottomPlugin = {
    id: "elementCenterBottom",
    beforeDraw: (chart) => {
      const { ctx } = chart;
      ctx.save();

      ctx.font = 'bolder 14px "Mulish", sans-serif';
      ctx.fillStyle = "#E95280";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      ctx.fillText(
        `${subtextCenterPlugin}`,
        chart.getDatasetMeta(0).data[0] !== undefined &&
          chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0] !== undefined &&
          chart.getDatasetMeta(0).data[0].y + 20,
      );
    },
  };

  if (type === "doughnut") {
    return (
      <Doughnut
        data={data}
        options={optionsChart}
        plugins={[
          activePluginChartLabel ? ChartDataLabels : "",
          elementCenterPlugin,
          elementCenterBottomPlugin,
        ]}
      />
    );
  } else {
    return <Pie data={data} options={optionsChart} />;
  }
};

export default DoughnutAndPieChart;
