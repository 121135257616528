import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  status: "idle",
  error: null,
};

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    toggleCollection: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { toggleCollection } = collectionSlice.actions;
