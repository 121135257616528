import React from "react";

const ConfidentialityAgreement = () => {
  return (
    <div className="base">
      <h1>Politique de confidentialité</h1>
      <h5>1. INTRODUCTION</h5>
      <p>
        La protection de vos données personnelles est une préoccupation majeure
        pour AKANTHAS. C’est pourquoi, AKANTHAS, s’engage à ne collecter que les
        données nécessaires à assurer un service optimal, dans le respect de la
        confidentialité et de la sécurité, y compris lorsque nous avons recours
        à des prestataires et à faciliter l’exercice de vos droits sur vos
        données. AKANTHAS s’engage à respecter toutes les dispositions
        applicables en matière de protection de la vie privée, à protéger les
        données à caractère personnel et notamment les dispositions concernant
        la loi du 6 janvier 1978 modifiée relative à l’informatique, aux
        fichiers et aux libertés ainsi que le Règlement UE 2016/679 du 27 avril
        2016 relatif à la protection des personnes physiques à l’égard du
        traitement des données à caractère personnel et à la libre circulation
        de ces données. La présente politique de confidentialité présente les
        données personnelles recueillies par AKANTHAS, leur utilisation ainsi
        que vos droits à cet égard. Elle s’applique à tout utilisateur qui
        accède à l’application et qui utilise les services associés. AKANTHAS se
        réserve le droit d’apporter des modifications à sa politique de
        confidentialité à tout instant. L’utilisation de vos données est régie
        par la version la plus récente de la présente politique de
        confidentialité qui restera constamment à disposition sur l’application
        web ou sur demande auprès de AKANTHAS. AKANTHAS s’engage à vous notifier
        par email toute modification substantielle apportée à cette politique de
        confidentialité.
      </p>
      ​<h5>2. RESPONSABLE DU TRAITEMENT DE VOS DONNEES</h5>
      <p>
        ​AKANTHAS
        <br />
        Société en formation (par actions simplifiée)
        <br />
        Siège social : 9 Place de Bologne 31000 TOULOUSE
        <br />
        Représentée par Nicolas Jaouen, Directeur Général
      </p>
      ​<h5>3. DONNEES COLLECTEES</h5>
      <p>
        ​ Les catégories de données que AKANTHAS collecte lors de l’utilisation
        de nos services et de notre application sont décrites ci-dessous. Ces
        données sont issues :
        <ul>
          <li />• Des informations que vous nous transmettez lors de votre
          inscription, ou lors de vos échanges avec AKANTHAS. Ces données
          personnelles sont :
          <li />• des informations permettant d’assurer votre identification sur
          notre application (nom, prénom, numéro de téléphone, email, mot de
          passe),
          <li />• Le token d’identification créé par le serveur AKANTHAS. Cet
          identifiant se présente sous la forme d’une liste de caractères
          uniques et est utilisé pour vous identifier lors des communications
          avec notre serveur.
          <li />• Des informations de localisation
        </ul>
        Nous vous demandons l’autorisation pour collecter sur notre application
        les données précises de localisation de votre mobile via le système
        d’autorisation que le système d’exploitation de votre mobile utilise. Si
        vous autorisez la collecte de ces données, vous pouvez revenir
        ultérieurement sur votre choix en changeant les paramètres de
        localisation de votre téléphone portable. Cependant, cela limitera votre
        capacité à utiliser certaines fonctionnalités de nos services.
        <ul>
          <li />• Des données d’identification de contenants via la lecture d’un
          code QR
        </ul>
        Nous vous demandons l’autorisation pour accéder à l’appareil photo de
        votre mobile via le système d’autorisation que le système d’exploitation
        de votre mobile utilise. Cette prise de photo est dédiée uniquement à
        l’activation de notre programme d’identification de codes QR. Si vous
        autorisez cet accès à votre appareil photo, vous pouvez revenir
        ultérieurement sur votre choix en changeant les paramètres de
        localisation de votre téléphone portable. Cependant, cela limitera votre
        capacité à utiliser l’ensemble de notre service. Nous ne traitons pas de
        catégories particulières de données (données sensibles) telles que les
        données énumérées à l’article 9 du RGPD qui révèlent l’origine raciale
        ou ethnique, les opinions politiques, les convictions religieuses ou
        philosophiques ou l’appartenance syndicale, des données génétiques, des
        données biométriques, des données concernant la santé ou des données
        concernant la vie sexuelle ou l’orientation sexuelle des personnes.
      </p>
      ​<h5>4. FINALITES</h5>
      <p>
        ​ AKANTHAS procède au traitement de vos données à caractère personnel
        pour des finalités déterminées, explicites et légitimes. En particulier,
        ces données sont destinées à :
        <ul>
          <li />• la création et la gestion de votre compte ;
          <li />• l’utilisation de nos services ;
          <li />• vous adresser des communications pertinentes et adaptées à vos
          besoins directement en lien avec les services du site ;
          <li />• sécuriser le site ainsi que les données et échanges
          intervenant sur le site ;
          <li />• faire évoluer nos services pour vous offrir de nouvelles
          fonctionnalités et nous adapter à vos besoins ;
          <li />• communiquer avec vous ;
          <li />• répondre à une injonction des autorités légales et notamment
          pour lutter contre la fraude et plus généralement contre toute
          activité pénalement répréhensible.
        </ul>
      </p>
      <h5>5. BASE JURIDIQUE DES TRAITEMENTS</h5>
      <p>
        ​ AKANTHAS traite la plupart de vos données personnelles dans le cadre
        du contrat que vous avez conclu lors de votre inscription sur
        l’application via l’acceptation de nos conditions générales
        d’utilisation. Toutefois, nous sommes susceptibles de traiter certaines
        données vous concernant sur la base de votre consentement, en raison
        d’obligations légales ou pour répondre à notre intérêt légitime à les
        traiter. AKANTHAS vous propose ainsi de vous inscrire à sa newsletter si
        vous souhaitez recevoir des communications pertinentes en lien avec les
        services proposés sur l’application.
      </p>
      <h5>6. DESTINATAIRES OU CATEGORIES DE DESTINATAIRES</h5>
      <p>
        AKANTHAS est destinataire des données à caractère personnelle
        recueillies. Nous pourrions également être susceptibles de partager
        certaines informations pour des raisons juridiques ou en cas de litige.
        Enfin, nous ne communiquons vos données personnelles à des entreprises
        ou des personnes tierces que dans les circonstances suivantes :
        <ul>
          <li />• Lorsque cela est nécessaire pour des besoins de traitement
          externe, et uniquement dans ce cas, nous transmettons ces données à
          nos prestataires de confiance qui les traitent pour notre compte,
          selon nos instructions ou selon un accord contractuel, conformément à
          la présente politique de confidentialité et dans le respect de toute
          autre mesure appropriée de sécurité et de confidentialité. Nous sommes
          susceptibles de leur transmettre vos données personnelles uniquement
          pour les finalités énoncées ci-dessous. Ainsi, nous faisons notamment
          appel à nos prestataires chargés d’assurer l’hébergement et la
          sécurité de nos systèmes d’information et de notre site (notamment
          Heroku et MongoDB Atlas). La liste de nos sous-traitants peut vous
          être communiquée en adressant votre demande à contact@akanthas.com.
          <li />• Nous conservons ou divulguons vos informations si nous
          estimons que cela est raisonnablement nécessaire pour satisfaire à
          toute obligation légale ou réglementaire, toute procédure juridique ou
          demande administrative, pour protéger la sécurité d’une personne, pour
          traiter tout problème de nature frauduleuse, sécuritaire ou technique,
          ou pour protéger les droits ou les biens de nos utilisateurs. AKANTHAS
          ne revend pas vos données et ne transmettra jamais vos données
          personnelles à aucun tiers susceptible de les utiliser à ses propres
          fins et notamment à des fins commerciales et/ou de publicité directe,
          sans votre consentement exprès. Par conséquent, AKANTHAS ne divulgue
          pas d’informations personnelles en dehors des situations décrites dans
          la présente politique de confidentialité.
        </ul>
      </p>
      <h5>7. TRANSFERT DES DONNEES HORS UE</h5>
      <p>
        Tous nos serveurs sur lesquels vos données sont conservées et ceux des
        prestataires utilisés pour échanger et stocker ces données sont
        localisées en Europe. Dans l’hypothèse où AKANTHAS aurait recours à des
        sous-traitants situés en dehors de l’Union européenne, elle s’engage à
        s’assurer que ses sous-traitants présentent des mesures de protection
        reconnues comme suffisantes au sens du RGPD. Il peut notamment s’agir de
        sous-traitants situés dans tout autre pays reconnu par l’Union
        européenne comme assurant un niveau de protection adéquat des données à
        caractère personnel (« Décision d’adéquation »), faisant l’objet d’un
        accord de transfert de données conforme aux clauses contractuelles types
        adoptées par la Commission Européenne ou, tout autre mesure de
        protection reconnue comme suffisante par la Commission Européenne.
      </p>
      <h5>8. DUREE DE CONSERVATION DES DONNEES</h5>
      <p>
        AKANTHAS conserve vos informations tant que votre compte reste actif, à
        moins que vous ne demandiez leur suppression ou celle de votre compte.
        Dans certains cas, nous pouvons conserver des informations vous
        concernant en raison de la loi ou à d’autres fins, même si vous
        supprimez votre compte. Ainsi, les données utilisées à des fins de
        prospection commerciale peuvent être conservées pendant un délai de
        trois ans à compter de la suppression de votre compte, sauf si vous avez
        décidé de faire jouer votre droit d’opposition dans les conditions
        prévues à l’article 10. Par ailleurs, les données permettant d’établir
        la preuve d’un droit ou d’un contrat, ou conservées au titre du respect
        d’une obligation légale, peuvent faire l’objet d’une politique
        d’archivage intermédiaire pour une période correspondant aux durée de
        prescription légale (et notamment le délai de droit commun de 5 ans).
      </p>
      <h5>9. SECURITE DES DONNEES</h5>
      <p>
        AKANTHAS met en œuvre les mesures techniques et organisationnelles
        appropriées afin de garantir la sécurité, la confidentialité,
        l’intégrité et la disponibilité des services et protéger les données
        contre la destruction, la perte, l’altération, la divulgation non
        autorisée de données à caractère personnel transmises, conservées ou
        traitées d’une autre manière, ou l’accès non autorisé à de telles
        données. AKANTHAS s’engage à mettre en œuvre tous les moyens disponibles
        pour assurer la sécurité et la confidentialité de ces données, en
        particulier :
        <ul>
          <li />• Nous chiffrons la plupart de nos services à l’aide de la
          technologie https ;
          <li />• L’accès à votre compte se fait au moyen d’un identifiant et
          d’un mot de passe sécurisé ;
          <li />• L’accès aux données personnelles est strictement réservé aux
          salariés et prestataires de AKANTHAS qui ont besoin d’y accéder afin
          de les traiter en notre nom. Ces personnes sont soumises à de strictes
          obligations de confidentialité.
        </ul>
      </p>
      <h5>10. VOS DROITS</h5>
      <p>
        Conformément à la réglementation sur les données personnelles, vous
        disposez des droits suivants :
        <ul>
          <li />• droit d’accès (article 15 du RGPD),
          <li />• droit de rectification (article 16 du RGPD),
          <li />• droit d’effacement (article 17 du RGPD),
          <li />• droit de limitation du traitement (article 18 du RGPD),
          <li />• droit de portabilité (article 20 du RGPD),
          <li />• droit d’opposition (article 21 et 22 du RGPD),
        </ul>
      </p>
      <p>
        Droits d’accès Vous avez la possibilité d’obtenir de AKANTHAS la
        confirmation que les données vous concernant sont ou ne sont pas
        traitées et, lorsqu’elles le sont, l’accès auxdites données ainsi que
        les informations suivantes :
        <ul>
          <li />• les finalités du traitement ;
          <li />• les catégories de données ;
          <li />• les destinataires ou catégories de destinataires auxquels les
          données ont été ou seront communiquées ;
          <li />• lorsque cela est possible, la durée de conservation des
          données envisagée ou, lorsque ce n’est pas possible, les critères
          utilisés pour déterminer cette durée ;
          <li />• l’existence du droit de demander à AKANTHAS la rectification
          ou l’effacement de données, ou une limitation du traitement de vos
          données, ou du droit de s’opposer à ce traitement ;
          <li />• le droit d’introduire une réclamation auprès de la CNIL ;
          <li />• lorsque les données ne sont pas collectées auprès de vous,
          toute information disponible quant à leur source ; l’existence d’une
          prise de décision automatisée, y compris un profilage, et, au moins en
          pareils cas, des informations utiles concernant la logique
          sous-jacente, ainsi que l’importance et les conséquences prévues de ce
          traitement pour vous.
        </ul>
        Lorsque les données sont transférées vers un pays tiers ou à une
        organisation internationale, vous avez le droit d’être informé des
        garanties appropriées, en ce qui concerne ce transfert. Votre droit
        d’obtenir une copie de vos données ne doit pas porter pas atteinte aux
        droits et libertés d’autrui.
      </p>
      <p>
        Droits de rectification Vous avez la possibilité d’obtenir de AKANTHAS,
        dans les meilleurs délais, la rectification des données vous concernant
        qui sont inexactes. Vous avez également la possibilité d’obtenir que les
        données incomplètes soient complétées, y compris en fournissant une
        déclaration complémentaire.
      </p>
      <p>
        Afin de vous permettre d’exercer le plus facilement possible ce droit,
        nous vous invitons à procéder directement à ces modifications et
        compléments sur votre compte. Si vous estimez que d’autres données vous
        concernant doivent être modifiées ou complétées et que vous ne parvenez
        pas à effectuer ce changement par vous-même, nous vous invitons à en
        faire la demande directement auprès de nous en nous contactant.
      </p>
      <p>
        Droits à l’effacement Vous avez la possibilité d’obtenir de AKANTHAS
        l’effacement, dans les meilleurs délais, de données vous concernant
        lorsque l’un des motifs suivants s’applique :
        <ul>
          <li />• les données ne sont plus nécessaires au regard des finalités
          pour lesquelles elles ont été collectées ou traitées d’une autre
          manière par AKANTHAS ;
          <li />• vous avez retiré votre consentement pour le traitement de ces
          données et il n’existe pas d’autre fondement juridique au traitement ;
          <li />• vous exercez votre droit d’opposition dans les conditions
          rappelées ci-après et il n’existe pas de motif légitime impérieux pour
          le traitement ;
          <li />• les données ont fait l’objet d’un traitement illicite ;
          <li />• les données doivent être effacées pour respecter une
          obligation légale ;
          <li />• les données ont été collectées auprès d’un enfant.
        </ul>
      </p>
      <p>
        Droits à la limitation Vous avez la possibilité d’obtenir de AKANTHAS la
        limitation du traitement de vos données lorsque l’un des motifs suivants
        s’applique :
        <ul>
          <li />• AKANTHAS vérifie l’exactitude des données suite à votre
          contestation de l’exactitude des données,
          <li />• le traitement est illicite et vous vous opposez l’effacement
          des données et exigez à la place la limitation de leur utilisation ;
          <li />• AKANTHAS n’a plus besoin des données aux fins du traitement
          mais celles-ci vous sont encore nécessaires pour la constatation,
          l’exercice ou la défense de droits en justice ;
          <li />• Vous vous êtes opposés au traitement dans les conditions
          rappelées ci-après et AKANTHAS vérifie de savoir si les motifs
          légitimes poursuivis prévalent sur vos motifs allégués.
        </ul>
      </p>
      <p>
        Droit à la portabilité des données Vous avez la possibilité de recevoir
        de AKANTHAS les données vous concernant, dans un format structuré,
        couramment utilisé et lisible par machine lorsque :
        <ul>
          <li />• le traitement des données est fondé sur le consentement, ou
          sur un contrat et
          <li />• le traitement est effectué à l’aide de procédés automatisés.
        </ul>
      </p>
      <p>
        Lorsque vous exercez votre droit à la portabilité vous avez le droit
        d’obtenir que les données soient transmises directement par AKANTHAS à
        un responsable de traitement que vous désignerez lorsque cela est
        techniquement possible. Votre droit à la portabilité de vos données ne
        doit pas porter atteinte aux droits et libertés d’autrui.
        <br />
        Droit d’opposition
        <br />
        Vous avez le droit de vous opposer à tout moment, pour des raisons
        tenant à votre situation particulière, à un traitement des données vous
        concernant fondé sur l’intérêt légitime de AKANTHAS. AKANTHAS ne
        traitera alors plus les données, à moins qu’elle ne démontre qu’il
        existe des motifs légitimes et impérieux pour le traitement qui
        prévalent sur vos intérêts et vos droits et libertés, ou pourra les
        conserver pour la constatation, l’exercice ou la défense de droits en
        justice.
        <br />
        Vous pouvez vous opposer à l’envoi de communications. Nous mettons à
        cette fin à votre disposition plusieurs liens de désinscription sur tous
        les courriels que nous vous adressons.
        <br />
        ***
        <br />
        Notez que vous n’avez pas besoin de payer des frais pour accéder à vos
        données personnelles ou exercer vos droits. Cependant, nous pourrions
        exiger des frais raisonnables si votre demande était manifestement
        infondée, répétitive ou excessive.
        <br />
        Nous pouvons par ailleurs être amenés à vous contacter pour vous
        demander des informations complémentaires par rapport à votre demande
        afin de vous répondre. Toute réponse vous sera d’ailleurs apportée dans
        un délai d’un mois. Exceptionnellement, il serait possible que nous
        excédions ce délai d’un mois si votre demande était particulièrement
        complexe.
      </p>
      <h5>
        11. DROIT D’INTRODUIRE UN RECOURS AUPRES D’UNE AUTORITE DE CONTROLE
      </h5>
      <p>
        L’autorité de contrôle compétente pour connaître de toute demande nous
        concernant, est la Commission Nationale de l’Informatique et des
        Libertés (CNIL).Si souhaitez saisir la CNIL de toute demande, vous
        trouverez ci-dessous les coordonnées :
        <br />
        CNIL (COMMISSION NATIONALE DE L’INFORMATIQUE ET DES LIBERTÉS)
        <br />3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07
        <br />
        Tél. : 01 53 73 22 22
        <br />
        (du lundi au jeudi de 9h à 18h30 / le vendredi de 9h à 18h)
        <br />
        Fax : 01 53 73 22 00
        <br />
        Si vous souhaitez déposer une plainte auprès de la CNIL, vous pouvez
        renseigner le formulaire de dépôt de plainte en ligne disponible à
        l’adresse suivante: https://www.cnil.fr/fr/plaintes.
      </p>
      <p>
        Si vous avez une question sur vos droits informatique et libertés, vous
        pouvez consulter le site de la CNIL: www.cnil.fr.
      </p>
    </div>
  );
};

export default ConfidentialityAgreement;
