import React from "react";
import Cookies from "js-cookie";

const ToggleFilterButton = ({ cookiesKey, filterActive, setFilterActive }) => {
  const handleButtonClick = () => {
    Cookies.set(cookiesKey, JSON.stringify(!filterActive), { secure: true });
    setFilterActive(!filterActive);
  };

  return (
    <button
      className={`filter-toggle-button ${filterActive ? "active" : ""}`}
      onClick={handleButtonClick}
    >
      <i className="fas fa-filter nav-icon" style={{ fontSize: "1.1em" }}></i>
      Filtres
      <i
        class="fas fa-chevron-down"
        style={{ fontSize: "0.8em", marginLeft: "10px" }}
      ></i>
    </button>
  );
};

export default ToggleFilterButton;
