export const verifyCodeWaste = (code, setFormIsVerified, setErrorForm) => {
  const regex6 = new RegExp(/^\d{2}\s\d{2}\s\d{2}$/);
  const result = regex6.test(code);
  if (!result && code.length > 0) {
    setFormIsVerified(false);
    setErrorForm("Le numéro doit être sous la forme 11 99 20");
  } else {
    setFormIsVerified(true);
    setErrorForm("");
  }
};

export const verifyCodeOnu = (code, setFormIsVerified, setErrorForm) => {
  const regex4 = new RegExp(/^\d{2}\s\d{2}$/);
  const result = regex4.test(code);
  if (!result && code.length > 0) {
    setFormIsVerified(false);
    setErrorForm("Le numéro doit être sous la forme  11 99");
  } else {
    setFormIsVerified(true);
    setErrorForm("");
  }
};
