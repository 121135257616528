import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export const loadModel = (path, position, scale, rotation) => {
  return new Promise((resolve, reject) => {
    const loader = new GLTFLoader();

    loader.load(path, (gltf) => {
      const model = gltf.scene;

      model.position.set(position.x, position.y, position.z);
      model.scale.set(scale.x, scale.y, scale.z);
      model.rotation.set(rotation.x, rotation.y, rotation.z);

      // Calculer les arêtes du modèle
      model.traverse((child) => {
        if (child.isMesh) {
          const edges = new THREE.EdgesGeometry(child.geometry);
          const line = new THREE.LineSegments(
            edges,
            new THREE.LineBasicMaterial({ color: 0x000000 }),
          );
          child.add(line);
          child.material.transparent = true; // Permettre la transparence
        }
      });

      resolve(model);
    });
  });
};

export const createSkipWithAlert = async (
  skipConfig,
  alertConfig,
  scene,
  skipModelsRef,
  alertSkipModelsRef,
) => {
  const { modelPath, position, scale, rotation } = skipConfig;
  const { alertPath, alertScale } = alertConfig;

  // Charger le modèle principal
  const skipModel = await loadModel(modelPath, position, scale, rotation);
  scene.add(skipModel);
  skipModelsRef.current.push(skipModel);

  // Définir la position du modèle d'alerte
  const alertPosition = { x: position.x, y: position.y + 1, z: position.z };

  // Charger le modèle d'alerte
  const alertSkipModel = await loadModel(
    alertPath,
    alertPosition,
    alertScale,
    rotation,
  );
  alertSkipModel.visible = false;
  scene.add(alertSkipModel);
  alertSkipModelsRef.current.push(alertSkipModel);
};
