import React, { useEffect, useState } from "react";
import MapDisplay from "../../components/utils/Map";
import Loading from "../../components/utils/Loading";
import Cookie from "js-cookie";
import { DashbordIndicators } from "./DashboardIndicators";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();

  const firstName = Cookie.get("firstName");
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookie.get("userToken");
  const [isLoading, setIsLoading] = useState(true);
  const [countContainers, setCountContainers] = useState(0);
  const [countAnomalies, setCountAnomalies] = useState([]);
  const [dataMaps, setDataMaps] = useState([]);
  const [arrayFillingDelivery, setArrayFillingDelivery] = useState([]);
  const [fillingRateDelivery, setFillingRateDelivery] = useState([]);
  const [meanFilling, setMeanFilling] = useState(0);
  const [showDashboard, setShowDashboard] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [totalVolume, setTotalVolume] = useState(0);
  const [totalVolumeUsed, setTotalVolumeUsed] = useState(0);
  const [typeContainersDelivery, setTypeContainersDelivery] = useState([]);
  const [wasteErrorDelivery, setWasteErrorDelivery] = useState([]);
  const [wasteDelivery, setWasteDelivery] = useState([]);
  const [wasteCategory, setWasteCategory] = useState(null);
  const [characterization, setCharacterization] = useState(false);

  useEffect(() => {
    axios
      .get(`${API}wastecategory?page=1&limit=100`, {
        headers: { authorization: `Bearer ${token}` },
        params: {
          keywordFilter: "",
          listUnCodeFullFilter: {},
          listUnCodeEmptyFilter: {},
          listWasteCodeFullFilter: {},
          listWasteCodeEmptyFilter: {},
          sortByField: "label",
          orderSortByField: "1",
        },
      })
      .then((response) => {
        setWasteCategory(response.data.data);
      });
  }, [API, token]);

  return (
    <div className="base dashboard-base">
      <nav className="container-modal-nav">
        <article>
          <h1>
            {t("Hello")} <span>{firstName}</span>
          </h1>
          <h4>{t("Welcome to your space")}</h4>
        </article>
        <div>
          <span
            onClick={() => {
              setShowDashboard(true);
              setShowMap(false);
            }}
            className={`${showDashboard ? "container-modal-select" : ""}`}
          >
            {t("My indicators")}
          </span>
          <span
            onClick={() => {
              setShowDashboard(false);
              setShowMap(true);
            }}
            className={`${showMap ? "container-modal-select" : ""}`}
          >
            {t("My locations")}
          </span>
        </div>
      </nav>

      {showDashboard ? (
        <DashbordIndicators
          countContainers={countContainers}
          setCountContainers={setCountContainers}
          countAnomalies={countAnomalies}
          setCountAnomalies={setCountAnomalies}
          setDataMaps={setDataMaps}
          arrayFillingDelivery={arrayFillingDelivery}
          setArrayFillingDelivery={setArrayFillingDelivery}
          setFillingRateDelivery={setFillingRateDelivery}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          meanFilling={meanFilling}
          setMeanFilling={setMeanFilling}
          totalVolume={totalVolume}
          setTotalVolume={setTotalVolume}
          totalVolumeUsed={totalVolumeUsed}
          setTotalVolumeUsed={setTotalVolumeUsed}
          typeContainersDelivery={typeContainersDelivery}
          setTypeContainersDelivery={setTypeContainersDelivery}
          wasteErrorDelivery={wasteErrorDelivery}
          setWasteErrorDelivery={setWasteErrorDelivery}
          wasteDelivery={wasteDelivery}
          setWasteDelivery={setWasteDelivery}
          wasteCategory={wasteCategory}
          dataMaps={dataMaps}
          characterization={characterization}
          setCharacterization={setCharacterization}
        />
      ) : null}

      {showMap ? (
        <div>
          {isLoading ? (
            <Loading color="#F5F5F5" />
          ) : (
            <MapDisplay
              fillingRateDelivery={fillingRateDelivery}
              markers={dataMaps}
              mapHeight={"90vh"}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Dashboard;
