import { Modal } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ConfirmationModal = ({
  open,
  setOpen,
  title,
  message,
  submitText,
  handleSubmit,
  close,
}) => {
  const toggle = () => {
    setOpen(!open);
    close();
  };

  return (
    <Modal isOpen={open} toggle={toggle} className="w-1/2 top-28">
      <section className="flex justify-between py-3 border-b-1 border-solid ">
        <h4 className="font-semibold ml-3">{title}</h4>
        <FontAwesomeIcon
          icon="times"
          className="close-modal mr-3"
          onClick={() => toggle()}
        />
      </section>

      <section className="flex justify-between py-3 border-b-1 border-solid text-gray-600 font-medium text-lg">
        <p className="mb-0 mx-3">{message}</p>
      </section>

      <section className="flex justify-end my-2.5">
        <button
          onClick={() => toggle()}
          className="bg-inherit border-solid border-2 border-gray-300 mr-3 py-2 px-4 rounded-md text-gray-600 hover:bg-gray-200"
        >
          Annuler
        </button>
        <button
          type="submit"
          className="bg-cyan mx-2 py-2 px-4 rounded-md text-white hover:bg-yellow"
          onClick={() => {
            handleSubmit();
            toggle();
          }}
        >
          {submitText}
        </button>
      </section>
    </Modal>
  );
};
