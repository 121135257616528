import React from "react";
import Popover from "@mui/material/Popover";
import { Typography } from "@mui/material";

const CustomPopover = ({ open, anchorEl, content, content2, onClose }) => {
  return (
    <Popover
      id="custom-popover"
      sx={{
        pointerEvents: "none",
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      <Typography variant="body2" sx={{ px: 1, paddingTop: 1 }}>
        {content}
      </Typography>
      <Typography variant="body2" sx={{ px: 1, paddingBottom: 1 }}>
        {content2}
      </Typography>
    </Popover>
  );
};

export default CustomPopover;
