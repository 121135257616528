import React, { useState, useEffect } from "react";
import { formatAzureBlob } from "../../../components/formatted/FormattedAzureSasToken";
import { ImageCardComponent } from "../../../components/imageCard/ImageCardComponent";
import {
  formattedDate,
  formattedTime,
} from "../../../components/formatted/FormattedDateAndTime";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { getAzureBlob } from "../../../services/container";

const ContainerSortingError = ({
  dataForSortingErrorOverlay,
  containerData,
  setIsSortingErrorOverlayActive,
  setContainerActive,
}) => {
  const { i18n } = useTranslation();
  const [objects, setObjects] = useState([]);
  const [historyId, setHistoryId] = useState(0);
  const API = process.env.REACT_APP_API_BACKEND;

  useEffect(() => {
    getAzureBlob({
      blobUrl: formatAzureBlob(
        containerData?.image_url
          .replace("pictures", "output-surface")
          .replace("-L", "")
          .replace("-R", "")
          .replace(
            ".jpeg",
            "-" + dataForSortingErrorOverlay[0]._measurement + "-surface.json",
          ),
      ),
    })
      .then((response) => {
        setObjects(response.data);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération du fichier JSON :",
          error,
        );
      });
  }, [containerData, dataForSortingErrorOverlay]);

  return (
    <>
      <div
        className="w-6 h-6 bg-gray-400 ml-auto flex items-center justify-center rounded-full"
        style={{
          marginRight: "12px",
          marginTop: "14px",
        }}
        onClick={() => {
          setIsSortingErrorOverlayActive(false);
          setContainerActive({});
        }}
      >
        <CloseIcon
          style={{
            width: 22,
            cursor: "pointer",
            color: "#FFF",
          }}
        />
      </div>
      <div style={{ height: 250 }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "10px 20px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: 0,
              marginBottom: 0,
              justifyContent: "center",
            }}
          >
            <p className="data-bold" style={{ color: "#0F265C", padding: 0 }}>
              Dernière horaire :&thinsp;
            </p>
            <p className="data-bold" style={{ color: "#E81D7A", padding: 0 }}>
              {formattedDate(
                dataForSortingErrorOverlay[0]._time,
                i18n.language,
              )}{" "}
              - {formattedTime(dataForSortingErrorOverlay[0]._time)}
            </p>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              padding: 0,
              marginBottom: 0,
            }}
          >
            <p className="data-bold" style={{ color: "#0F265C" }}>
              Nombre d'objets sur la première image :&thinsp;
            </p>
            <p className="data-bold" style={{ color: "#E81D7A" }}>
              {objects.object_segmentations !== undefined &&
                objects.object_segmentations.length}
            </p>
          </div>
        </div>
        <div style={{ marginTop: -10 }}>
          <ImageCardComponent
            API={API}
            container={containerData}
            displayLegend={true}
            expendImage={false}
            setExpendImage={null}
            history={dataForSortingErrorOverlay}
            historyId={historyId}
            isCharacterization={false}
            setHistoryId={setHistoryId}
            displayAnomalyLegend={false}
            isActivity={false}
            containerSortingError={true}
          />
        </div>
      </div>
    </>
  );
};

export default ContainerSortingError;
