const azureImagePath =
  "https://cameraanalysis.blob.core.windows.net/camera-analysis/";
const azureToken = process.env.REACT_APP_AZURE_TOKEN;

export const azureSASTokenToUrl = (url) => {
  url = url.replace(
    "https://imagecamera.blob.core.windows.net/",
    azureImagePath,
  );

  return url.substring(0, url.indexOf("?")) + azureToken;
};

export const formatAzureBlob = (url) => {
  url = url.replace(
    "https://imagecamera.blob.core.windows.net/",
    azureImagePath,
  );

  return url.substring(0, url.indexOf("?"));
};
