import React from "react";
import { View, Page } from "@react-pdf/renderer";
import { ActivityPdfStyleSheet } from "../styles/ActivityPdfStyleSheet";
import { ImageDocumentComponent } from "./ImageDocumentComponent";
import { TitleAndLengend } from "./TitleAndLengend";

export const ActivityPageComponent = ({ data }) => {
  if (data.x_box < 0) {
    const newWidth = data.w_box - Math.abs(data.x_box);

    data.x_box = 0;
    data.w_box = newWidth;
  }
  if (data.y_box < 0) {
    const newHeight = data.h_box - Math.abs(data.y_box);

    data.h_box = 0;
    data.y_box = newHeight;
  }

  const isLandscapeCrop =
    data.h_box / data.w_box < 0.5 && data.x_box / data.y_box !== 0
      ? true
      : false;

  return (
    <Page size="A4" style={ActivityPdfStyleSheet.page} orientation="landscape">
      <TitleAndLengend data={data} />

      <View
        style={{
          display: "flex",
          flexDirection: isLandscapeCrop ? "column" : "row",
          justifyContent: "space-evenly",
          marginVertical: "auto",
        }}
      >
        <ImageDocumentComponent data={data} drawMaterial={true} />
        <ImageDocumentComponent data={data} drawMaterial={false} />
      </View>
    </Page>
  );
};
