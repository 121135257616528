// Multi Object treatments (array of objects)
export function findObjectInArrayByKeyValue(data, keyName, value) {
  for (let i = 0; i < data.length; i++) {
    const obj = data[i];
    if (obj[keyName] === value) {
      return obj;
    }
  }
}

export function sortObjectsInArrayByKeyName(
  initialArray,
  keyName,
  order = "ascending",
) {
  const sortedArray = [...initialArray];
  if (order === "ascending") {
    sortedArray.sort((a, b) => (a[keyName] > b[keyName] ? 1 : -1));
  }
  if (order === "descending") {
    sortedArray.sort((a, b) => (a[keyName] < b[keyName] ? 1 : -1));
  }
  return sortedArray;
}

//Single Object treatments
export function sortObjectbyKeys(unordered) {
  return Object.keys(unordered)
    .sort()
    .reduce((obj, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});
}
