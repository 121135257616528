import React from "react";
import { Text, View, Font, StyleSheet } from "@react-pdf/renderer";
import MulishLight from "../../../fonts/Mulish/Mulish-Light.ttf";
import MulishMedium from "../../../fonts/Mulish/Mulish-Medium.ttf";
import { useTranslation } from "react-i18next";

Font.register({
  family: "Mulish",
  fonts: [
    { src: MulishLight, fontWeight: 200 },
    { src: MulishMedium, fontWeight: 500 },
  ],
});

export const WasteQualityFooter = ({ page, cover }) => {
  const { t } = useTranslation();

  const styles = StyleSheet.create({
    pageFooter: {
      height: "3%",
      width: "100%",
      bottom: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: cover ? "center" : "space-between",
      textAlign: "center",
      paddingHorizontal: 20,
    },
    textFooter: {
      fontSize: 10,
      color: "#808080",
    },
  });

  return (
    <View style={styles.pageFooter}>
      <Text style={styles.textFooter}>{t("Confidential")}</Text>
      {!cover && <Text style={styles.textFooter}>{page}</Text>}
    </View>
  );
};
