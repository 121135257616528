import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MaterialCountingChartForPDF } from "../../../../components/download/wasteQuality/MaterialCountingChartForPDF";
import { toFrenchNumber } from "../../../../components/formatted/FormattedText";
import HorizontalBarChart from "../../../charts/HorizontalBarChart";
import { useTranslation } from "react-i18next";
import { OutletPieChart } from "../../../charts/OutletPieChart";

export const ContainerViewMaterialForPDF = ({
  tableRef,
  parentCategoriesList,
  totalWasteMass,
  chartRef,
  dataMassMaterial,
  labelMaterial,
  dataMaterialChart,
  labelChart,
  materialTotal,
  colorChart,
  materialRef,
  selectedNumberOfCycleMaterial,
  dataVolumeMaterial,
  volumeRef,
  totalWeight,
  outletData,
  outletRef,
  tableOutletRef,
}) => {
  const { t } = useTranslation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [fontSizeLabel, setFontSizeLabel] = useState("1rem");

  const calculateFontSize = (screen) => {
    if (screen >= 2049) {
      return "2.1rem";
    } else if (screen >= 1821) {
      return "1.9rem";
    } else if (screen >= 1707) {
      return "1.8rem";
    } else if (screen >= 1517) {
      return "1.6rem";
    } else if (screen >= 1366) {
      return "1.3rem";
    } else {
      return "1rem";
    }
  };

  useEffect(
    () => {
      function handleResize() {
        setScreenWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    },
    // eslint-disable-next-line
    [window],
  );

  useEffect(() => {
    const size = calculateFontSize(screenWidth);
    setFontSizeLabel(size);
  }, [screenWidth]);

  return (
    <div style={{ zIndex: -10, position: "absolute", width: "100%" }}>
      <Grid container>
        <Grid
          item
          xs={6}
          style={{ position: "absolute", marginTop: -50, marginLeft: 100 }}
        >
          {parentCategoriesList.length > 0 && (
            <section
              className="flex flex-col my-4"
              ref={tableRef}
              style={{ height: "100%", width: "700px" }}
            >
              <div className="flex justify-end text-cyan text-lg mb-2">
                <div className="w-1/5 px-2 mx-1 rounded-md text-center font-semibold">
                  {t("Mass")} (kg)
                </div>
                <div className="w-1/5 px-2 rounded-md text-center font-semibold">
                  % {t("mass")}
                </div>
              </div>
              {parentCategoriesList.map(
                (parentCategory, indexParentCategory) => {
                  return (
                    <div
                      key={`parentcat-${indexParentCategory}`}
                      className="flex justify-between text-grey"
                    >
                      <div className="flex flex-col w-3/5 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                        <div className="font-semibold">
                          {parentCategory.parentCategory.toUpperCase()}
                        </div>
                        <div className="flex text-xs">
                          <div>
                            {parentCategory.categoryList.sort().join(", ")}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="flex font-semibold justify-center items-center w-1/5 bg-white-modal px-2 py-2.5 m-1 rounded-sm ">
                        {toFrenchNumber(
                          (parentCategory.massObjects / totalWasteMass) *
                            totalWeight,
                          1,
                        )}
                      </div>
                      <div className="flex font-semibold justify-center items-center text-center w-1/5 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                        {totalWasteMass > 0
                          ? toFrenchNumber(
                              (parentCategory.massObjects / totalWasteMass) *
                                100,
                              1,
                            )
                          : "0,0"}
                        %
                      </div>
                    </div>
                  );
                },
              )}
            </section>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          style={{ position: "absolute", marginTop: -50, marginLeft: 100 }}
        >
          {parentCategoriesList.length > 0 && (
            <section
              className="flex flex-col my-4"
              ref={tableOutletRef}
              style={{ height: "100%", width: "700px" }}
            >
              <div className="flex justify-end text-cyan mb-2">
                <div className="w-1/3 px-2 mx-1 rounded-md text-center font-semibold">
                  Avec filière
                </div>
                <div className="w-1/3 px-2 rounded-md text-center font-semibold">
                  Sans filière
                </div>
              </div>

              {parentCategoriesList.map(
                (parentCategory, indexParentCategory) => {
                  return (
                    <div
                      key={`parentcat-${indexParentCategory}`}
                      className="flex justify-between text-grey"
                    >
                      <div className="flex flex-col w-1/3 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                        <div className="font-semibold">
                          {parentCategory.parentCategory.toUpperCase()}
                        </div>
                        <div className="flex text-xs">
                          <div>
                            {parentCategory.categoryList.sort().join(", ")}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="flex font-semibold justify-center items-center w-1/3 bg-white-modal px-2 py-2.5 m-1 rounded-sm ">
                        <span>
                          {toFrenchNumber(
                            (parentCategory.massInOutlet / totalWasteMass) *
                              totalWeight,
                            1,
                          )}{" "}
                          kg
                        </span>
                        <span className="mx-2">/</span>
                        <span>
                          {parentCategory.massInOutlet > 0
                            ? toFrenchNumber(
                                (parentCategory.massInOutlet / totalWasteMass) *
                                  100,
                                1,
                              )
                            : "0,0"}
                          %
                        </span>
                      </div>
                      <div className="flex font-semibold justify-center items-center text-center w-1/3 bg-white-modal px-2 py-2.5 my-1 rounded-sm">
                        <span>
                          {toFrenchNumber(
                            (parentCategory.massNotInOutlet / totalWasteMass) *
                              totalWeight,
                            1,
                          )}{" "}
                          kg
                        </span>
                        <span className="mx-2">/</span>
                        <span>
                          {parentCategory.massNotInOutlet > 0
                            ? toFrenchNumber(
                                (parentCategory.massNotInOutlet /
                                  totalWasteMass) *
                                  100,
                                1,
                              )
                            : "0,0"}
                          %
                        </span>
                      </div>
                    </div>
                  );
                },
              )}
            </section>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          style={{ position: "absolute", marginTop: -100, marginLeft: 100 }}
        >
          {dataMassMaterial.length > 0 && (
            <section style={{ height: "600px", width: "700px" }} ref={chartRef}>
              <HorizontalBarChart
                dataChart={dataMassMaterial}
                labelsChart={labelMaterial}
                unitValueChart="%"
                messageLabelTooltip="Matière"
                fontSizeLabel={fontSizeLabel}
              />
            </section>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          style={{ position: "absolute", marginTop: 400, marginLeft: 50 }}
        >
          {dataMaterialChart.length > 0 && (
            <section style={{ height: "70vw", marginTop: -900, zIndex: -11 }}>
              <MaterialCountingChartForPDF
                dataMaterialChart={dataMaterialChart}
                labelChart={labelChart}
                materialTotal={materialTotal}
                colorChart={colorChart}
                selectedNumberOfCycleMaterial={selectedNumberOfCycleMaterial}
                materialRef={materialRef}
                fontSizeLabel={fontSizeLabel}
              />
            </section>
          )}
        </Grid>
        <Grid item xs={6} style={{ position: "absolute", marginTop: 400 }}>
          {outletData?.data && (
            <section style={{ height: "70vw", marginTop: -900, zIndex: -11 }}>
              <OutletPieChart
                dataMaterialChart={outletData.data}
                labelChart={outletData.labels}
                materialTotal={totalWasteMass}
                colorChart={["#f58142", "#42f55d", "#bf42f5", "#2ab5b8"]}
                outletRef={outletRef}
                fontSizeLabel={fontSizeLabel}
                totalWeight={totalWeight / 1000}
                size={"85vw"}
                padding={0}
                marginLeft={null}
                childrenValueFontSize={"5rem"}
                childrenTextFontSize={"2.5rem"}
                radius={"48%"}
                space={80}
                pdf={true}
              />
            </section>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          style={{ position: "absolute", marginTop: -100, marginLeft: 200 }}
        >
          {dataVolumeMaterial.length > 0 && (
            <section
              style={{ height: "600px", width: "700px", zIndex: -12 }}
              ref={volumeRef}
            >
              <HorizontalBarChart
                dataChart={dataVolumeMaterial}
                labelsChart={labelMaterial}
                unitValueChart="%"
                maxYAxis={undefined}
                stepYAxis={undefined}
                messageLabelTooltip="Matière"
                fontSizeLabel={fontSizeLabel}
              />
            </section>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
