import React, { useState } from "react";
import { formattedRoleAddress } from "../../../components/formatted/FormattedText";
import DeleteModal from "../../../components/modals/DeleteModal";
import { handleOpenModalClick } from "../../../components/modals/EditableTable";
import AddressHeader from "./AddressHeader";

const AddressRow = ({
  data,
  tableData,
  setTableData,
  setIsDeleted,
  formData,
  setFormData,
  setModalEdit,
}) => {
  const [modalDelete, setModalDelete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="table-block">
      <AddressHeader classColumnTable={"table-column-block"} />
      <article className="table-line">
        <div className="data-table">{data.name}</div>

        <div className="data-table">{formattedRoleAddress(data.role)}</div>

        <div className="data-table data-address">
          {data.number +
            " " +
            data.street +
            " " +
            data.postalCode +
            " " +
            data.city}
        </div>

        <div className="data-table">{data.location[0]}</div>

        <div className="data-table">{data.location[1]}</div>

        <article className="data-table data-action">
          <i
            className={` button-action ${!isOpen ? "fas fa-ellipsis-v" : "fas fa-times"}`}
            onClick={() => setIsOpen(!isOpen)}
          ></i>
          <div className={`action-choice ${isOpen ? "display-action" : ""}`}>
            <button
              className="action"
              onClick={() => {
                handleOpenModalClick(data, formData, setFormData, setModalEdit);
                setIsOpen(!isOpen);
              }}
            >
              Modifier
            </button>
            <button
              className="action"
              onClick={() => {
                setModalDelete(true);
                setIsOpen(!isOpen);
              }}
            >
              Supprimer
            </button>
          </div>
        </article>

        {modalDelete && (
          <DeleteModal
            tableData={tableData}
            setTableData={setTableData}
            modal={modalDelete}
            setModal={setModalDelete}
            id={data._id}
            message1={
              "Voulez vous vraiment supprimer l'adresse : \n" + data.name + " ?"
            }
            message2={data.name}
            messageValidation={"L'adresse a bien été supprimée"}
            setIsDeleted={setIsDeleted}
            path={"address/delete/"}
          />
        )}
      </article>
    </section>
  );
};

export default AddressRow;
