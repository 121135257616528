import CloseIcon from "@mui/icons-material/Close";
import {
  formattedDate,
  formattedTime,
} from "../../../components/formatted/FormattedDateAndTime";
import { CropImage } from "../../../components/utils/ImageTreatment";
import { useCallback, useEffect, useState } from "react";
import Loading from "../../../components/utils/Loading";
import useWindowDimensions from "../../../components/formatted/UseWindowsDimensions";
import { getTemporalData } from "../../../services/container";
import { useTranslation } from "react-i18next";

const ActivityTimeLineTooltip = ({
  data,
  setItemIndex,
  activeWasteCategory,
}) => {
  const { i18n } = useTranslation();
  const { widthWindow, heightWindow } = useWindowDimensions();
  const imageWidth = widthWindow / 2.5;
  const imageHeight = heightWindow / 1.6;
  const [temporalData, setTemporalData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchTemporalData = useCallback(() => {
    getTemporalData({ time: data._time }, data._id).then(
      (responseTemporalData) => {
        if (responseTemporalData.status === 200) {
          setTemporalData(
            responseTemporalData.data.temporalData.length > 0 &&
              responseTemporalData.data.temporalData[0],
          );
          setIsLoading(false);
        }
      },
    );
  }, [data]);

  useEffect(() => {
    fetchTemporalData();
  }, [fetchTemporalData]);

  return (
    <div
      className="bottom-left-block"
      style={{
        height: heightWindow / 1.2,
        width: widthWindow / 2.5,
      }}
    >
      <section className="time-line-tooltip-top">
        <article className="flex flex-row w-full bottom-left-block-header pt-2 pr-2">
          <div className="flex-grow text-center text-white  mt-1 mb-1">
            <h6 className="text-xl font-medium">
              {data.addressName} |{" "}
              {activeWasteCategory !== ""
                ? activeWasteCategory
                : data.wasteName}
            </h6>
          </div>
          <div className="flex items-center justify-center bg-white w-5 h-5 rounded-full">
            <CloseIcon
              style={{
                color: "#2b9992",
                cursor: "pointer",
                width: 20,
                height: 20,
              }}
              onClick={() => setItemIndex(null)}
            />
          </div>
        </article>
        <article
          className="text-center mt-2 mb-2"
          style={{ color: "var(--blush)" }}
        >
          <span className="text-lg font-semibold">
            {formattedDate(data._time, i18n.language)} -{" "}
            {formattedTime(data._time)}
          </span>
        </article>
      </section>
      {isLoading ? (
        <Loading height={`45vh`} />
      ) : (
        <div className="p-2">
          <CropImage
            container={data}
            data={temporalData}
            imgDivWidth={imageWidth}
            imgDivHeight={imageHeight}
            isTooltip={true}
            isMap={false}
            isCharacterization={false}
            displayLegend={true}
            materialsDropdownToDisplay={[]}
            showCheckbox={false}
          />
        </div>
      )}
    </div>
  );
};

export default ActivityTimeLineTooltip;
