import React from "react";

const Loader = ({ text, height, color }) => {
  return (
    <div
      className="Loader-container rounded-md"
      style={{ height: height, backgroundColor: color }}
    >
      <div className="loader">Loading...</div>
      {text && <p>{text}</p>}
    </div>
  );
};

export default Loader;
